import Flatpickr from "stimulus-flatpickr";

// import the translation files and create a translation mapping
import { english } from "flatpickr/dist/l10n/default.js";
import { German } from "flatpickr/dist/l10n/de.js";

export default class extends Flatpickr {
  static locales = {
    en: english,
    de: German,
  }

  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      ...this.config,
      locale: this.locale,
      enableTime: true,
      time_24hr: true,
      altInput: true,
      altFormat: "d. M Y H:i"
    }
    super.connect()
  }

  get locale() {
    return I18n.locale
  }
}