this.isMobile = function() {
  var e
  try {
    document.createEvent("TouchEvent")
    return true
  } catch (_error) {
    e = _error
    return false
  }
}

this.getDisplayWidthMargin = function() {
  return $('footer').width()
}

this.initMailTooltip = function() {
  $('.mail_tooltip').tooltipster({
    animation: 'fade',
    delay: 200,
    trigger: 'hover',
    interactive: true,
    theme: 'tooltipster_mail'
  })
}

this.initProfileTooltip = function() {
  var trigger
  trigger = ''
  if (isMobile()) {
    trigger = 'click'
  } else {
    trigger = 'hover'
  }
  $('.profile_points_tooltip').tooltipster({
    animation: 'fade',
    delay: 200,
    trigger: trigger,
    interactive: true,
    theme: 'tooltip_profile'
  })
  $('.profile_settings_tooltip').tooltipster({
    animation: 'fade',
    delay: 200,
    trigger: trigger,
    interactive: true,
    theme: 'tooltip_profile'
  })
}

this.toggleBurger = function() {
  $('.burger').toggleClass('visible')
}

this.updateBurger = function() {
  if (!$('#burger_icon_container').is(':visible')) {
    $('#burger').removeClass('visible')
    $('#open_burger').addClass('visible')
    $('#close_burger').removeClass('visible')
  }
}

this.closestEdge = function(x, y, w, h) {
  var bottomEdgeDist, leftEdgeDist, min, rightEdgeDist, topEdgeDist
  topEdgeDist = distMetric(x, y, w / 2, 0)
  bottomEdgeDist = distMetric(x, y, w / 2, h)
  leftEdgeDist = distMetric(x, y, 0, h / 2)
  rightEdgeDist = distMetric(x, y, w, h / 2)
  min = Math.min(topEdgeDist, bottomEdgeDist, leftEdgeDist, rightEdgeDist)
  switch (min) {
    case leftEdgeDist:
      return 'left'
    case rightEdgeDist:
      return 'right'
    case topEdgeDist:
      return 'top'
    case bottomEdgeDist:
      return 'bottom'
  }
}

this.distMetric = function(x, y, x2, y2) {
  var xDiff, yDiff
  xDiff = x - x2
  yDiff = y - y2
  return xDiff * xDiff + yDiff * yDiff
}

this.initAnonymousContainer = function() {
  updateAnonymousContainer()
}

this.updateAnonymousContainer = function() {
  var footer, h, header, newHeight
  header = $('#microsite_header').outerHeight()
  footer = $('#microsite_footer').outerHeight()
  h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  newHeight = h - header - footer + 'px'
  $('.anonymous_container').css('min-height', newHeight)
}


/*
 * top bar scrolling behavior
 */

this.initSmHeader = function() {
  var $width_indicator, is_mobile, top_header_height
  $width_indicator = $('#width_indicator .value')
  is_mobile = $width_indicator.css("display") === "none"
  top_header_height = 80
  if (is_mobile) {
    top_header_height = 40
    $('#page_container').addClass('mobile')
  } else {
    $('#page_container').removeClass('mobile')
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > top_header_height) {
      return $('#page_container').addClass('stick')
    } else if ($(this).scrollTop() < top_header_height) {
      return $('#page_container').removeClass('stick')
    }
  })
}

$(document).ready(function() {

  /*
   * Cocoon
   */
  addFormInitializationRoutine(function() {
    return $('a.add_fields').each(function(i, el) {
      return $(el).parent().on('cocoon:after-insert', function() {
        return reinitializeForms()
      }).on('cocoon:after-remove', function() {
        return reinitializeForms()
      })
    })
  })

  /*
   * General close button
   */
  $('#messages').on('click', '.close-button', function(event) {
    var $button, $flash
    $button = $(this)
    $flash = $button.closest('.flash')
    return $flash.hide()
  })

  /*
   * See show_datetime helper
   */
  addFormInitializationRoutine(function(context) {
    $(context).find('.datetimestring').each(function() {
      return $(this).text(datetime.toLocal($(this).attr("data-value")))
    })
    $(context).find('.datestring').each(function() {
      return $(this).text(datetime.toLocalDate($(this).attr("data-value")))
    })
    return $(context).find('.timestring').each(function() {
      return $(this).text(datetime.toLocalTime($(this).attr("data-value")))
    })
  })

  /*
   * INDEX FILTERS
   */
  $('.filtered-table').on('change', 'table.filtered .filter-select', function(event) {
    var $form
    $form = $('form.filter-form')
    return $form.submit()
  })

  /*
   * BOOKMARKS
   */
  addFormInitializationRoutine(function(context) {
    return $(context).find(".svg-bookmark").each(function(i, el) {
      var $el
      $el = $(el)
      if ($el.data("initializedBookmark")) {
        return
      }
      $el.data("initializedBookmark", true)
      return $el.click(function() {
        return $.post($el.attr("data-path"), function(data) {
          if (data.bookmarked) {
            $el.find('.active').removeClass('hidden')
            return $el.find('.inactive').addClass('hidden')
          } else {
            $el.find('.active').addClass('hidden')
            return $el.find('.inactive').removeClass('hidden')
          }
        })
      })
    })
  })

  /*
   * MAPS
   */
  return mapsHelper.addInitializationRoutine(function(context) {
    return $(context).find(".map-canvas.auto-initialize").each(function(i, el) {
      var $el, infoWindow, mapApi, marker, placesServiceApi
      $el = $(el)
      if ($el.data("initializedMaps")) {
        return
      }
      $el.data("initializedMaps", true)
      mapApi = new google.maps.Map(el, mapsHelper.defaultConfiguration)
      placesServiceApi = new google.maps.places.PlacesService(mapApi)
      marker = new google.maps.Marker({
        map: mapApi,
        title: $el.attr("data-label")
      })
      infoWindow = new google.maps.InfoWindow({
        content: $el.attr("data-label")
      })
      marker.addListener('click', function() {
        return infoWindow.open(mapApi, marker)
      })
      if ($el.attr("data-place-id")) {
        return placesServiceApi.getDetails({
          placeId: $el.attr("data-place-id")
        }, function(place, status) {
          if (!(status === google.maps.places.PlacesServiceStatus.OK && place.geometry)) {
            return
          }
          mapsHelper.updateMapWithPlace(mapApi, place, marker)
          return infoWindow.open(mapApi, marker)
        })
      }
    })
  })
})