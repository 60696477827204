export default class TrashCan {
  constructor({ trashCanElement, relevantElement, relativeTo }) {
    let self = this
    this.trashCanElement = trashCanElement
    this.positionTrashCan(relativeTo)
    this.defineDragHandler(relevantElement)
    this.defineHandlers(trashCanElement)

    this.showTrashCan({ show: false, animate: false })
  }

  positionTrashCan(relativeTo) {
    let element = $(this.trashCanElement);
    let top = $(relativeTo).offset().top - $(relativeTo).scrollTop()
    let left = $(relativeTo).offset().left - $(relativeTo).scrollLeft()
    let width = $(relativeTo).width()
    element.css({
      position: "fixed",
      display: "flex",
      top: top,
      left: left + width + element.width() * 4,
      width: element.width() * 4,
      height: "84vh"
    });
  }

  defineHandlers(trashCan) {
    let self = this;

    // define the dragenter behavior
    $(trashCan).on("dragenter", function (ev) {
      // $(ev.target).css('border', '1px solid green')
      if(!$(trashCan).data('can-drop')) {
        $(trashCan).tooltip('show')
      }
    });

    // define the dragleave behavior
    $(trashCan).on("dragleave", function (ev) {
      // $(ev.target).css('border', '')
      if(!$(trashCan).data('can-drop')) {
        $(trashCan).tooltip('hide')
      }
    });

    // define the dragover behavior
    $(trashCan).on("dragover", function (ev) {
      ev.preventDefault();
    });

    // define the drop behavior
    $(trashCan).on("drop", function (ev) {
      // $(ev.target).css('border', '')
      self.drop(ev.originalEvent)
    });
  }

  drop(event) {
    if ($(this.trashCanElement).data("can-drop")) {
      let eliminationTargets = event.dataTransfer.getData("eliminationTargets"),
        id = event.dataTransfer.getData("id")
      if (eliminationTargets) {
        eliminationTargets = eliminationTargets.split(",")
        for (let elimination of eliminationTargets) {
          $("#" + elimination).remove()
        }
      }
      $("[dropzone=false]").attr({ dropzone: true })

      let partId = id ? parseInt(id) : -1

      $(this.trashCanElement).trigger({
        type: "trashdrop",
        learnUnitId: partId
      });
    }

    this.showTrashCan({ show: false, animate: true });
  }

  defineDragHandler(element) {
    let self = this;
    $(document).on("dragstart", element, function () {
      self.showTrashCan({ show: true, animate: true })
    });
    $(document).on("dragend", element, function () {
      self.showTrashCan({ show: false, animate: true })
    });
  }

  showTrashCan({ show = true, animate = false }) {
    let self = this;
    setTimeout(function () {
      let element = $(self.trashCanElement)
      if (show) {
        if (animate) {
          element.fadeIn()
        } else {
          element.fadeIn(0)
        }
      } else {
        if (animate) {
          element.fadeOut()
        } else {
          element.fadeOut(0)
        }
      }
    });
  }
}
