import ApplicationController from "../../../javascript/controllers/application_controller"
import Draggable from "../../../javascript/packs/draggable"

export default class extends ApplicationController {

  static targets = ["correctBucket", "incorrectBucket"]

  static values = {
    minimumAnswersSorted: Number
  }

  initialize() {
    super.initialize()
    this.currentDraggable = null
    this.draggable = new Draggable(false)
    this.#defineDropzonesHandlers()
  }

  #defineDropzonesHandlers() {
    const self = this
    $(this.element).on('dragenter', '[dropzone=true]', function (ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      if (!self.#contains($container, self.currentDraggable)) {
        $container.addClass('entered')
      }
    })

    // define the dragleave behavior
    $(this.element).on('dragleave', '[dropzone=true]', function (ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      $container.removeClass('entered')
    })

    $(this.element).on('dragstart', '[draggable=true]', function (ev) {
      self.currentDraggable = $(ev.currentTarget)
    })

    $(this.element).on('dragover', '[dropzone=true]', function (ev) {
      ev.preventDefault()
    })

    $(this.element).on('drop', '[dropzone=true]', function (ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      $container.removeClass('entered')
      self.#drop(ev.originalEvent, self)
    })
  }

  #drop(event, self) {
    event.stopPropagation()
    let $container = $(event.target).closest('[dropzone=true]')

    // move the item itself to the sortable container
    const originTarget = self.#getOriginTarget(event)

    const oldPoints = self.#calculatePoints(originTarget.closest('[dropzone]'), originTarget)

    self.#moveElementTo(originTarget, $container, self)

    const newPoints = self.#calculatePoints($container, originTarget)

    self.#dispatchQuestionState()
    self.#sendPointUpdate(oldPoints, newPoints)
  }

  #getOriginTarget(event) {
    return $(`#${event.dataTransfer.getData('eliminationTargets')}`)
  }

  #moveElementTo(element, to, self) {
    if (!self.#contains(to, element)) {
      element.appendTo(to)
    }
  }

  #contains(container, contained) {
    const id = `#${contained.attr('id')}`
    return container.find(id).length > 0
  }

  #calculatePoints(container, answer) {
    const correctAnswersIndex = $(container).data('correct-answers-index')

    if(correctAnswersIndex) {
      const answerIndex = answer.data('answer-index')
      const isIn = correctAnswersIndex.includes(answerIndex)

      if(isIn) {
        const points = answer.data('points')
        const calculatedPoints = {}
        for(var key in points) {
          calculatedPoints[key] = points[key]
        }
  
        return calculatedPoints
      } else {
        const points = answer.data('points')
        const calculatedPoints = {}
        for(var key in points) {
          calculatedPoints[key] = -points[key]
        }
  
        return calculatedPoints
      }
    }

    return null
  }

  #dispatchQuestionState() {
    const sortContainer = $(this.element).find('.sort-container')
    const answersSorted = sortContainer.find('.sort-answer').length

    const questionComplete = new CustomEvent('wizard.quiz.question_state', {
      detail: {
        question_complete: answersSorted >= this.minimumAnswersSortedValue
      }
    })
    this.element.dispatchEvent(questionComplete)
  }

  #sendPointUpdate(oldPoints, newPoints) {
    const pointUpdateEvent = new CustomEvent('wizard.quiz.point_update', {
      detail: {
        oldPoints: oldPoints,
        newPoints: newPoints
      }
    })
    this.logInfo(`dispatching point update`)
    this.element.dispatchEvent(pointUpdateEvent)
  }
}