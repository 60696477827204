import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ["inputField", "characterDisplay"]

  maxCharacters = 0
  currentCharacters = 0

  connect() {
    console.log('CharacterController connected')
    if(this.hasInputFieldTarget) {
      //setup current character
      this.currentCharacters = $(this.inputFieldTarget).val().length
      //setup the max characters first
      this.maxCharacters = $(this.inputFieldTarget).attr('maxlength')
      //update them for initial display
      this.updateCharacterDisplay()
    }
  }

  /**
   * fired whenever we type in a new key.
   */
  onChange() {
    this.currentCharacters = $(this.inputFieldTarget).val().length
    this.updateCharacterDisplay()
  }

  /**
   * Updating the display for how many characters are left to type
   */
  updateCharacterDisplay() {
    if(this.hasCharacterDisplayTarget) {
      $(this.characterDisplayTarget).html(`${this.currentCharacters}/${this.maxCharacters}`)
    }
  }
}