import ApplicationController from './application_controller'
import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"
import { getMetaValue, findElement, removeElement, insertAfter } from "../packs/global_functions"

Dropzone.autoDiscover = false

///
// a controller to render a dropzone input field
// use it like this in a form:
//
//  .dropzone.dropzone-default.dz-clickable.mb-3{data: {controller: 'dropzone', 'dropzone-accepted-filesi': 'image/jpeg', dropzone_upload_message: t("labels.dropzone.upload_message"), dropzone_label_remove_file: t("labels.dropzone.remove_file_message")}}
//    = form.file_field :file, direct_upload: false, data: { 'dropzone-target': 'input'}
///
export default class extends ApplicationController {
  
  static targets = [ "input" ]
  
  connect() {
      super.connect
      this.dropZone = createDropZone(this)
      this.hideFileInput()
      this.bindEvents()
    }
  
  // Private
    hideFileInput() {
      this.inputTarget.disabled = true
      this.inputTarget.style.display = "none"
    }
  
    bindEvents() {
      this.dropZone.on("addedfile", (file) => {
        setTimeout(() => { 
          file.accepted && createDirectUploadController(this, file).start() 
          const fileUploaded = new CustomEvent("dropzone.addedfile", {
            detail: {
              file: file
            }
          })
          this.element.dispatchEvent(fileUploaded)
        }, 500)
      })
  
      this.dropZone.on("removedfile", (file) => {
        const fileRemoved = new CustomEvent("dropzone.removedfile", {
          detail: {
            file: file
          }
        })
        file.controller && removeElement(file.controller.hiddenInput)
        this.element.dispatchEvent(fileRemoved)
      })
  
      this.dropZone.on("canceled", (file) => {
        file.controller && file.controller.xhr.abort()
      })
  
      this.dropZone.on("processing", (file) => {
        if(this.submitButton) {
          this.submitButton.disabled = true
        }
      })
  
      this.dropZone.on("queuecomplete", (file) => {
        if(this.submitButton) {
          this.submitButton.disabled = false
        }
      })
    }
  
  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }

  // get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }
  get url() {
    const path = `${window.location.origin}/rails/active_storage/direct_uploads`
    // console.log(`uploading file to ${path}`)
    return path
  }

  get maxFiles() { return this.data.get("maxFiles") || 1 }

  get maxFileSize() { return this.data.get("maxFileSize") || 256 }

  get acceptedFiles() { return this.data.get("acceptedFiles") }

  get addRemoveLinks() { return this.data.get("addRemoveLinks") || true }

  get form() { return this.element.closest("form") }

  get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }

}
  
class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

// Private
  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    // const element = this.source.element
    // console.log('element: ', element)
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, ".progress-bar.progress-bar-success").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  // console.log("controller data: ", controller.data)
  let idPostfix = controller.data.get('idPostfix')
  let uploadMessage = controller.data.get('uploadMessage')
  if (uploadMessage == undefined)
  uploadMessage = "Drop files here to upload" 
  let labelRemoveFile = controller.data.get('labelRemoveFile')
  if (labelRemoveFile == undefined)
    labelRemoveFile = "remove file"
  const previewId = `#dz_preview_${idPostfix}`
  let previewNode = $(previewId);
  let previewTemplate = previewNode[0].parentNode.innerHTML
  const dropzone = new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    // addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    autoProcessQueue: false,
    dictDefaultMessage: uploadMessage,
    dictRemoveFile: labelRemoveFile,
    previewTemplate: previewTemplate,
    thumbnailMethod: 'contain',
    thumbnailWidth: null,
    thumbnailHeight: null,
    // previewsContainer: previewId // Define the container to display the previews
  })
  previewNode.detach()
  return dropzone
}
