import ApplicationController from './application_controller';

export default class extends ApplicationController {

  change(event) {
    let $target = $(event.currentTarget),
      id = $target.data("id"),
      type = $target.val(),
      object_name = $target.data("object-name");
    // console.log("target: ", $target)
    this.stimulate("EventSpecificsReflex#change", id, object_name, type).then(() => {
      reinitializeForms();
    });
  }

}
