import ApplicationController from './application_controller'
import Chart from 'chart.js/auto';

export default class extends ApplicationController {

  initialize() {
    super.initialize();
  }

  connect() {
    super.connect();
    const self = this
    // create the charts
    $(".chart-container.line").each(function () {
      self.setupChart($(this), self);      
    })
    // setup listeners for changed charts
    $(this.element).on("chart:changed", function (event, chartId) {
      // console.log(`chart changed: ${chartId}`)
      self.setupChart($(`#${chartId}_container`), self);      
    })
  }

  setupChart(chartContainer, self) {
    const chartId = chartContainer.data('chart-id')
    const colors = chartContainer.data("colors")

    const config = {
      colors: colors,
      empty: I18n.t("dict.no_data"),
      xtitle: chartContainer.data("x-axis-label"),
      ytitle: chartContainer.data("y-axis-label")
    }
    // console.log(`drawing chart for ${chartId}`)
    const myChart = new Chartkick.LineChart(
      `${chartId}_container`,
      chartContainer.data('datasets'),
      config
    );
  }

}
