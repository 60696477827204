import ApplicationController from './application_controller'
import PhaseLine from '../packs/phase_line'
import Draggable from '../packs/draggable'
import TrashCan from '../packs/trash_can'

export default class PhaseLineController extends ApplicationController {

  static targets = ["phase"]
  
  initialize() {
    super.initialize();
    this.useReflex = false
  }

  connect() {
    super.connect();
    this.openPhaseTask = null
    this.trashCan = new TrashCan({
      trashCanElement: "#trash_can",
      relevantElement: ".phase-part[draggable=true]",
      relativeTo: "#learn_path",
    });
    let draggable = new Draggable();
    let phaseLine = new PhaseLine({ element: "#learn_path" });
    if(this.hasPhaseTarget) {
      $(document).on('dragenter', '[data-phase-line-target="phase"]', this.startOpening.bind(this))
      $(document).on('dragleave', '[data-phase-line-target="phase"]', this.closeOpening.bind(this))
    }
  }

  ///
  // expands the phase box
  ///
  expand(event) {
    this.logInfo("expanding....")
    // event.preventDefault();
    const $target = $(event.currentTarget);
    const isFixed = $target.hasClass('fixed')
    if (isFixed) {
      return
    }
    const isExpanded = $target.hasClass('expanded')
    const $phase = $target.next()
    const $phaseLine = $target.closest(".phase-line-container")
    $phaseLine.find(".phase, .phase-box").removeClass('expanded')
    if(isExpanded) {
      $phase.removeClass("expanded")
      $target.removeClass("expanded")  
    } else {
      $phase.addClass("expanded")
      $target.addClass("expanded")
    }
  }

  /**
   * This will trigger the open phase after 150 ms.
   * @param {*} event 
   */
  startOpening(event) {
    if(this.openPhaseTask == null) {
      this.openPhaseTask = setTimeout(() => this.openPhase(event), 300)
    }
  }

  /**
   * This will clear the openPhaseTask in case we are going through the list of phases so only one will open.
   * @param {*} event 
   */
  closeOpening(event) {
    clearTimeout(this.openPhaseTask)
    this.openPhaseTask = null
  }

  /**
   * This will open the the phase that is dragged over and close all others
   * @param {*} event 
   */
  openPhase(event) {
    const $target = $(event.currentTarget)
    const isFixed = $target.hasClass('fixed')
    if (isFixed) {
      return
    }
    const $phase = $target.next()
    const $phaseLine = $target.closest('.phase-line-container')
    $phaseLine.find('.phase.expanded, .phase-box.expanded').removeClass('expanded')

    $phase.addClass('expanded')
    $target.addClass('expanded')
  }
}