import Trix from "trix"
import Rails from "@rails/ujs"

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2',
  terminal: true,
  breakOnReturn: true,
  group: false
}

Trix.config.blockAttributes.heading3 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false
}

Trix.config.blockAttributes.custom_trix_flex = {
  tagName: 'custom_trix_flex',
  parse: false,
};

Trix.config.blockAttributes.custom_trix_element = {
  tagName: 'custom_trix_element',
  listAttribute: 'custom_trix_flex',
  group: false,
  nestable: true,
  parser: function (element) {
      return Trix.tagName(element.parentNode) === Trix.config.blockAttributes[this.listAttribute].tagName
  }
};

/*
 * Column Controller
  *   This controller is responsible for handling the column dialog and fetching the column code
*/
class ColumnController {
  constructor(trixInitializeEvent) {
    this.trixEditor = trixInitializeEvent.target;
    this.createButtons();
  }
  
  createButtons() {
    this.getTrixContainer().find('.trix-button-group.trix-button-group--file-tools').append(this.generateTagButton('custom_trix_element', `<svg width="14" height="14"><use xlink:href="#trix_table_icon"></use></svg>`));
  }

  generateTagButton(name, content){
      return `<button type="button" class="trix-button" data-trix-attribute="${name}" data-trix-key="${name}" tabindex="-1">${content}</button>`;
  }

  getEditor() {
    return this.trixEditor.editor;
  }

  getTrixElement() {
    return $(this.trixEditor);
  }

  getTrixContainer() {
    return this.getTrixElement().parent();
  }

}

/*
  * Custom Embed Controller
  * 
  * This controller is responsible for handling the embed dialog and fetching the embed code
  */
class EmbedController {
  constructor(element) {
    this.pattern = /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/

    this.element = element
    this.editor = element.editor
    this.toolbar = element.toolbarElement
    this.hrefElement = this.toolbar.querySelector("[data-trix-input][name='href']")
    this.embedContainerElement = this.toolbar.querySelector("[data-behavior='embed_container']")
    this.embedElement = this.toolbar.querySelector("[data-behavior='embed_url']")

    this.reset()
    this.installEventHandlers()
  }

  installEventHandlers() {
    this.hrefElement.addEventListener("input", this.didInput.bind(this))
    this.hrefElement.addEventListener("focusin", this.didInput.bind(this))
    this.embedElement.addEventListener("click", this.embed.bind(this))
  }

  didInput(event) {
    let value = event.target.value.trim()
    let matches = value.match(this.pattern)
    // console.log(value,matches)

    // When patterns are loaded, we can just fetch the embed code
    if (matches != null) {
      this.fetch(matches[2])

    // No embed code, just reset the form
    } else {
      this.reset()
    }
  }

  fetch(value) {
    $.ajax({
      url: `/trix_videos/${encodeURIComponent(value)}`,
      type: 'get',
      error: this.reset.bind(this),
      success: this.showEmbed.bind(this)
    })
  }

  embed(event) {
    if (this.currentEmbed == null) { return }

    let attachment = new Trix.Attachment(this.currentEmbed)
    this.editor.insertAttachment(attachment)
    this.element.focus()
  }

  showEmbed(embed) {
    this.currentEmbed = embed
    this.embedContainerElement.style.display = "block"
  }

  reset() {
    this.embedContainerElement.style.display = "none"
    this.currentEmbed = null
  }
}


document.addEventListener("trix-initialize", event => {
  const { toolbarElement } = event.target
  const h1Button = $("[data-trix-attribute=heading1]", toolbarElement)
  h1Button.removeClass("trix-button--icon trix-button--icon-heading-1")
  h1Button.text("H1")
  h1Button[0].insertAdjacentHTML("afterend", `
    <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>
    <button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active="">H3</button>
  `)
  const trixDialogs = $(".trix-dialogs .trix-dialog--link", toolbarElement);
  // console.log(`trix Dialogs `, trixDialogs);
  trixDialogs[0].insertAdjacentHTML("beforeend", 
        '<div data-behavior="embed_container">\
          <div class="link_to_embed link_to_embed--new">\
            Would you like to embed media from this site?\
            <input class="btn btn-tertiary outline btn-small ms-3" type="button" data-behavior="embed_url" value="Yes, embed it">\
          </div>\
        </div>\
  ')
  var ec = new EmbedController(event.target)
  const cc = new ColumnController(event)
  // console.log('initialize Trix with: ', ec)

})

// document.addEventListener("trix-action-invoke", function(event) {
//   console.log(`called '${event.actionName}'`); 
//   console.log(`called on `, event.target);
//   var editor = event.target.editor;
//   if(event.actionName === "x-set-video"){
//     console.log("document: ", editor.getDocument().toString());
//     let position = editor.getPosition()
//     // editor.setSelectedRange([position - 2, position])
//     // editor.deleteInDirection("backward")
//   }
// })
