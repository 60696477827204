import ApplicationController from '../../../javascript/controllers/application_controller'
import croppie from 'croppie';
import { DirectUpload } from 'activestorage'

export default class extends ApplicationController {

  static targets = ['image']

  connect() {
    super.connect();
    const $cropArea = $(this.element)
    this.cropperModal = $cropArea.find('.modal')
    this.model = $cropArea.data("model")
    this.instanceId = $cropArea.data("instance-id")
    this.attributeName = $cropArea.data("attribute-name")
    this.dropZone = $cropArea.parent().find("a")
    this.imageSize = $cropArea.data("image-size")
  }


  showCroppingModal(event) {
    event.preventDefault();
    event.stopPropagation();
    
    this.cropperModal.modal('show')
  }

  initCropping(event) {
    event.preventDefault();
    event.stopPropagation();
    if(!(this.cropped == undefined)) {
      this.cropped.croppie('destroy')
      console.log("destroyed croppie")
    }
    console.log("init the cropping")
    // const image = $(this.imageTarget)
    const image = this.cropperModal.find('.modal-body img')
    const imageSource = this.dropZone.find('img')
    image.attr('src', imageSource.attr('src'))
    // console.log("image src: ", image.attr('src'))
    // console.log("image size: ", this.imageSize)
    const cropOpts = {
      viewport: { width: this.imageSize[0], height: this.imageSize[1] },
      boundary: { width: this.imageSize[0] * 2, height: this.imageSize[1] * 2 },
      showZoomer: true,
      enableOrientation: true,
      enableResize: false
    }
    this.cropped = image.croppie(cropOpts);
  }

  useCropped(event) {
    const self = this
    this.cropped.croppie('result', {
      type: 'base64',
      size: 'viewport'
    }).then(function (resp) {
      const imageSource = self.dropZone.find('img')
      $(imageSource).attr('src', resp)
      self.cropperModal.modal('hide')
    })
  }

}
