import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {

  openGroupModal(event) {
    event.preventDefault();
    const $target = $(event.currentTarget);
    const learnUnitId = $target.data("learnunit-id")
    const $modal = $(`#consumable_groups_modal_${learnUnitId}`)
    $modal.modal("show");
    $modal.find("input:first-of-type").focus()
  }

  createGroup(event) {
    event.preventDefault();
    const $target = $(event.currentTarget);
    const learnUnitId = $target.data("learnunit-id")
    const $modal = $(`#consumable_groups_modal_${learnUnitId}`)
    this.stimulate('ConsumableGroup#create_group', { serializeForm: true }).then(() => {
      $modal.modal("hide");
    })
  }

  removeGroup(event) {
    event.preventDefault();
    const $target = $(event.currentTarget);
    const groupId = $target.data("group-id")
    const $group = $target.closest(".consumable-group")
    this.stimulate('ConsumableGroup#remove_group', groupId).then(() => {
      $group.remove();
    })
  }

  addConsumable(event) {
    let target = $(event.currentTarget);
    let learn_unit_id = parseInt(target.data("learn-unit-id")),
      consumable_group_id = parseInt(target.data("consumable-group-id")),
      consumable_id = parseInt(target.data("consumable-id")),
      type = target.data("type");
    this.stimulate(
      "ConsumableGroupReflex#add_consumable",
      learn_unit_id,
      consumable_group_id,
      consumable_id,
      type
    ).then(() => {
      $("#create_consumable .modal").modal("show")
    })
  }

  createConsumable(event) {
    let target = $(event.currentTarget);
    let learn_unit_id = parseInt(target.data("learn-unit-id")),
      consumable_group_id = parseInt(target.data("consumable-group-id")),
      consumable_id = parseInt(target.data("consumable-id")),
      type = target.data("type");
    this.stimulate("ConsumableGroupReflex#create_consumable", { serializeForm: true }).then(() => {
      $("#create_consumable .modal").modal("hide")
    })
  }

  // addConsumableSuccess() {
  //   $("#create_consumable .modal").modal("show");
  // }

  reload(event) {
    let target = $(event.currentTarget);
    let consumable_group_id = parseInt(target.data("consumable-group-id"));
    this.stimulate("ConsumableGroupReflex#reload", consumable_group_id);
  }

}
