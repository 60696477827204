window.dispatchMapsEvent = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

window.mapsHelper = {
  defaultConfiguration: {
    center: { lat: 51.165693, lng: 10.451528 },
    zoom: 5
  },
  updateMapWithPlace: (mapApi, place, marker) => {
    if(place.geometry.viewport) {
      mapApi.fitBounds(place.geometry.viewport)
    }
    else {
      mapApi.setZoom(11)
    }
    mapApi.setCenter(place.geometry.location)

    if(marker) {
      marker.setPlace({ placeId: place.place_id, location: place.geometry.location })
      marker.setVisible(true)
    }
  }
}