import ApplicationController from './application_controller'
import Param from '../packs/param';

export default class UsersController extends ApplicationController {

  initialize() {
    super.initialize();
  }

  connect() {
    this.param = new Param(this.getBaseUrl());
  }

  userDraggingStart(event) {
    let target = $(event.target);
    event.preventDefault;
    copyDataToDataTransfer(event.dataTransfer, target.data());
  }

  showJourneyData(event) {
    let target = $(event.currentTarget),
      selected = target.hasClass("selected"),
      userId = parseInt(target.data("id"));
    $(".user-list-table.selected").removeClass("selected");
    if (!selected) target.addClass("selected");

    this.stimulate("UserReflex#showJourneyData", selected ? null : userId);
  }

  showUserList(event) {
    let target = $(event.currentTarget),
      groupBtn = $("#group_btn");
    groupBtn.removeClass("btn-secondary").addClass("btn-light");
    groupBtn
      .find(".material-icons")
      .removeClass("text-light")
      .addClass("text-secondary");
    target.addClass("btn-secondary").removeClass("btn-light");
    target
      .find(".material-icons")
      .addClass("text-light")
      .removeClass("text-secondary");

    this.stimulate("UsersReflex#showUserList");
  }

  showGroupList(event) {
    let target = $(event.currentTarget),
      userBtn = $("#user_btn");
    userBtn.removeClass("btn-secondary").addClass("btn-light");
    userBtn
      .find(".material-icons")
      .removeClass("text-light")
      .addClass("text-secondary");
    target.addClass("btn-secondary").removeClass("btn-light");
    target
      .find(".material-icons")
      .addClass("text-light")
      .removeClass("text-secondary");

    this.stimulate("UsersReflex#showGroupList");
  }

  setLocale(event) {
    const $target = $(event.currentTarget);
    const locale = $(event.currentTarget).data('value');
    console.log(`locale: ${locale}`)
  //   this.stimulate("IncognitoReflex#change_locale", locale).then((e) => {console.log('done')})
  //     .catch((e) => {
  //   console.log(`error: ${e}`)
    // })
  $.ajax({
    type: "POST",
    url: '/adapt_locale',
    data: {'locale': locale},
    success: (data) => {
      console.log('locale has been adapted');
      location.reload();
    }
  })

  }

  /*
    Toggles whether fake users are included

    @param event [Object] The click event object from the toggle element
    @option options [Boolean] :with_fakes Include (true) or exclude (false) fake users. Defaults to false.
    Toggles whether fake users are included by checking the value of the toggle element that triggered the event, updating the 'with_fakes' param, and reloading the page.
  */
  toggleFakeUser(event) {
    const $target = $(event.currentTarget);
    const withFakes = $target.prop('checked')
    this.param.updateParams("with_fakes", withFakes, false);
    location.reload();
  }
}

/*
* copies everything from JQuery.Data to DataTransfer.Data
* the values can be accessed by camelCase in the DataTransfer.Data object
*/
function copyDataToDataTransfer(dataTransfer, data) {
  for (let [key, value] of Object.entries(data)) {
    dataTransfer.setData(key, value);
  }
  return dataTransfer
}