import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {
  initialize() {
    super.initialize()
    $(this.element).modal('show')
    this.create_authenticy_token_for('#translation_form')
    this.create_authenticy_token_for('#translation_add_form')
    this.reset_csrf_token()
  }
}