import ApplicationController from './application_controller'
import Param from '../packs/param';

export default class extends ApplicationController {

  static values = {  
    scope: String
  }

  connect() {
    super.connect();
    this.param = new Param(this.getBaseUrl());
  }

  filter(event) {
    let target = $(event.currentTarget),
    paramKey = target.data('param'),
    array = target.data('array'),
    clear = target.data('clear'),
    value = $.trim(target.html());

    if(target.is("select")) {
      value = $.trim(target.val());
    }

    if(clear) {
      this.param.clearParam(paramKey, value);
    } else {
      this.param.updateParams(paramKey, value, array);
    }

    this.stimulate("filterSortReflex#filter", this.param.getParams());
  }

  search(event) {
    let target = $(event.currentTarget),
    paramKey = target.data('param'),
    value = $.trim(target.val());

    this.param.updateParams(paramKey, value, false);

    this.stimulate("filterSortReflex#filter", this.param.getParams());
  }

  sort(event) {
    let sortDirections = ['asc', 'desc'],
    target = $(event.currentTarget),
    sort = target.attr('data-sort-direction'),
    sortField = target.attr('data-sort-field');
    let scope = $("#table_wrapper").attr("data-filter-sort-scope-value")
    if(sort === 'desc') {
      sort = sortDirections[0];
    } else {
      sort = sortDirections[1];
    }

    $('[data-sort-direction]').attr('data-sort-direction', null);

    target.attr('data-sort-direction', sort);

    let json = JSON.parse(`{ "${sortField}": "${sort}" }`);
    // console.log("params: ", this.param)
    this.param.updateParams('sort', JSON.stringify(json), false);
    if(scope) {
      this.param.updateParams('scope', scope, false)
    } else {
      this.param.clearParam('scope')
    }
    // console.log("params: ", this.param.getParams())
    this.stimulate('filterSortReflex#filter', this.param.getParams());

  }

  filterSuccess(event) {
    window.reinitializeForms(document.body)
  }

  ///
  // a more generic search approach for filtering index records
  ///
  searchForIt(event) {
    let target = $(event.currentTarget)
    let paramName = target.data('paramname');
    if(paramName == undefined) {
      paramName = "query"
    }
    let term = $.trim(target.val());
    // console.log("term: ", term)
    this.param.clearParam("page", false);
    if(term) {
      this.param.updateParams(paramName, term, false);
    } else {
      this.param.clearParam(paramName)
    }
    // this.stimulate("searchReflex#filter"); // <-- as this has been a page morph only, the following js solution is much better
    location.reload();
  }

  ///
  // sets the correct params so the page morph trigered by stimulate will do the sorting by itself
  ///
  sortIt(event) {
    let target = $(event.currentTarget),
    sort = target.attr('data-sort-direction'),
    sortField = target.attr('data-sort-field');
    if(sort === 'desc') {
      sort = "asc";
    } else {
      sort = "desc";
    }
    $('[data-sort-direction]').attr('data-sort-direction', null);
    target.attr('data-sort-direction', sort);
    this.param.updateParams('sort', `${sortField}:${sort}`, false);
    this.stimulate('filterSortReflex#sort');
  }

}

