import { Controller } from '@hotwired/stimulus'
import ScrollMagic from 'scrollmagic'
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'

export default class extends Controller {

  static values = {
    config: Object,
    enabled: Boolean,
    embeddedClass: String
  }

  initialize() {
    super.initialize()
    this.debug = true
  }

  connect() {
    super.connect()

    if(!this.enabledValue) return

    // initialize scroll magic for the heavy lifting of detecting element is visible.
    const self = this
    this.scrollMagicController = new ScrollMagic.Controller()
    if (this.configValue) {

      let parent = $(this.element)

      if(this.embeddedClassValue !== '') {
        parent = $(`.${this.embeddedClassValue}`).find(this.element)
      }

      for (var key in this.configValue) {
        const animation = this.configValue[key]

        parent.find(key).each(function() {
          $(this).addClass('invisible')
          const scene = new ScrollMagic.Scene(
            {
              triggerElement: this,
              triggerHook: 0.9,
              reverse: false,
            }
          )
          if(this.debug) {
            scene.addIndicators({ name: this })
          }
          scene.on("enter", function (event) {
            //trigger the animate css animation we defined in the config
            const triggerElement = $(event.currentTarget.triggerElement())
            triggerElement.removeClass('invisible')
            triggerElement.addClass(`animate__animated ${animation}`)
          })
          scene.addTo(self.scrollMagicController)
        })
      }
    }
  }

  disconnect() {
    super.disconnect()
    // destroy the scroll magic controller along with the stimulus controller
    if (this.scrollMagicController != null) {
      this.scrollMagicController.destroy()
    }
  }
}