import { Controller } from '@hotwired/stimulus'
import Ajax from "../packs/ajax.js";

export default class NestedFormController extends Controller {
  static targets = ["template", "form", "phasePart"];

  connect() {
    this.initializeFormInitializationRoutine($(this.element));

    this.accept_for_type = function(type) {
      var accept = '';
      switch(type) {
        case 'audio':
          accept = 'audio/mpeg,audio/vnd.wave';
          break;
        case 'zip_archive':
          accept = 'application/zip';
          break;
        case 'ppt':
          accept = 'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation';
          break;
        case 'pdf':
          accept = 'application/pdf';
          break;
        default:
          break;
      }
      return accept;
    };

  }

  ///
  // calls the defined FormInitializationRoutines on the wrapped form
  // those routines create the right context for functionality in the js world
  ///
  initializeFormInitializationRoutine($wrapper) {
    // let $form = $("form", $wrapper)
    // // console.log("FORM: ", $form)
    // reinitializeForms($form)
  }

  getExistingPhasePosition(phaseId) {
    let elements = [];

    if (phaseId) {
      elements = $(`[value=${phaseId}]`);
    }
    let element = elements.first();
    let matches = $(element).attr("id").match(/\d+/g);

    return matches != null && matches.length > 0 ? parseInt(matches[0]) : -1;
  }

  getNextLearnUnitPosition(phasePosition, learnUnitId) {
    let elements = [],
      position = -1;

    if (learnUnitId) {
      elements = $(
        `[id^='journey_phases_attributes_${phasePosition}_learn_units_attributes_'][type=hidden][id$='id']:not([id$='consumable_attributes_id'], [value=${learnUnitId}], [id$='event_attributes_id'])`
      );
    }

    for (let element of elements) {
      let matches = $(element).attr("id").match(/\d+/g);
      let newPosition = parseInt(matches[1]);

      if (newPosition > position) {
        position = newPosition;
      }
    }

    return position + 1;
  }
}