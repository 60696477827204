this.autoComplete = function() {
  return $('.autocomplete').each(function() {
    $(this).autocomplete({
      source: $(this).data('source'),
      minLength: 2
    })
    return $(this).autocomplete('instance')._renderItem = function(ul, item) {
      return $('<li>').append(item.label).appendTo(ul)
    }
  })
}

$(document).ready(this.autoComplete)