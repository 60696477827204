import ApplicationController from '../../../javascript/controllers/application_controller';

export default class extends ApplicationController {
  initialize() {
    super.initialize()
    this.open()
  }

  open(event) {
    this.logInfo('open modal')
    $(this.element).modal('show')
  }

  close(event) {
    $(this.element).modal('hide')
  }
}