import ApplicationController from './application_controller'
import Ajax from '../packs/ajax'

export default class extends ApplicationController {

  static targets = ["page", "info", "previousBtn", "nextBtn", "endInfo", "audio", "duration"]

  initialize() {
    super.initialize()
    this.currentPage = 0
    // This defines how long the animation transition take in seconds
    this.animationTransition = .5
    this.autoplay_task = null
    // should we autoplay?
    this.autoplay = $(this.element).data('slide-autoplay') || false
    // the global set duration, the time is given in seconds
    this.globalDuration = $(this.element).data('slide-duration') || 3
    // which animation it should play, currently only fade is supported
    this.animation = $(this.element).data('slide-animation') || 'fade'
    // how many pages we have in total, this should also include the ones that are not yet loaded.
    this.totalPages = $(this.element).data('slide-total-pages') || $(this.element).find('.slide-page').length
    // from where can we lazy load the pages
    this.lazyLoadingUrl = $(this.element).data('slide-lazy-loading-url')

    this.logInfo(`autoplay: ${this.autoplay}`)
    this.logInfo(`global duration: ${this.globalDuration}`)
    this.logInfo(`animation: ${this.animation}`)
    this.logInfo(`total pages: ${this.totalPages}`)

    this.#setup()
  }

  connect() {
    super.connect()
    if(this.autoplay) {
      this.startSlideshow()
    } else {
      this.#hideDurationInfo()
    }
  }

  disconnect() {
    this.pauseSlideshow()
  }

  /**
   * Prepare the slide show with all necessary steps.
   */
  #setup() {
    if(this.hasPageTarget) {
      $(this.pageTargets).each((index, page) => {
        $(page).removeClass('slide-show').removeClass('slide-hidden')
        if(index === this.currentPage) {
          $(page).addClass('slide-show')
        } else {
          $(page).addClass('slide-hidden')
        }
      })
    }
    this.#updatePageProgress()
    this.#canHideNextBtn()
    this.#canHidePreviousBtn()
    if(!this.#hasReachedEnd()) {
      this.#hideEndInfo()
    }
  }

  /**
   * This will autoplay the slideshow. Only call this if you want to autoplay it.
   * @param {*} event 
   */
  startSlideshow(event) {
    setTimeout(() => {
      this.#showDurationInfo()
      this.runSlideshow()
      this.logInfo('start slideshow')
    }, 500)
  }

  /**
   * Helper method to call it recursive.
   * It will slide through all pages until it reaches the end.
   */
  runSlideshow() {
    this.#playbackAudioIfPossible()
    const totalAnimationTransitionTime = this.#convertSecondsToMilliseconds(this.animationTransition) * 2
    this.#updateDurationInfo()
    this.autoplay_task = setTimeout(async () => {
      await this.nextSlide()

      if(this.#hasReachedEnd()) { 
        this.showEnd()
      } else {
        this.runSlideshow()
      }
    }, this.#convertSecondsToMilliseconds(this.#getCurrentPageDuration()) + totalAnimationTransitionTime)
  }

  /**
   * This will display the end information after the timeout of the current page slide
   */
  showEnd() {
    this.#updateDurationInfo()
    setTimeout(() => {
      this.#displayEndInfo()
    }, this.#convertSecondsToMilliseconds(this.#getCurrentPageDuration()))
  }

  /**
   * This will pause the slideshow.
   * @param {*} event 
   */
  pauseSlideshow(event) {
    this.#pauseAudioIfPossible()
    this.#hideDurationInfo()
    if(this.autoplay_task) {
      clearTimeout(this.autoplay_task)
      this.logInfo('pause slideshow')
    }
  }

  /**
   * Reset all needed parameter and then scroll back to page 1.
   * If autoplay is set it will autostart the slideshow.
   * @param {*} event 
   */
  restartSlideshow(event) {
    this.pauseSlideshow()
    this.#updateSlidePage(this.currentPage, 0).then(() => {
      if(this.autoplay) {
        this.startSlideshow()
      }
    })
    this.#hideEndInfo()
  }

  /**
   * scroll to next page. It will lazy load the page after next if needed.
   */
  async nextSlide(event) {
    if(this.#hasReachedEnd()) {
      return
    }

    if(this.animationRunning) {
      return
    }

    this.#lazyLoadPage(this.currentPage + 2)

    await this.#pauseAudioIfPossible()

    //hide current page with animation after animation done play next one
    await this.#updateSlidePage(this.currentPage, this.currentPage + 1)

    await this.#playbackAudioIfPossible()
  }

  async previousSlide(event) {
    if(this.#hasReachedStart()) {
      return
    }

    if(this.animationRunning) {
      return
    }

    await this.#pauseAudioIfPossible()

    //hide current page with animation after animation done play next one
    await this.#updateSlidePage(this.currentPage, this.currentPage - 1)

    await this.#playbackAudioIfPossible()
  }

  /**
   * This will animate beetween current and next page.
   * @param {Integer} current page to hide
   * @param {Integer} next page to show
   */
  async #updateSlidePage(current, next) {

    if(this.hasPageTarget){  
      this.#disableInteractionBtns()
      await this.#animatePageOut(this.pageTargets[current])
      this.currentPage = next
      this.#updatePageProgress()
      this.#canHideNextBtn()
      this.#canHidePreviousBtn()
      await this.#animatePageIn(this.pageTargets[next])
      this.#afterPageUpdate()
    }
  }

  /**
   * @returns {Integer} either the page duration of the current page or if not set the global set duration
   */
  #getCurrentPageDuration() {
    const page = this.pageTargets[this.currentPage]
    const pageDuration = $(page).data('slide-duration')
    return pageDuration || this.globalDuration
  }

  /**
   * This will lazy load the page with the given lazy-loading-url.
   * It will only try to load a page if we didn't reach the end yet.
   * @param {Integer} page to load 
   * @returns 
   */
  async #lazyLoadPage(page) {
    if(page >= this.totalPages) {
      return
    }

    // when we have all pages loaded we dont need to load any pages anymore
    if(this.pageTargets.length == this.totalPages) {
      return
    }

    if(this.lazyLoadingUrl) {
      const response =  await new Ajax(this.lazyLoadingUrl).doAjax('get', { page: page })
      // we get the html content pretty printed back so erase all \n otherwise it will not get parsed correctly.
      const $slide_page = $(response.replaceAll('\n', ''))
      $slide_page.addClass('slide-hidden')
      $(this.element).append($slide_page)
    }
  }

  /**
   * This will animate a page out.
   * It will resolve as soon as the animation is done.
   * @param {Integer} page 
   * @returns {Future}
   */
  async #animatePageOut(page){
    $(page).css('animation-duration', `${this.animationTransition}s`)
    $(page).removeClass('slide-show')
    $(page).addClass('slide-hidden')
    return new Promise((resolve, _) => setTimeout(() => resolve(), this.#convertSecondsToMilliseconds(this.animationTransition)))
  }

  /**
   * This will animate a page in.
   * It will resolve as soon as the animation is done.
   * @param {Integer} page 
   * @returns 
   */
  async #animatePageIn(page){
    $(page).css('animation-duration', `${this.animationTransition}s`)
    $(page).removeClass('slide-hidden')
    $(page).addClass('slide-show')
    return new Promise((resolve, _) => setTimeout(() => resolve(), this.#convertSecondsToMilliseconds(this.animationTransition)))
  }

  /**
   * Update the information on which page we are.
   */
  #updatePageProgress() {
    if(this.hasInfoTarget) {
      $(this.infoTarget).html(`${this.currentPage + 1}/${this.totalPages}`)
    }
  }

  /**
   * as soon as the animation is done we will enable the interactions buttons again.
   */
  #afterPageUpdate() {
    this.#enableInteractionBtns()
    if(this.#hasReachedEnd()) {
      this.showEnd()
    }
  }

  /**
   * Whenever we have reached the start it will hide the previous button.
   */
  #canHidePreviousBtn() {
    if(this.hasPreviousBtnTarget) {
      if(this.#hasReachedStart()) {
        $(this.previousBtnTarget).addClass('hidden')
      } else {
        $(this.previousBtnTarget).removeClass('hidden')
      }
    }
  }

  /**
   * Whenever we have reached the end it will hide the next button.
   */
  #canHideNextBtn() {
    if(this.hasNextBtnTarget) {
      if(this.#hasReachedEnd()) {
        $(this.nextBtnTarget).addClass('hidden')
      } else {
        $(this.nextBtnTarget).removeClass('hidden')
      }
    }
  }

  /**
   * Disable the previous and next button.
   */
  #disableInteractionBtns() {
    this.logInfo('disable interactions')
    this.animationRunning = true
    if(this.hasNextBtnTarget) {
      $(this.nextBtnTarget).addClass('disabled')
    }
    if(this.hasPreviousBtnTarget) {
      $(this.previousBtnTarget).addClass('disabled')
    }
  }

  /**
   * Enable the previous and next button.
   */
  #enableInteractionBtns() {
    this.logInfo('enable interactions')
    this.animationRunning = false
    if(this.hasNextBtnTarget) {
      $(this.nextBtnTarget).removeClass('disabled')
    }
    if(this.hasPreviousBtnTarget) {
      $(this.previousBtnTarget).removeClass('disabled')
    }
  }

  /**
   * This will show the restart and 'got it' button.
   */
  #displayEndInfo() {
    if(this.hasEndInfoTarget){
      $(this.endInfoTarget).removeClass('hidden')
    }
  }

  /**
   * This will hide the restart and 'got it' button.
   */
  #hideEndInfo() {
    if(this.hasEndInfoTarget){
      $(this.endInfoTarget).addClass('hidden')
    }
  }

  async #playbackAudioIfPossible() {
    if(this.hasAudioTarget) {
      const currentAudio = $(this.audioTargets[this.currentPage])[0]
      console.log(currentAudio)
      if(currentAudio.readyState >= 2) {
        await currentAudio.play()
      } else {
        currentAudio.addEventListener('loadeddata', (event) =>  {
          if($(this.audioTargets[this.currentPage])[0].id !== currentAudio.id) {
            return
          }
          currentAudio.play()
        })
      }
    }
  }

  async #pauseAudioIfPossible() {
    if(this.hasAudioTarget) {
      const currentAudio = $(this.audioTargets[this.currentPage])[0]
      if(!currentAudio.paused) {
        await currentAudio.pause()
      }
    }
  }

  #updateDurationInfo() {
    if(this.hasDurationTarget) {
      const leftProgress = $(this.durationTarget).find('.left-part .fill')
      const rightProgress = $(this.durationTarget).find('.right-part .fill')
      const duration = this.#getCurrentPageDuration() / 2
      leftProgress.css('animation-name', 'none')
      rightProgress.css('animation-name', 'none')
      leftProgress.css('animation-duration', '')
      rightProgress.css('animation-duration', '')
      setTimeout(() => {
        leftProgress.css('animation-name', '')
        rightProgress.css('animation-name', '')
        leftProgress.css('animation-duration', `${duration}s`)
        rightProgress.css('animation-delay', `${duration}s`)
        rightProgress.css('animation-duration', `${duration}s`)
      }, this.#convertSecondsToMilliseconds(this.animationTransition))
    }
  }

  #hideDurationInfo() {
    if(this.hasDurationTarget) {
      $(this.durationTarget).addClass('hidden')
    }
  }

  #showDurationInfo() {
    if(this.hasDurationTarget) {
      $(this.durationTarget).removeClass('hidden')
    }
  }

  /**
   * @returns {Boolean}
   */
  #hasReachedStart() {
    return this.currentPage <= 0
  }

  /**
   * @returns {Boolean}
   */
  #hasReachedEnd() {
    return this.currentPage >= this.totalPages - 1
  }

  /**
   * @param {Double} timeInSeconds 
   * @returns {Integer} returns the given seconds in milliseconds
   */
  #convertSecondsToMilliseconds(timeInSeconds) {
    return timeInSeconds * 1000
  }
}