import "@hotwired/turbo"

Turbo.setConfirmMethod((message, element, submitter) => {
  let originalElement = submitter

  if (!submitter) {
    let matchingElements = document.querySelectorAll(
      `a[data-turbo-confirm="${message}"][data-turbo-method="${element.getAttribute('method')}"]`
    )
    originalElement = [...matchingElements].find((el) => element.action.includes(el.href))
  }

  const dialog = $('#data_confirm_modal')
  dialog.modal('show')

  dialog.find('.message').html(message)

  if($(originalElement).data('turbo-commit') != null) {
    dialog.find('.modal-footer .confirm').html($(originalElement).data('turbo-commit'))
  }

  return new Promise((resolve, reject) => {
    dialog[0].addEventListener('hide.bs.modal', () => {
      resolve(false)
    }, { once: true })
    dialog.find('.modal-footer .confirm')[0].addEventListener('click', () => {
      resolve(true)
      dialog.modal('hide')
    }, { once: true })
  })
})