class ClassEventsES6 {
  constructor() {
    this.listeners = new Map();
    this.onceListeners = new Map();
    this.triggerdLabels = new Map();
  }

  // help-function for onReady and onceReady
  // the callbackfunction will execute,
  // if the label has already been triggerd with the last called parameters
  _fCheckPast(label, callback) {
    if (this.triggerdLabels.has(label)) {
      callback(this.triggerdLabels.get(label));
      return true;
    } else {
      return false;
    }
  }

  // execute the callback everytime the label is trigger
  on(label, callback, checkPast = false) {
    this.listeners.has(label) || this.listeners.set(label, []);
    this.listeners.get(label).push(callback);
    if (checkPast)
      this._fCheckPast(label, callback);
  }

  // execute the callback everytime the label is trigger
  // check if the label had been already called
  // and if so excute the callback immediately
  onReady(label, callback) {
    this.on(label, callback, true);
  }

  // execute the callback onetime the label is trigger
  once(label, callback, checkPast = false) {
    this.onceListeners.has(label) || this.onceListeners.set(label, []);
    if (!(checkPast && this._fCheckPast(label, callback))) {
      // label wurde nocht nicht aufgerufen und
      // der callback in _fCheckPast nicht ausgeführt
      this.onceListeners.get(label).push(callback);
    }
  }
  // execute the callback onetime the label is trigger
  // or execute the callback if the label had been called already
  onceReady(label, callback) {
    this.once(label, callback, true);
  }

  // remove the callback for a label
  off(label, callback = true) {
    if (callback === true) {
      // remove listeners for all callbackfunctions
      this.listeners.delete(label);
      this.onceListeners.delete(label);
    } else {
      // remove listeners only with match callbackfunctions
      let _off = (inListener) => {
        let listeners = inListener.get(label);
        if (listeners) {
          inListener.set(label, listeners.filter((value) => !(value === callback)));
        }
      };
      _off(this.listeners);
      _off(this.onceListeners);
    }
  }

  // trigger the event with the label
  trigger(label, ...args) {
    let res = false;
    this.triggerdLabels.set(label, ...args); // save all triggerd labels for onready and onceready
    let _trigger = (inListener, label, ...args) => {
      let listeners = inListener.get(label);
      if (listeners && listeners.length) {
        listeners.forEach((listener) => {
          listener(...args);
        });
        res = true;
      }
    };
    _trigger(this.onceListeners, label, ...args);
    _trigger(this.listeners, label, ...args);
    this.onceListeners.delete(label); // callback for once executed, so delete it.
    return res;
  }
}

export default class VideoPlayer extends ClassEventsES6 {

  static async init({init_youtube= true, init_vimeo= true} = {}) {
    // let firstScriptTag = document.getElementsByTagName('script')[0];

    // if(init_youtube) {
    //   let tag = document.createElement('script');
    //   tag.src = 'https://www.youtube.com/iframe_api';
    //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    // }

    // if(init_vimeo) {
    //   let tag = document.createElement('script');
    //   tag.src = 'https://player.vimeo.com/api/player.js';
    //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    // }
  }

  constructor({id, url, type} = {}) {
    super();
    this.id = id;
    this.url = url;
    this.type = type;
  }

  isVimeo() {
    return /vimeo/.test(this.url);
  }

  createPlayer() {
    let self = this;
    if(this.type == "vimeo") {
      console.log("generating Vimeo Video");
      this.player = new Vimeo.Player(this.id, {
        url: this.url,
        responsive: true,
      });
      this.player.on('ended', function() {
        self.trigger('ended');
      })
    } else {
      console.log("generating Youtube Video");
      this.player = new YT.Player(this.id, { events: { 'onReady': (event) => { self.onVideoReady(event, self) }, 'onStateChange': (event) => { self.playerStateChange(event, self) } } })
      // this.player = new YT.Player(this.id, { videoId: cutYoutubeId(self.url), playerVars: {autoplay: 0, showinfo: 0, controls: 2, start: 0}, events: { 'onReady': (event) => { self.onVideoReady(event, self) }, 'onStateChange': (event) => { self.playerStateChange(event, self) } } })
    }
  }

  stopVideo() {
    if(this.type == "vimeo") {
      this.player.pause();
    } else {
      this.player.pauseVideo();
    }
  }

  startVideo() {
    if(this.type == "vimeo") {
      this.player.play();
    } else {
      this.player.playVideo();
    }
  }

  onVideoReady(event, self) {
    let player = event.target;
    let youtubeId = cutYoutubeId(self.url);
    player.loadVideoById(youtubeId);
    console.log("video ready event: ", event)
    const video_id = player.getIframe().id.replace('video_player_', '');

    const myEvent = new CustomEvent("YTVideo.ready", {
      detail: {video_id: video_id, player: player},
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    // console.log("myEvent: ", myEvent)
    document.dispatchEvent(myEvent);
    // window.onAcademyPlayerReady(event)
  }

  playerStateChange(event, self) {
    let state = event.data
    // console.log("state changed to: ", state)
    switch(state) {
      case YT.PlayerState.ENDED:
        self.trigger('ended');
        break;
    }
  }
}
function cutYoutubeId(url) {
  let regex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  let match = url.match(regex);
  return match[1];
}