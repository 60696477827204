import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  async openPhases(event) {
    event.preventDefault()
    this.startLoading()
    await this.stimulate('Translation::Translatables#open_phases')
    this.stopLoading()
  }

  async openHardfacts(event) {
    event.preventDefault()
    this.startLoading()
    await this.stimulate('Translation::Translatables#open_hardfacts')
    this.stopLoading()
  }

  async openConsumables(event) {
    event.preventDefault()
    this.startLoading()
    await this.stimulate('Translation::Translatables#open_consumables')
    this.stopLoading()
  }

  async openEvent(event) {
    event.preventDefault()
    this.startLoading()
    await this.stimulate('Translation::Translatables#open_event')
    this.stopLoading()
  }

  async openLearnUnit(event) {
    event.preventDefault()
    this.startLoading()
    await this.stimulate('Translation::Translatables#open_learn_unit')
    this.stopLoading()
  }
}