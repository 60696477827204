import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["content", "form"];

  initialize() {
    super.initialize();
    this.stateArea = $(this.element).find('[data-state]');
    this.state = this.stateArea.data('state');
    this.setState(this.state);
  }

  /**
   * This will update the current state to a new one.
   * It will hide the previous state and display either the form or the content depending on state.
   * It will also update the content if necessary.
   * @param {String} state - the current state 
   */
  setState(state) {
    this.state = state
    this.stateArea.find(`.${this.state}`).addClass('hidden');
    this.stateArea.find(`:not(.${this.state})`).removeClass('hidden');

    this.stateArea.data('state', this.state)

    if (this.state === 'show') {
      this.updateContent(this.getFormValue());
    }

    this.toggleForm()
    this.toggleContent()
  }

  /**
   * Toggle between edit and show state.
   * @param {*} event 
   */
  toggleState(event) {
    event.preventDefault();
    const $target = $(event.currentTarget);
    const state = $target.data('state');
    this.setState(state === 'edit' ? 'show' : 'edit');
  }

  /**
   * this will hide or show the form depending on the state.
   */
  toggleForm() {
    if (this.hasFormTarget) {
      if (this.state === 'edit') {
        $(this.formTarget).removeClass('hidden');
      } else {
        $(this.formTarget).addClass('hidden')
      }
    }
  }

  /**
   * This will hide or show the content depending on the state
   */
  toggleContent() {
    if (this.hasContentTarget) {
      if (this.state === 'show') {
        $(this.contentTarget).removeClass('hidden');
      } else {
        $(this.contentTarget).addClass('hidden')
      }
    }
  }

  /**
   * @returns {String} the form value or empty string if no form target is set.
   */
  getFormValue() {
    if (this.hasFormTarget) {
      return $(this.formTarget).find('textarea').val();
    }

    return "";
  }

  /**
   * This will update the content target if set.
   * @param {String} content - the new content that should be filled.
   */
  updateContent(content) {
    if (this.hasContentTarget) {
      $(this.contentTarget).html(content);
    }
  }
}
