import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = ['rating']

  connect() {
    this.initRatings()
  }

  initRatings() {
    $(this.ratingTargets).each(function(i, el) {
      var $el, $ratingAverage, $ratingLabel, $ratingTooltip, $ratingUser
      $el = $(el)
      if ($el.data("initializedRating")) {
        return
      }
      $el.data("initializedRating", true)
      $ratingAverage = $el.find(".rating-average")
      $ratingUser = $el.find(".rating-user")
      $ratingLabel = $el.find(".rating-average-label")
      $ratingAverage.barrating({
        theme: "custom-" + ($el.attr("data-styling")),
        initialRating: Math.round(parseFloat($ratingAverage.attr("data-value"))),
        readonly: true
      });
      if (!$ratingUser.length) {
        return
      }
      $ratingUser.barrating({
        theme: "custom-" + ($el.attr("data-styling")),
        showSelectedRating: false,
        allowEmpty: false,
        deselectable: false
      });
      $ratingTooltip = $ratingAverage.parents(".br-wrapper")
      $ratingTooltip.tooltipster({
        animation: 'fade',
        trigger: 'click',
        delay: 200,
        side: 'bottom',
        interactive: true,
        theme: 'tooltipster-rating',
        content: $el.find(".rating-tooltip-content"),
        functionReady: function(instance, helper) {
          return $ratingUser.barrating("set", $ratingUser.attr("data-value"))
        }
      })
      return $el.find(".rating-submit").click(function() {
        var value
        value = $ratingUser.data("barrating").ratingValue
        if (!value) {
          return false
        }
        $.post($el.attr("data-path"), {
          value: value
        }, function(data) {
          var rating
          rating = parseFloat(data["average_rating"])
          $ratingAverage.barrating("set", Math.round(rating))
          $ratingUser.attr("data-value", value)
          $ratingLabel.html(rating.toFixed(1))
          return $ratingTooltip.tooltipster("close")
        });
        return false
      })
    })
  }
}