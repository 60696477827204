import ApplicationController from './application_controller'
import Dropzone from 'dropzone'

export default class extends ApplicationController {
  initialize() {
    super.initialize()

    this.allowedFileTypes = $(this.element).data('allowed-types')
    this.uploadUrl = $(this.element).data('upload-url')

    const dropzone = new Dropzone(this.element, {
      url: this.uploadUrl, uploadMultiple: true, 
      clickable: false, acceptedFiles: this.allowedFileTypes,
      parallelUploads: 100,
      disablePreviews: true,
    })

    dropzone.on('addedfile', (file) => {
      this.startLoading()      
    })

    dropzone.on('error', (file, message) => {
      this.stopLoading()      
    })
  }
}