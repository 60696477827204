import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  ///
  // handles the selection of a card element by adding or removing the "selected" CSS class, 
  // and updating the border color and background color of the selected card based on its data attributes.
  ///
  selected(event) {
    if($(event.target).closest("a").length > 0 && event.target != event.currentTarget) {
      // do nothing should not interest you!
      console.log($(event.target).closest("a"))
    } else {
      event.stopPropagation()
      event.preventDefault()
      let $currentTarget = $(event.currentTarget)

      if($currentTarget.data('target')) {
        console.log($currentTarget.data('target'))
        $currentTarget = $(`#${$currentTarget.data('target')}`)
      }

      const hasClass = $currentTarget.hasClass("selected")

      $(".card.selected").css({
        "border-color": "",
        "background-color": ""
      })
      $(".card.selected").removeClass("selected")
      if (!hasClass) {
        $currentTarget.addClass("selected")
        const color = $currentTarget.attr("data-color")
        const backgroundColor = $currentTarget.attr("data-background-color")
        if (color) {
          $currentTarget.css({
            "border-color": color,
          })
        }
        if(backgroundColor) {
          $currentTarget.css({
            "background-color": backgroundColor,
          })
        }
        let $groupCard = $currentTarget.closest(".card.group-definition-item").first()
        selectGroupCard($groupCard)
      }
    }
  }

  ///
  // handles the selection of a group card element by adding or removing the "selected" CSS class,
  // and updating the border color and background color of the selected card based on its data attributes.
  ///
  groupSelected(event) {
    event.stopPropagation()
    event.preventDefault()
    let $currentTarget = $(event.currentTarget)
    let $groupCard = $currentTarget.closest(".card.group-definition-item").first()

    // const hasClass = $groupCard.hasClass("selected")

    selectGroupCard($groupCard)
  }

}

function selectGroupCard($groupCard) {
  $(".card.group-definition-item").css({
    "border-color": "",
    "background-color": ""
  })
  // $(".card.group-definition-item.selected").removeClass("selected")
  // $groupCard.addClass("selected")
  const color = $groupCard.attr("data-color")
  const backgroundColor = $groupCard.attr("data-background-color")
  if (color) {
    $groupCard.css({
      "border-color": color,
    })
  }
  if (backgroundColor) {
    $groupCard.css({
      "background-color": backgroundColor,
    })
  }
}
