import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    const self = this;
    $("select.color-palette").select2({
      placeholder: "choose color from palette", //placeholder
      templateResult: self.setOptionClass,
      templateSelection: self.setOptionClass
    });

  }

  setOptionClass (color) {
    if (!color.id) { return color.text; }
    var $color = $('<div class="bgc-pc-' + color.element.value + '">&nbsp;</div>');
    return $color;
  };

}