import ApplicationController from "../../../javascript/controllers/application_controller"
import Draggable from "../../../javascript/packs/draggable"

export default class extends ApplicationController {

  initialize() {
    super.initialize()
    this.currentDraggable = null
    this.minimumAnswersSorted = $(this.element).data('minimum-answers-sorted')
    this.draggable = new Draggable(false)
    this.#defineDropzonesHandlers()
  }

  #defineDropzonesHandlers() {
    const self = this
    $(this.element).on('dragenter', '[dropzone=true]', function(ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      if(!self.#contains($container, self.currentDraggable)) {      
        $container.addClass('entered')
      }
    })

    // define the dragleave behavior
    $(this.element).on('dragleave', '[dropzone=true]', function(ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      $container.removeClass('entered')
    })

    $(this.element).on('dragstart', '[draggable=true]', function(ev) {
      self.currentDraggable = $(ev.currentTarget)
    })

    $(this.element).on('dragover', '[dropzone=true]', function(ev) {
      ev.preventDefault()
    })

    $(this.element).on('drop', '[dropzone=true]', function(ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      $container.removeClass('entered')
      self.#drop(ev.originalEvent, self)
    })
  }

  #drop(event, self) {
    event.stopPropagation()
    // we can only fill in one item. So we will marked it as filled
    let $container = $(event.target).closest('[dropzone=true]')

    // move the item itself to the sortable container
    const originTarget = self.#getOriginTarget(event)

    const oldPoints = self.#calculatePoints(originTarget.closest('[dropzone]'), originTarget)

    self.#moveElementTo(originTarget, $container, self)

    const newPoints = self.#calculatePoints($container, originTarget)

    self.#enableDropzoneIfEmpty()
    self.#dispatchQuestionState()
    self.#sendPointUpdate(oldPoints, newPoints)
  }

  #getOriginTarget(event) {
    return $(`#${event.dataTransfer.getData('eliminationTargets')}`)
  }

  #moveElementTo(element, to, self) {
    if(!self.#contains(to, element)) {
      element.appendTo(to)
    }
  }

  #contains(container, contained) {
    const id = `#${contained.attr('id')}`
    return container.find(id).length > 0
  }

  #calculatePoints(container, answer) {
    const weight = $(container).data('weight')
    // we have a container with weight, so we can calculate the points
    if(weight) {
      const points = answer.data('points')
      const calculatedPoints = {}
      for(var key in points) {
        calculatedPoints[key] = points[key] * weight
      }

      return calculatedPoints
    }
    return null
  }

  #enableDropzoneIfEmpty() {
    $(this.element).find('.sort-container').each((index, dropzoneContainer) => {
      if($(dropzoneContainer).find('.sort-answer').length == 0) {
        $(dropzoneContainer).attr('dropzone', 'true')
      } else {
        $(dropzoneContainer).attr('dropzone', 'false')
      }
    })
  }

  #dispatchQuestionState() {
    const sortContainer = $(this.element).find('.sort-container')
    const answersSorted = sortContainer.find('.sort-answer').length

    const questionComplete = new CustomEvent('wizard.quiz.question_state', {
      detail: {
        question_complete: answersSorted >= this.minimumAnswersSorted
      }
    })
      this.element.dispatchEvent(questionComplete)
  }

  #sendPointUpdate(oldPoints, newPoints) {
    const pointUpdateEvent = new CustomEvent('wizard.quiz.point_update', {
      detail: { 
        oldPoints: oldPoints,
        newPoints: newPoints
      }
    })
    this.logInfo(`dispatching point update`)
    this.element.dispatchEvent(pointUpdateEvent)
  }
}