import { Controller } from '@hotwired/stimulus';

export default class ChatController extends Controller {

  static targets = ['container', 'item', 'participants'];

  connect() {
    this.scrollToBottom();
    if(this.hasContainerTarget) {
      let self = this; 
      $(this.containerTarget).on('DOMNodeInserted', (event) => {
        self.onNodeAdded(self, event);
      });
    }
  }

  toggleParticipants(event) {
    if(this.hasParticipantsTarget) {
      $(this.participantsTarget).toggleClass('closed');
    }
  }


  onNodeAdded(self, event) {
    // Check if a new message was added
    if($(event.target).hasClass('chat-item')) {
      self.scrollToBottom('smooth');
    }
  }
  
  // itemTargetDisconnected(element) {
  //   console.log(element);
  // }

  scrollToBottom(behaviour = 'auto') {
    let lastItem = $(this.itemTargets).last();
    if(typeof(lastItem) != undefined) {
      let domItem = lastItem.get(0);
      if(typeof(domItem) != 'undefined') {
        domItem.scrollIntoView({ behaviour: behaviour });
      }
    }
  }
}