import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {


  initialize() {
    super.initialize()
  }
  connect() {
    super.connect();
    this.tooltipList = []
    this.showPopups()
  }

  showPopups() {
    const tooltipTriggerList = $("#received_certificates .cert.remind")
    this.tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {customClass: 'warning', placement: 'bottom', trigger: 'manual'}))
    this.tooltipList.forEach(tooltip => {
      console.log(tooltip)
      tooltip.show()
    });
  }

}
