import ApplicationController from './application_controller'
import { DirectUpload } from 'activestorage'

class Ajax {
  constructor(url) {
    this.url = url
  }

  async doAjax(method, args) {

    let result

    try {
      result = await $.ajax({
        url: this.url,
        method: method,
        data: args
      })

      return result
    } catch (error) {
      console.error(error)
    }
  }
}

export default class extends ApplicationController {

  initialize() {
    super.initialize()
    this.useReflex = true
    this.assetsUrl = "/media-library/render_asset"
    this.directUploadUrl = "/active_storage/intercepted_uploads"
    // this.directUploadUrl = "/rails/active_storage/direct_uploads"
  }
  
  connect() {
    super.connect()
    // find form, if there is one <-- we have to react on a form submit to be able to send images at latest possible time
    this.dropZone = $(this.element)
    console.log("this.dropZone: ", this.dropZone)
    // this.form = this.dropZone.closest('form')
    // if(this.form.length) {
    //   this.form.on('submit', this.directImageUpload.bind($, this.dropZone));
    // }
    this.attributeName = $(this.element).data("attribute-name")
    // console.log("this.attributeName: ", this.attributeName)
  }

  disconnect() {
    // Remove the event listener when the controller disconnects from the element
    // this.form.off('submit');
    super.disconnect()
  }

  ///
  // !!as the form would be submitted multiple, if there is more than one file input in the form, the approach of interferring the form submission, set data accordingly and then submit it from js is not a valid one!!  <-- we have to upload file data one step before, even we then might get orphaned blobs in db
  ///
  directImageUpload(dropZone, event) {
    event.preventDefault();
    console.log("event: ", event)
    // Access the form element
    const form = event.currentTarget;
    console.log("form: ", form)

    // Modify form data
    const formData = new FormData(form);
    console.log("formData: ", formData)
    console.log("serialized: ", $(form).serializeArray())

    ///
    // takes a base64 encoded image and transforms it into a File object
    ///
    const base64DataToFile = function base64DataToFile(base64Data, filename) {
      var arr = base64Data.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    // const dropZone = event.data.dropZone
    console.log("dropZone: ", dropZone)
    const imageData = dropZone.find('img.result').attr('src')
    console.log("we load it up, this image: ", imageData)
    if(imageData && imageData.startsWith('data:')) {


      const imageFile = base64DataToFile(imageData, `${this.attributeName}.png`)
      const directUpload = new DirectUpload(imageFile, "/rails/active_storage/direct_uploads");
      directUpload.create(function(error, blob) {
        if (error) {
          // Handle the error
          console.log(error)
        } else {
          // Access the signed ID from the server response
          const signedId = blob.signed_id;
          console.log("signed id: ", signedId)
          // Perform any other modifications to the form data

          // Submit the form programmatically with the modified data
          // fetch(form.action, {
          //   method: form.method,
          //   body: formData
          // })
          //   .then(response => {
          //     // Handle the response as needed
          //   })
          //   .catch(error => {
          //     // Handle any errors
          //   });

          // dropZone.val(signedId)
          // event.target.submit()
        }
      });
    } else {
      event.target.submit()
    }
  }

  ///
  // stimulates to render the media library modal and/or opens it
  ///
  openModal(event) {
    // const $target = $(event.currentTarget)
    let $modal = $(`#media_library_modal_${$(this.element).data('unique-id')}`)
    if($modal.length) {
      // we just open the existing modal
      $modal.modal('show')
    } else {
      this.stimulate('MediaLibraryReflex#open_modal', event.currentTarget).then(() => {
        $modal = $(`#media_library_modal_${$(this.element).data('unique-id')}`)
        $modal.modal('show')
        // console.log("modal opened")
      });
    }
  }

  ///
  // disposes the modal, so it must be rendered again (no artifacts in form)
  // @info: msut be called from an element within the modal itself
  ///
  disposeModal(event) {
    // const $target = $(event.currentTarget)
    // console.log("this.element: ", this.element);
    let $modal = $(this.element)
    if($modal.length) {
      // we dispose the existing modal
      console.log("dispose modal")
      $modal.modal('hide')
      $modal.remove()
      // console.log("this.element (after removal): ", this.element);
    } else {
      console.log("modal not found!")
    }
  }

  async choose(event) {
    this.startLoading()
    const $target = $(event.currentTarget),
    $targetToSend = $($target.data('target')),
    $parent = $target.closest('.modal'),
    self = this
    let dropZoneImage = $targetToSend.parent().find('img')

    if(dropZoneImage.length == 0) {
      dropZoneImage = $("<img src=''>")
      $targetToSend.parent().after(dropZoneImage)
      $targetToSend.parent().find('i').remove()
    }
    // console.log("target: ", $target)
    // console.log("targetToSend: ", $targetToSend)

    const $activePane = $parent.find('.tab-pane.show.active')
    let signedId = null

    /// using a file from media library/archive
    if($activePane.hasClass('library-container')) {
      const selectedThumbnail =  $activePane.find('.img-thumbnail.selected')
      signedId = selectedThumbnail.data('signed-id')
      // console.log("signed id for library: ", signedId)
      const img = selectedThumbnail.find('img')
      if (img.length > 0) {
        // console.log("transfer image src")
        dropZoneImage.attr('src', selectedThumbnail.find('img').attr("src"));
      } else {
        // console.log("get file info")
        const fileInfo = selectedThumbnail.find('.file-info').clone()
        const uploadLink = $targetToSend.closest("a")
        uploadLink.find('i').remove()
        uploadLink.find('.file-info').remove()
        fileInfo.appendTo(uploadLink)
      }

    /// using an uploaded file
  } else {
      // console.log("uploading...")
      const $uploadContainer = $activePane.find('.upload-container')
      const fileContainer = $uploadContainer.find('input[type=file]')

      if($uploadContainer.data('base64')) { // set when we cropped the file <-- extra handling needed
        const imageData = $uploadContainer.find('.upload-preview img').attr('src')
        const imageFile = this.base64DatatoFile(imageData, "custom.png")
        const subdomainToUse = $(this.element).data("subdomain-to-use")
        let fullUploadUrl 
        if(subdomainToUse) {
          // console.log("this.subdomainToUse: ", subdomainToUse)
          fullUploadUrl = `${this.directUploadUrl}?subdomain_to_use=${subdomainToUse}`
        } else {
          fullUploadUrl = this.directUploadUrl
        }
    
        const directUpload = new DirectUpload(imageFile, fullUploadUrl);
        let response = await this.promisify(directUpload)
        signedId = response.signed_id
        // console.log("signed id for base64: ", signedId)
        dropZoneImage.attr('src', imageData);
  
      } else {
        const file = fileContainer.get(0).files[0]
        if(file) {
          console.log("choosing file.....", file)
          var reader = new FileReader()
          reader.onload = function(event) {
            // console.log("target parent: ", $targetToSend.parent())
            // console.log("drop zone image: ", dropZoneImage)
            if (dropZoneImage.length > 0) {
              // console.log("transfer image src")
              dropZoneImage.attr('src', event.target.result);
            } else {
              // console.log("get file info")
              const uploadLink = $targetToSend.closest("a")
              const fileInfo = $uploadContainer.find('.upload-preview .file-info').clone()
              uploadLink.find('.file-info').remove()
              uploadLink.find('i').remove()
              fileInfo.appendTo(uploadLink)
            }
      
          };
        
          reader.readAsDataURL(file);  

          const upload = new DirectUpload(
            file,
            this.directUploadUrl,
          )
          let response = await this.promisify(upload)
          signedId = response.signed_id
        }
      }

    }

    // console.log("si before send", signedId)
    $targetToSend.val(signedId)
    /// if auto_send is set, find closest form and submit
    if($targetToSend.data("auto-send")) {
      const $form = $targetToSend.closest('form')
      // console.log("form: ", $form)
      $form.submit()
      // Reload the page after 500ms
      setTimeout(function() {
        location.reload();
      }, 800);
      // $targetToSend.get(0).dispatchEvent(new Event('change'))
    } 

    $parent.modal('hide')
    this.resetModal($parent)
    this.stopLoading()
  }

  ///
  // marks given media as selected
  ///
  selectMedia(event) {
    let $target = $(event.currentTarget),
    $container = $target.closest('.library-container')
    $container.find('.selected').removeClass('selected')
    $target.addClass('selected')
  }

  ///
  // reads uploaded file from file input and renders preview accordingly in the dropzone (no file upload)
  ///
  async renderFilePreviewOnUpload(event) {
    const self = this
    const target = event.currentTarget,
    $target = $(target)
    let files = null
    // console.log("target: ", $target)
    if(target.files && target.files[0]) {
      // normal click on element
      files = target.files
    } else {
      // something was dropped in here
      files = event.dataTransfer.files
    }

    // console.log("files: ", files)

    if (files && files[0]) {
      var reader = new FileReader()
      let file = files[0],
      $modal = $target.closest('.modal'),
      $uploadPreview = $modal.find('.upload-preview')

      reader.onload = async function (e) {
        let next = $uploadPreview.children().first()
        if (file.type.startsWith("video")) {
          // create video tag
          if ($(next).is('video')) {
            $(next).attr("src", e.target.result)
          } else {
            $(next).remove()
            let imageElement = document.createElement("video")
            $(imageElement).attr({
              src: e.target.result,
              autoplay: "autoplay",
              muted: "muted",
              loop: "loop",
            })
            $(imageElement).appendTo($uploadPreview)
          }
        } else if(file.type.startsWith('image')) {
          // create image tag
          if ($(next).is("img")) {
            $(next).attr("src", e.target.result)
          } else {
            $(next).remove()
            let imageElement = document.createElement("img")
            $(imageElement).attr("src", e.target.result)
            $(imageElement).appendTo($uploadPreview)
          }
        } else {
          // for displaying whatever file type this is.
          let fileParts = file.name.split('.'),
          filename = fileParts[0],
          extension = fileParts[fileParts.length - 1]

          $(next).remove()
          let asset = await new Ajax(self.assetsUrl).doAjax("GET", { extension: extension, filename: filename })
          $(asset).appendTo($uploadPreview)
        }
        $modal.find('.no-upload-yet').addClass('hidden')
        const cropper = $modal.find('.preview-cropper')
        if(cropper.length > 0) { // we have an image
          cropper.get(0).dispatchEvent(new Event('previewRendered'))
        }
      }

      reader.readAsDataURL(files[0])
    }
  }

  /// !!! might be erased (original name is directUpload)
  async directUpload(event) {
    console.log("direct Upload")
    let $target = $(event.currentTarget),
    signedId = $target.val()
    let next = $target.next()

    const imageSize = null; //$target.data('image-size') // <-- we do not resize uploaded image, so we can crop from original
    const allowCrop = $target.data('allow-crop')
    let asset = await new Ajax(this.assetsUrl).doAjax("GET", { signed_id: signedId, image_size: null, allow_crop: allowCrop })
    $(next).remove()
    $(asset).insertAfter($target)
  }

  ///
  // uploads file via DirectUpload (currently used in icon_upload input(IcoFileInput))
  ///
  async fileUpload(event) {
    const self = this
    const target = event.currentTarget,
    $target = $(target)
    let files = null
    // console.log("target: ", $target)
    if(target.files && target.files[0]) {
      // normal click on element
      files = target.files
    } else {
      // something was dropped in here
      files = event.dataTransfer.files
    }

    // console.log("files: ", files)

    if (files && files[0]) {
      var reader = new FileReader()
      let file = files[0],
      $modal = $target.closest('.modal'),
      $uploadPreview = $modal.find('.upload-preview')

      // console.log("target data: ", $target.data())
      if($target.data('direct-upload')) {
        let $targetToSend = $($target.data('direct-upload-target'))
        if(file) {
          // console.log("uploading file.....", file)
          const upload = new DirectUpload(
            file,
            '/rails/active_storage/direct_uploads',
          )
          let response = await this.promisify(upload)
          let signedId = response.signed_id
          if($targetToSend) {
            // console.log(`signed id: ${signedId}`)
            $targetToSend.val(signedId)
            $targetToSend.get(0).dispatchEvent(new Event('change'))
          }
        }
      }

      reader.onload = async function (e) {
        let next = $uploadPreview.children().first()
        if (file.type.startsWith("video")) {
          // create video tag
          if ($(next).is('video')) {
            $(next).attr("src", e.target.result)
          } else {
            $(next).remove()
            let imageElement = document.createElement("video")
            $(imageElement).attr({
              src: e.target.result,
              autoplay: "autoplay",
              muted: "muted",
              loop: "loop",
            })
            $(imageElement).appendTo($uploadPreview)
          }
        } else if(file.type.startsWith('image')) {
          // create image tag
          if ($(next).is("img")) {
            $(next).attr("src", e.target.result)
          } else {
            $(next).remove()
            let imageElement = document.createElement("img")
            $(imageElement).attr("src", e.target.result)
            $(imageElement).appendTo($uploadPreview)
          }
        } else {
          // for displaying whatever file type this is.
          let fileParts = file.name.split('.'),
          filename = fileParts[0],
          extension = fileParts[fileParts.length - 1]

          $(next).remove()
          let asset = await new Ajax(self.assetsUrl).doAjax("GET", { extension: extension, filename: filename })
          $(asset).appendTo($uploadPreview)
        }
        $modal.find('.no-upload-yet').addClass('hidden')
      }

      reader.readAsDataURL(files[0])
    }
  }


  resetModal($modal) {
    $modal.find('.no-upload-yet').removeClass('hidden')
    $modal.find('.upload-preview').html('')
    $modal.find('.library-container .img-thumbnail.selected').removeClass('selected')
  }

  promisify(upload) {
    return new Promise(
      (resolve, reject) => {
        // console.log("Upload: ", upload)
        upload.create((error, blob) => {
          if(error) {
            console.log(error)
            reject(error)
          }
          else {
            resolve(blob)
          }
        })
      }
    )
  }


  ///
  // takes a base64 encoded image and transforms it into a File object
  ///
  base64DatatoFile(base64Data, filename) {
    var arr = base64Data.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  ///
  // deletes the given blob
  ///
  deleteAsset(event) {
    event.preventDefault();
    $('[data-bs-toggle="tooltip"]').tooltip("hide")
    let $target = $(event.currentTarget)
    let blob = $target.closest('div.blob')
    this.stimulate('MediaLibraryReflex#delete_asset', event.currentTarget).then(() => {
      blob.detach()
    });

  }

  ///
  // deletes the given blob
  ///
  deleteVariant(event) {
    event.preventDefault();
    $('[data-bs-toggle="tooltip"]').tooltip("hide")
    let $target = $(event.currentTarget)
    let blob = $target.closest('div.blob')
    this.stimulate('MediaLibraryReflex#delete_variant', event.currentTarget).then(() => {
      blob.detach()
    });
  }

  ///
  // deletes the given blob
  ///
  deleteAttachment(event) {
    event.preventDefault();
    $('[data-bs-toggle="tooltip"]').tooltip("hide")
    let $target = $(event.currentTarget)
    let blob = $target.closest('div.blob')
    this.stimulate('MediaLibraryReflex#delete_attachment', event.currentTarget).then(() => {
      blob.detach()
    });
  }

  ///
  // deletes the given media archive entry
  ///
  deleteMediaArchiveEntry(event) {
    event.preventDefault();
    $('[data-bs-toggle="tooltip"]').tooltip("hide")
    let $target = $(event.currentTarget)
    let blob = $target.closest('div.blob')
    this.stimulate('MediaLibraryReflex#delete_media_archive_entry', event.currentTarget).then(() => {
      blob.detach()
    });
  }

}