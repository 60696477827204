import ApplicationController from '../application_controller'
import VALIDATION_STATES from './validation_states'

export default class extends ApplicationController {
  static targets = ["field"]

  initialize() {
    super.initialize()

    if(this.hasFieldTarget) {
      this.numberOfFields = this.fieldTargets.length
      this.validationStates = []
      this.id = $(this.element).attr('id').replace('_form', '')
      this.validationState = VALIDATION_STATES.invalid
      $(document).on('validations.field-validation', '[data-validations--group-validation-target="field"]', this.watchValidations.bind(this))
    }
  }

  watchValidations(event) {
    this.logInfo(`received validation: ${event.detail.validationState}`)
    const target = $(event.currentTarget)
    this.checkValidationState(target, event.detail.validationState)
  }

  checkValidationState(target, state) {
    const index = $(this.fieldTargets).index(target)
    this.validationStates[index] = state

    const currentValidationStates = this.validationStates.filter(e => String(e).trim())

    let groupValid = currentValidationStates.every((state) => state === VALIDATION_STATES.valid)

    groupValid &&= currentValidationStates.length === this.numberOfFields

    const newValidationState = groupValid ? VALIDATION_STATES.valid : VALIDATION_STATES.invalid

    if(newValidationState !== this.validationState) {
      this.validationState = newValidationState
      this.dispatchValidationState()
    }
  }

  dispatchValidationState() {
    this.logInfo(`dispatching new state ${this.validationState}`)
    const eventName = 'validations.group-validation'
    const stateData =  {
      id: this.id,
      validationState: this.validationState
    }
    const validationStateEvent = new CustomEvent(eventName, {
      detail: stateData
    })

    this.element.dispatchEvent(validationStateEvent)
    $(this.element).trigger({ type: eventName, detail:  stateData})
  }
}