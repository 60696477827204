import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ['field']

  onSelect(event) {
    let value = $(event.currentTarget).data('value');
    $(this.fieldTarget).val(value)
    $(this.element).get(0).submit();
  }
}