addFormInitializationRoutine(function() {
  return $("input.association_autocomplete").each(function(i, el) {
    var $el, name
    $el = $(el)
    if (!$el.data("initializedTokenInput")) {
      $el.data("initializedTokenInput", true)
      $el.tokenInput($el.attr("data-url"), {
        prePopulate: JSON.parse($el.val() || '{}'),
        tokenLimit: (!$el.attr("data-multiple") ? 1 : void 0),
        tokenDelimiter: ",",
        preventDuplicates: true,
        tokenValue: "id",
        allowTabOut: true,
        autoSelectFirstResult: true,
        minChars: 2,
        theme: "facebook",
        animateDropdown: false
      })
      if ($el.attr("data-multiple")) {
        name = $el.attr("name") + "[]"
        return $el.attr("name", "__dummy__").change(function() {
          var id, j, len, ref, results
          $el.siblings("[data-helper='true']").remove()
          ref = $el.val().split(",")
          results = []
          for (j = 0, len = ref.length; j < len; j++) {
            id = ref[j]
            results.push($("<input>").attr("type", "hidden").attr("name", name).val(id).attr("data-helper", "true").insertAfter($el))
          }
          return results
        })
      }
    }
  })
})