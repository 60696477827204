import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["text", "hiddenField"]

  initialize() {
    super.initialize()
    this.useReflex = false
  }

  updateHiddenField(event) {
    // event.preventDefault()
    const $target = $(event.currentTarget)
    const $parent = $target.parent('.btn-group')
    const $checkedInput = $target
    const optionIndex = $checkedInput.val()
    const options = {left: 'white', right: 'black'}
    const currentMode = options[optionIndex]
    if(this.hasHiddenFieldTarget) {
      const prevMode = $(this.hiddenFieldTarget).val()
      $(this.hiddenFieldTarget).val(currentMode)
      this.updateTextColor(prevMode, currentMode)
    }
  }

  updateTextColor(prevMode, currentMode) {
    if (this.hasTextTarget) {
      const $input = $(this.textTarget).find('input')
      // this.logInfo(`switching from ${prevMode} to ${currentMode}`)
      $input.removeClass(`text-${prevMode}`)
      $input.addClass(`text-${currentMode}`)
    }
  }

  toggleVisibility(event) {
    event.preventDefault()
    const $target = $(event.currentTarget)
    if(this.hasTextTarget) {
      $(this.textTarget).find('.title').toggleClass('blend-off')
    }

  }

  toggleOverlay(event) {
    event.preventDefault()
    console.log("toggle Overlay")
    const $target = $(event.currentTarget)
    $target.closest(".hero-image").find('.hero-overlay').toggleClass("inactive")
  }
}