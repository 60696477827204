import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {


  initialize() {
    super.initialize()
  }

  connect() {
    super.connect();
    // console.log("eventTriggered: ", this.triggeredValue)
    const self = this
    const $target = $(this.element)
    // console.log("skill_rating_modal connected to: ", $target)
    const needsClosingAction = $target.data("needs-closing-action")
    // console.log("needsClosingAction: ", needsClosingAction)
    const $skillRatingModal = $("#skill_rating_modal")
    $skillRatingModal.modal("show")
    if(needsClosingAction) {
      $skillRatingModal.on('hidden.bs.modal', function (event) {
        // console.log("I'm hidden!")
        self.stimulate("SkillReflex#compute_average_rating").then(payload => {
          console.log("skillReflex stimulated to compute average rating")
        });
  
        $skillRatingModal.off('hidden.bs.modal')
      });
    }

  }



}
