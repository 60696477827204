import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["autohide"]


  connect() {
    super.connect()
  }
    
  ///
  // setup functionality for autohiding elements
  ///
  autohideTargetConnected(element) { 
    // console.log("autohide connected")
    setTimeout(() => {
      element.style.display = 'none';
    }, 2000);
  }
}
