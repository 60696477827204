import { Controller } from "@hotwired/stimulus";

import { split } from "../packs/global_functions";

import autocomplete from "autocompleter";

export default class extends Controller {
  static targets = ["output", "input"];

  connect() {
    let input = $(this.inputTarget).next("input")[0];
    let supportedTags = split($(input).data("supportedtags")).map(function (
      value
    ) {
      return { label: value, value: value };
    });

    autocomplete({
      input: input,
      fetch: function (text, update) {
        text = text.toLowerCase();
        var suggestions = supportedTags.filter((n) =>
          n.label.toLowerCase().startsWith(text)
        );
        update(suggestions);
      },
      onSelect: function (item) {
        input.value = item.label;
      },
    });
  }

  addTag(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      let target = $(event.currentTarget);
      let title = $.trim(target.val());
      if (title != "") {
        this.updateInputField(title, true);
          let tag = this.createTag(title);
          $(tag).appendTo(this.outputTarget);
        // let supportedTags = split($(target).data("supportedtags"));
        // if (
        //   $.inArray(title, this.currentTags()) === -1 &&
        //   (supportedTags.length === 0 || $.inArray(title, supportedTags) !== -1)
        // ) {

        // }
        target.val("");
      }
    }
  }

  deleteTag(event) {
    let target = $(event.currentTarget);
    let title = $.trim(target.html());
    target.remove();

    this.updateInputField(title, false);
  }

  createTag(title) {
    let tag = document.createElement("div");
    $(tag).addClass("tag");
    $(tag).attr("data-action", "click->tag#deleteTag");
    $(tag).html(title);

    return tag;
  }

  updateInputField(tag, push) {
    let newTags = this.currentTags();
    if (push) {
      newTags.push(tag);
    } else {
      newTags = newTags.filter(function (v) {
        return v !== tag;
      });
    }
    $(this.inputTarget).val(newTags.join(",")).trigger("change");
  }

  currentTags() {
    let tags = $(this.inputTarget).val();
    return split(tags);
  }
}
