import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['collapsed']

  toggle(event) {
    const domTarget = $(event.currentTarget)
    const collapseTarget = $(`#${domTarget.data('bs-target')}`)
    if(this.isOpen(collapseTarget)) {
      domTarget.find('.material-icons').html('expand_more')
      this.closeGroup(collapseTarget)
    } else {
      domTarget.find('.material-icons').html('expand_less')
      const id = domTarget.data('id')
      this.openGroup(collapseTarget, id)
    }
  }

  isOpen(target) {
    return target.hasClass('show')
  }

  openGroup(target, id) {
    this.stimulate('JourneyVersions#versions', id).then(() => target.collapse('show'))
  }

  closeGroup(target) {
    target.collapse('hide')
  }

  deleteActiveJourney(event) {
    event.preventDefault()
    const target = $(event.currentTarget)
    const versionId = target.data('id')

    this.stimulate('JourneyVersions#delete_active_journey', versionId)
  }
}