import PlacesAutocomplete from 'stimulus-places-autocomplete'

export default class extends PlacesAutocomplete {

  static targets = ['map', 'placeId']

  static mapApi
  static marker

  initAutocomplete() {
    if(this.hasMapTarget) {
      this.renderMap(this.mapTarget)
    }
    super.initAutocomplete()
  }

  placeChanged() {
    super.placeChanged()
    if(typeof(this.place.place_id) != "undefined") {
      if(this.hasPlaceIdTarget) {
        $(this.placeIdTarget).val(this.place.place_id)
        this.renderPlace()
      }
    } else if(typeof(this.place.name) != "undefined") {
      //maybe we can try to get a place
      this.findPlacesFromName(this.place.name)
    }
  }

  preventSubmit(event) {
    if(event.key == "Enter") {
      event.preventDefault()
    }
    super.preventSubmit(event)
  }

  // You can set the Autocomplete options in this getter.
  get autocompleteOptions() {
    return {
      fields: ['place_id'],
    }
  }

  findPlacesFromName(name) {
    let placesServiceApi = new google.maps.places.PlacesService(this.mapApi)

    var request = {
      query: name,
      fields: ['place_id']
    }

    placesServiceApi.findPlaceFromQuery(request,(results, status) => {
      if(status == google.maps.places.PlacesServiceStatus.OK) {
        if(results.length > 0) {
          $(this.placeIdTarget).val(results[0].place_id)
          this.renderPlace()
        }
      }
    })
  }

  renderMap(el) {
    let $el = $(el)
    this.mapApi = new google.maps.Map(el, mapsHelper.defaultConfiguration)
    this.marker = new google.maps.Marker({
      map: this.mapApi,
      title: $el.attr("data-label")
    })
    this.renderPlace()
  }

  renderPlace() {
    let self = this
    if(this.hasPlaceIdTarget) {
      if ($(this.placeIdTarget).val()) {
        let placesServiceApi = new google.maps.places.PlacesService(this.mapApi)
        return placesServiceApi.getDetails({
          placeId: $(this.placeIdTarget).val()
        }, function(place, status) {
          if (!(status === google.maps.places.PlacesServiceStatus.OK && place.geometry)) {
            return
          }
          mapsHelper.updateMapWithPlace(self.mapApi, place, self.marker)
        })
      }
    }
  }
}