$(function() {
  var $index, $modal, $modalContent, id, showModal
  $index = $('#product-events-index')
  $modal = $('#product-events-modal')
  $modalContent = $modal.find('.modal-body-outer')
  showModal = function(html) {
    $modalContent.html(html)
    $modal.modal()
    return reinitializeForms($modalContent)
  }
  $('.nested-association-new-link').on('ajax:success', function(event, data, status, xhr) {
    return showModal(data)
  })
  $modal.on('ajax:success', '.nested-association-form', function(event, data, status, xhr) {
    $index.html(data)
    return $modal.modal('hide')
  })
  $modal.on('ajax:error', '.nested-association-form', function(event, xhr, status, error) {
    return showModal(xhr.responseText)
  })
  $index.on('ajax:success', '.nested-association-show-link', function(event, data, status, xhr) {
    return showModal(data)
  })
  $index.on('ajax:success', '.nested-association-edit-link', function(event, data, status, xhr) {
    return showModal(data)
  })
  $index.on('ajax:success', '[data-method="delete"]', function(event, data, status, xhr) {
    return $index.html(data)
  })
  if (location.hash.match("^#event_id=")) {
    id = location.hash.substr("#event_id=".length, location.hash.length)
    $index.find("tr[data-id='" + id + "']").find(".nested-association-show-link").click()
    location.hash = ""
  }
  $("#consumables").on("click", ".consumption-link", function(event) {
    var action_type, url
    url = $(this).data("url")
    action_type = $(this).data("actiontype")
    return $.post(url, {
      action_type: action_type
    })
  })
  return $("#share").on("click", "#portal-share", function(event) {
    var $form, $url, email, message, name
    $form = $('#share-form')
    $url = $form.data('url')
    message = $form.find("textarea[name='message']").val()
    name = $form.find("input[name='name']").val()
    email = $form.find("input[name='email']").val()
    id = $form.find("[name='id']").val()
    return $.post($url, {
      id: id,
      message: message,
      email: email,
      name: name
    })
  })
})