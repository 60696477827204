import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {

  static targets = ["step", "question"]

  initialize() {
    super.initialize()
    this.currentStep = 0
    this.currentQuestion = 0
    if(this.hasStepTarget) {
      $(this.stepTargets).addClass('d-none')
      $(this.stepTargets[this.currentStep]).removeClass('d-none')
    }

    if(this.hasQuestionTarget) {
      $(this.questionTargets).addClass('d-none')
      $(this.questionTargets[this.currentQuestion]).removeClass('d-none')
    }
  }

  nextStep(event) {
    if(this.hasStepTarget) {
      if(this.stepTargets.length > this.currentStep + 1) {
        this.currentStep++
        const prevStepDom = this.stepTargets[this.currentStep - 1]
        const stepDom = this.stepTargets[this.currentStep]
        $(prevStepDom).addClass('animate__fadeOut')
        $(stepDom).removeClass('d-none').addClass('animate__fadeIn')
        setTimeout(() => $(prevStepDom).addClass('d-none'), 1000)
      }
    }
  }

  decideNextStep(event) {
    console.log(event)
    const questionComplete = event.detail.question_complete
    const nextQuestionBtn = $(this.questionTargets[this.currentQuestion]).find('.next-question')
    if(questionComplete) {
      if(this.#hasReachedQuestionEnd()) {
        $('#next_btn').removeClass('disabled')
      } else {
        nextQuestionBtn.removeClass('disabled')
      }
    } else {
      nextQuestionBtn.addClass('disabled')
      $('#next_btn').addClass('disabled')
    }
  }

  nextQuestion(event) {
    console.log(event)
    if(this.hasQuestionTarget) {
      this.currentQuestion++
      const current = $(this.questionTargets[this.currentQuestion])
      const previous = $(this.questionTargets[this.currentQuestion - 1])
      previous.addClass('animate__fadeOut')
      current.removeClass('d-none').addClass('animate__fadeIn')
      setTimeout(() => previous.addClass('d-none'), 1000)
    }
  }

  #hasReachedQuestionEnd() {
    if(!this.hasQuestionTarget) {
      return true
    }

    return this.currentQuestion >= this.questionTargets.length - 1
  }
}