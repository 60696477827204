import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {

  static targets = ["slideForm", "sortable", "editSlide"]

  connect() {
    super.connect()
    const self = this
    document.documentElement.addEventListener('turbo:before-stream-render', (event) => {
      self.#checkStream(event, self)
    })

    if(this.hasSortableTarget) {
      this.sortable = Sortable.create(this.sortableTarget, {
        animation: 150,
        onEnd: this.sortEnd.bind(this)
      })
    }
  }

  /**
   * this will sync the image from the dropzone form into the active preview image.
   * @param {*} event the Image File from the dropzone custom event. @see dropzone_controller
   */
  setPreview(event) {
    const preview = $(this.sortableTarget).find('.slide-active .slide-page-content')
    const dataUrl = event.detail.file.dataURL
    preview.find('img').addClass('hidden')
    const img = preview.find('img.test-preview')
    if(img.length == 0) {
      const img = document.createElement("img")
      img.src = dataUrl
      $(img).addClass('test-preview')
      $(preview).append(img)
    }
    $(img).removeClass('hidden')
    $(img).attr('src', dataUrl)
  }

  /**
   * This will hide the preview set from dropzone event.
   * @param {*} event the Image File Event from the dropzone custom event. @see dropzone_controller
   */
  resetPreview(event) {
    const preview = $(this.sortableTarget).find('.slide-active .slide-page-content')
    preview.find('img:not(.test-preview)').removeClass('hidden')
    preview.find('.test-preview').addClass('hidden')
  }

  /**
   * when sortable js is finished with sorting, this will be called.
   * It will set the order input field for every slide page.
   * @param {*} event 
   */
  sortEnd(event) {
    if(this.hasSortableTarget){
      $(this.sortableTarget).find('.slide-page').each((index, page) => {
        const target = $(page).data('target')
        const position = index + 1
        this.logInfo(`${position} ${target}`)
        $(target).find('.slide-order-field').val(position)
      })
    }
  }

  /**
   * show the selected form.
   * @param {*} event 
   */
  showForm(event) {
    const target = $(event.currentTarget).data('target')
    this.logInfo(target)
    this.#hideAllFormFields()
    $(this.element).find(target).removeClass('hidden')
    this.#toggleActiveForSlidePage(event.currentTarget)
  }

  /**
   * this will set the current selected page on the left as active.
   * @param {*} currentTarget 
   */
  #toggleActiveForSlidePage(currentTarget) {
    if(this.hasSortableTarget) {
      $(this.sortableTarget).find('.slide-page').removeClass('slide-active')
    }
    $(currentTarget).addClass('slide-active')
  }

  /**
   * we listen for a general turbo:before-stream-render, therefore we need to be sure it is the right one.
   * @param {*} event 
   * @param {SlideFormController} self 
   * @returns 
   */
  #checkStream(event, self) {
    // are we interested in the stream?
    const updateTarget = event.detail.newStream.attributes.target.nodeValue
    self.logInfo(updateTarget)
    if(updateTarget !== self.editSlideTarget.id) {
      return
    }
    self.#hideAllFormFields()
  }

  /**
   * This will hide all active form fields.
   */
  #hideAllFormFields() {
    if(this.hasSlideFormTarget) {
      $(this.sortableTarget).find('.slide-active').removeClass('slide-active')
      $(this.slideFormTargets).addClass('hidden')
    }
  }
}