import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  updateState(event) {
    $('.frame-data-card').each(function(index, element) {
      let stateElement = $(element).find('.card-state');
      let correspondingForm = $(`#form_element_${index}`).find('textarea');
      if(correspondingForm.val() != "") {
        stateElement.addClass('complete').removeClass('unknown');
      } else {
        stateElement.addClass('unknown').removeClass('complete');
      }
    });
  }
}