import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {


  initialize() {
    super.initialize()
    this.useReflex = false
  }

  connect() {
    super.connect();
  }

  ///
  // when the users role has been changed, a custom event role:changed is triggered
  ///
  roleChange(event) {
    const $target = $(event.currentTarget)
    const role = $target.val()
    $(document).trigger("role:changed", role)
  }

}
