import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['switch']
  static values = { init: String, input: String, steps: Number, labels: Array }

  connect() {
    var $switchElement = $(this.switchTarget);
    // console.log("switch: ", $switchElement)
    var switchValue = parseInt(this.initValue)
    // console.log("init value: ", switchValue)
    var switchSteps = parseInt(this.stepsValue)
    // console.log("switch steps: ", switchSteps)
    var switchLabels = this.labelsValue
    // console.log("switch labels: ", switchLabels)
    var inputFieldId = this.inputValue
    var $inputField = $("#" + inputFieldId)
    // console.log("input ID: ", inputFieldId)
    // console.log("input: ", $inputField)
    var states = ['', 'off', 'noset', 'on'];
    var action = states[switchValue];
    // console.log("action: ", action)

    // add the right label
    var $switchLabel = $(`<label for="${$switchElement.id}" class="me-2 d-inline">${switchLabels[switchValue - 1]}</label>`)
    $switchLabel.insertAfter($switchElement)

    // define the switch
    $switchElement.setTheSwitch({
      action: action,
      steps: switchSteps,
      bgOn: '#4da154',
      bgNoSet: '#f1f1f1',
      bgOff: '#d82c2c',
      width: 80,
      porcent: false,
      height: 20, 
      onLabel: '●',
      offLabel: '●',
      hsize: 16,
      disabled: false,
      onClickOn: function(e) {
        $inputField.val(3)
        $switchLabel.text(switchLabels[2])
      },
      onClickNoSet: function(e) {
        $inputField.val(2)
        $switchLabel.text(switchLabels[1])
      },
      onClickOff: function(e) {
        $inputField.val(1)
        $switchLabel.text(switchLabels[0])
      }
    });
  }

  switch(event) {
  }
}
