import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  initialize() {
    super.initialize()
    this.useReflex = false
  }

  connect() {
    this.logger.info(this.identifier, "connected")
    const debugEvents = $(this.element).data('debug-events').split(',')
    debugEvents.forEach((debugEvent) => {
      this.logInfo(`register for event: ${debugEvent}`)
      document.documentElement.addEventListener(debugEvent, (event) => {
        this.logInfo(`${debugEvent} -> ${$(event)}`)
      })
    })
  }
}