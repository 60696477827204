import ApplicationController from './application_controller'
import Param from '../packs/param';

export default class extends ApplicationController {

  static targets = ["label"];

  connect() {
    super.connect();
    this.param = new Param(this.getBaseUrl());
  }


  switch(event) {
    $(this.labelTargets).toggleClass("text-muted");
  }

  ///
  // sets the filtered param accordingly
  ///
  switchScope(event) {
    let $target = $(event.currentTarget)
    let isFiltered = $target.prop("checked");
    // console.log("isFiltered: ", isFiltered)

    this.param.updateParams("filtered", isFiltered, false);
    this.stimulate("searchReflex#filter");
  }


}
