import { Controller } from '@hotwired/stimulus'

export default class TagInputController extends Controller {
  textChange(event) {
    event.preventDefault();
    let char = event.data,
      $target = $(event.currentTarget),
      container = $target.next(".pills"),
      hiddenField = $("#journey_tag_list");
    if (char === " ") {
      let value = $.trim($target.val());
      $target.val("");

      if (value !== "") {
        let tag = document.createElement("div"),
          text = document.createElement("span"),
          close = document.createElement("span");

        $(tag).attr("data-action", "click->tag-input#deleteTag");

        $(text).text(value);
        $(text).addClass("tag-label");
        $(close).text("╳");

        $(tag).addClass("badge badge-pills badge-primary badge-clickable");
        $(text).appendTo(tag);
        $(close).appendTo(tag);

        $(tag).appendTo(container);

        let array = cleanArray(hiddenField.val().split(","));
        array.push(value);

        hiddenField.val(array.join(","));
      }
    }
  }

  deleteTag(event) {
    event.preventDefault();
    let $target = $(event.currentTarget),
      text = $target.children(".tag-label").text(),
      hiddenField = $("#journey_tag_list");

    let array = cleanArray(hiddenField.val().split(","));

    let filtered = array.filter(function (el) {
      return el !== text;
    });

    hiddenField.val(filtered.join(","));
    $target.remove();
  }
}

function cleanArray(array) {
  var filtered = array.filter(function (el) {
    return el !== null && el !== "";
  })
  return filtered
}