import ApplicationController from './application_controller'

export default class extends ApplicationController {


  initialize() {
    super.initialize()
    this.useReflex = true
  }

  ///
  // saves the journey, but creates hidden field for edit_path before
  // save is triggered by clicking sav&exit button
  ///
  saveJourney(event) {
    event.preventDefault()
    this.startLoading()
    this.reset_csrf_token()
    let form = $('#journey_form')
    let action = form.attr('action')
    if (!action.endsWith(".js")) {
      action += ".js"
    }
    $('#journey_form').attr('action', action)


    let hiddenField = document.createElement('input')
    $(hiddenField).attr({
      type: 'hidden',
      value: $(event.currentTarget).data('path'),
      name: 'journey[edit_path]'
    });

    $(hiddenField).appendTo(form)

    $("[type=submit][form=journey_form][name=save_and_exit]").trigger("click")
  }


  ///
  // removes the phase by calling a reflex
  ///
  removePhase(event) {
    event.preventDefault();
    const $target = $(event.currentTarget);
    const phaseId = $target.data("id")
    console.log(`start deleting the phase with id: ${phaseId}`)
    const phaseBox = $(`#phase_box_${phaseId}`)
    const phaseContainer = phaseBox.next()
    phaseBox.remove()
    phaseContainer.remove()
    this.stimulate('PhaseBuilderReflex#remove_phase', phaseId)
  }

  preview(event) {
    const target = $(event.currentTarget)
    event.preventDefault()
    this.stimulate('Journeys#preview', $('#journey_form')[0]).then((event) => {
      // console.log(event)
      window.open(target.attr('href'), target.attr('target'), 'height=1024,width=1280,toolbar=no,menubar=no,scrollbars=no,location=no,status=no')
    })
  }

  setupStructure(event) {
    // const $target = $(event.currentTarget)
    event.preventDefault()
    // console.log('setting up the learning path\'s structure')
    this.stimulate('Journeys#show_structure_modal', event.currentTarget).then((event) => {
      $("#journey_structure_modal").modal('show')
    });

  }

  requestStructure(event) {
    const $target = $(event.currentTarget)
    event.preventDefault()
    const journeyId = $target.closest(".modal").data("journey-id")
    const theme = $('#theme_input').val()
    const targetGroup = $('#target_group_input').val()
    const language = $('#language_input').val()
    // console.log("theme:", theme)
    // console.log("targetGroup:", targetGroup)
    // console.log("language:", language)
    this.stimulate('Journeys#request_structure', journeyId, theme, targetGroup, language).then((event) => {
      // $("#journey_structure_modal .modal-footer").text(`Wir suchen nach '${$input.val()}'`)
    });
  }

  updateStateManagement(event) {
    const journeyId = $(this.element).data('journey-id')
    const stateOn = $(event.currentTarget).prop('checked')
    this.stimulate('Journeys#update_state_management', stateOn)
  }

  showTranslationModal(event) {
    this.stimulate('Journeys#show_translation_modal')
  }

}