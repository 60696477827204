import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {

  confirmUsageOfProposal(event) {
    // const $target = $(event.currentTarget)
    event.preventDefault()
    const $modal = $("#journey_structure_modal")
    $modal.modal('hide')
    this.stimulate('Journeys#confirm_usage_of_proposal', event.currentTarget).then((event) => {
      // $("#journey_structure_modal .modal-footer").text(`Wir suchen nach '${$input.val()}'`)
    });

  }

  adaptProposal(event) {
    // const $target = $(event.currentTarget)
    event.preventDefault()
    let $modal = $("#journey_structure_modal")
    $modal.modal('hide')
    this.stimulate('Journeys#show_structure_modal', event.currentTarget).then((event) => {
      $modal = $("#journey_structure_modal")
      $modal.modal('show')  
    });

  }

}
