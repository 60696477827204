import ApplicationController from '../../../javascript/controllers/application_controller'
import Chart from 'chart.js/auto';

export default class extends ApplicationController {

  static values = {
    colorTheme: String,
    circular: Boolean,
    showLegend: Boolean
  }

  connect() {
    super.connect();
    this.setupChart();
  }

  setupChart() {

    let colorGrid = "#00003C1A";
    let colorLabel = "#00003C";

    console.log("color theme: ", this.colorThemeValue)

    if (this.colorThemeValue === "dark") {
      colorGrid = "#FFFFFF66";
      colorLabel = "#FFFFFF";
    }

    const chartId = $(this.element).data('chart-id')
    const data = {
      labels: $(this.element).data('labels'),
      datasets: $(this.element).data('datasets')
    }
    // console.log("data: ", data);
    const config = {
      type: 'radar',
      data: data,
      options: {
        plugins: {
          legend: {
            display: this.showLegendValue,
            labels: {
              color: colorLabel
            }
          },
        },
        elements: {
          line: {
            borderWidth: 3
          },
        },
        scales: {
          r: {
            suggestedMin: -1,
            suggestedMax: 4,
            pointLabels: {
              color: colorLabel
            },
            ticks: {
              display: false,
              stepSize: 1.0
            },
            angleLines: {
              color: colorGrid,
            },
            grid: {
              circular: this.circularValue,
              color: colorGrid,
            }
          }
        }
      },
    };

    const myChart = new Chart(
      document.getElementById(chartId),
      config
    );
  }

  // check(event) {
  //   event.preventDefault();

  //   const $target = $(event.currentTarget);
  //   const $input = $target.find("input")
  //   console.log(`check `, $input.attr("id"));
  // }

}
