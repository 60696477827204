import { Color } from '@svgdotjs/svg.js';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["value", "label"];

  change(event) {
    let $target = $(event.currentTarget),
    color = $target.val();

    $(this.valueTarget).css({
      "background-color": color,
    });

    $(this.labelTarget).html(color);
  }
}