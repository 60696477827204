
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    Chartkick.configure({language: I18n.locale})
    console.log(`we go with locale ${I18n.locale} for Chartkick`)
  }

  changed(event) {
    event.stopPropagation();
    event.preventDefault();
    let $target = $(event.target);
    console.log(`target: `, $target)
    let currentSelection = $target.attr('id');
    console.log("current selection: ", currentSelection);
    var yearSelection, currentQuarterSelection, monthSelection, quarters;
    yearSelection = $('#year_select').val()
    currentQuarterSelection = $('#quarter_select').val();
    monthSelection = $('#month_select').val();

    let xLabel;
    switch(currentSelection) {
      case "quarter_select":
        xLabel = I18n.t("dict.week");
        break;
      case "month_select":
        xLabel = "";
        break;
    
      default:
        xLabel = I18n.t("dict.week");
        break;
    }


    if (currentSelection === 'quarter_select') {
      monthSelection = "-1";
      $('#month_select').val(monthSelection);      // $('#month_select').trigger();
    } else {
      if (currentSelection === 'month_select') {
        if (currentQuarterSelection !== '-1') {
          quarters = {
            1: '1',
            2: '1',
            3: '1',
            4: '2',
            5: '2',
            6: '2',
            7: '3',
            8: '3',
            9: '3',
            10: '4',
            11: '4',
            12: '4'
          };
          currentQuarterSelection = quarters[monthSelection];
          $('#quarter_select').val(currentQuarterSelection);
        }
      }
    }
    
    // $('#filter_form').submit();
    let dataSource, split, path, params;
    const queryString = require('query-string');
    Chartkick.eachChart( function(chart) {
      console.log("working on chart: ", chart );
      console.log("options: ", chart.options );
      dataSource = chart.dataSource;
      // console.log("dataSource is: ", dataSource);
      split = dataSource.split("?", 2);
      path = split[0];
      params = queryString.parse(split[1]);
      // console.log("parsed: ", params);
      params.year = yearSelection;
      params.month = monthSelection;
      params.quarter = currentQuarterSelection;
      console.log(`changed params on chart '${$(chart.element).attr("id")}': ${queryString.stringify(params)}`);
      chart.dataSource = `${path}?${queryString.stringify(params)}`;
      let options = chart.getOptions();
      chart.setOptions({...options, ...{"xtitle": xLabel}});
      chart.refreshData();
    })

  }

}
