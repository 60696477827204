import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['map']

  initMap() {
    self = this
    if(this.hasMapTarget) {
      $(this.mapTargets).each((_, el) => {
        self.renderMap(el)
      })
    }
  }

  mapTargetConnected(el) {
    if(typeof(google) != "undefined") {
      this.renderMap(el)
    }
  }

  renderMap(el) {
    var $el, infoWindow, mapApi, marker, placesServiceApi
    $el = $(el)
    if ($el.data("initializedMaps")) {
      return
    }
    $el.data("initializedMaps", true)
    mapApi = new google.maps.Map(el, mapsHelper.defaultConfiguration)
    placesServiceApi = new google.maps.places.PlacesService(mapApi)
    marker = new google.maps.Marker({
      map: mapApi,
      title: $el.attr("data-label")
    })
    infoWindow = new google.maps.InfoWindow({
      content: $el.attr("data-label")
    })
    marker.addListener('click', function() {
      return infoWindow.open(mapApi, marker)
    })
    if ($el.attr("data-place-id")) {
      return placesServiceApi.getDetails({
        placeId: $el.attr("data-place-id")
      }, function(place, status) {
        if (!(status === google.maps.places.PlacesServiceStatus.OK && place.geometry)) {
          return
        }
        mapsHelper.updateMapWithPlace(mapApi, place, marker)
        return infoWindow.open(mapApi, marker)
      })
    }
  }
}