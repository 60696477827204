import ApplicationController from '../../../javascript/controllers/application_controller';

export default class extends ApplicationController {

  static targets = ["continueBtn", "primaryHiddenField", "secondaryHiddenField"]

  initialize() {
    super.initialize()
    this.open()

    this.primarySlot = null
    this.secondarySlot = null
  }

  open(event) {
    $(this.element).modal('show')
  }

  goToLearnTypePage(event) {
    event.preventDefault()

    const $introPage = $(this.element).find('#intro_page')
    const $chooseLearnTypePage = $(this.element).find('#choose_learn_type_page')

    this.#initLearnTypePage()

    $introPage.addClass('hidden')
    $chooseLearnTypePage.removeClass('hidden')
  }

  #initLearnTypePage() {
    this.primarySlot = null
    this.secondarySlot = null
    $(this.element).find('#choose_learn_type_page .big-btn-group .big-btn.selected').removeClass('selected')
    this.#enableContinueBtn()
  }

  chooseLearnType(event) {
    event.preventDefault()
    const $target = $(event.currentTarget)
    const $parent = $(event.currentTarget).parent('.big-btn-group')
    const $activeElements = $parent.find('.big-btn.selected')
    const learnType = $target.data('learn-type')

    if($target.hasClass('selected')) {
      $target.removeClass('selected')
      this.#removeFromSlot(learnType, learnType)
    } else {
      if($activeElements.length < 2) {
        $target.addClass('selected')
        this.#fillFittingSlot(learnType)
        this.#displayFittingSlotLabel($target, learnType)
      }
    }

    this.logInfo(`primary slot -> ${this.primarySlot}`)
    this.logInfo(`secondary slot -> ${this.secondarySlot}`)

    this.#enableContinueBtn()
  }

  backToIntro(event){
    event.preventDefault()

    const $introPage = $(this.element).find('#intro_page')
    const $chooseLearnTypePage = $(this.element).find('#choose_learn_type_page')

    $introPage.removeClass('hidden')
    $chooseLearnTypePage.addClass('hidden')
  }

  close(event) {
    event.preventDefault()
    $(this.element).modal('hide')
  }

  #fillFittingSlot(learnType) {
    if(this.primarySlot == null) {
      this.primarySlot = learnType
      this.#setPrimaryHiddenField(learnType)
    } else if(this.secondarySlot == null) {
      this.secondarySlot = learnType
      this.#setSecondaryHiddenField(learnType)
    }
  }

  #removeFromSlot(learnType) {
    if(this.primarySlot === learnType) {
      this.primarySlot = null
      this.#setPrimaryHiddenField('')
    }
    if(this.secondarySlot === learnType) {
      this.secondarySlot = null
      this.#setSecondaryHiddenField('')
    }
  }

  #setPrimaryHiddenField(value) {
    if(this.hasPrimaryHiddenFieldTarget) {
      $(this.primaryHiddenFieldTarget).val(value)
    }
  }

  #setSecondaryHiddenField(value) {
    if(this.hasSecondaryHiddenFieldTarget) {
      $(this.secondaryHiddenFieldTarget).val(value)
    }
  }

  #displayFittingSlotLabel($target, learnType) {
    if(this.primarySlot == learnType) {
      $target.find('.primary-slot').removeClass('hidden')
      $target.find('.secondary-slot').addClass('hidden')
      return
    }

    if(this.secondarySlot == learnType) {
      $target.find('.primary-slot').addClass('hidden')
      $target.find('.secondary-slot').removeClass('hidden')
      return
    }

    $target.find('.primary-slot').addClass('hidden')
    $target.find('.secondary-slot').addClass('hidden')
  }

  #enableContinueBtn() {
    if(this.hasContinueBtnTarget) {
      if(this.primarySlot != null && this.secondarySlot != null) {
        $(this.continueBtnTarget).removeClass('disabled')
      } else {
        $(this.continueBtnTarget).addClass('disabled')
      }
    }
  }
}