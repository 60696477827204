import FieldValidationController from './field_validation_controller'
import VALIDATION_STATES from './validation_states'

export default class extends FieldValidationController {
  checkValidation(target) {
    const isChecked = target.prop('checked')
    console.log('checkbox checked: ',target.prop('checked'))
    let newValidationState = ''
    newValidationState = isChecked ? VALIDATION_STATES.valid: VALIDATION_STATES.invalid
    
    if(this.validationState !== newValidationState) {
      this.validationState = newValidationState
      this.dispatchValidationState()
    }
  }
}