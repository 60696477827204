import { Controller } from '@hotwired/stimulus'
let Cookies = require('js-cookie')

export default class extends Controller {

  static targets = ["parent"]

  connect() {
    $(this.parentTarget).on("hide.bs.collapse", function (event) {
      let target = $(event.target)

      console.log(target)

      if (target.attr("id") == "backend") {
        $(".frontend").addClass("invisible")
        $(".backend").removeClass("invisible")
      } else if(target.attr("id") == "frontend") {
        $(".frontend").removeClass("invisible")
        $(".backend").addClass("invisible")
      }
    });

    ///
    // Navigation Sidebar
    ///
    // set the sidebar nav cookie if not set so far
    if(Cookies.get('YL_navSidebar') == undefined) {
      YL_navSidebar.initCookie()
    }
  
    // set the sidebar nav according to cookie stored state
    let state = YL_navSidebar.getState()
  
    if(state['show'])
      YL_navSidebar.show()
    else
      YL_navSidebar.hide()

    $(document).on('click', '.nav-sidebar-toggle', (_) => YL_navSidebar.toggle())
  }
}

let YL_navSidebar = {
  initCookie: function() {
    return Cookies.set('YL_navSidebar', JSON.stringify({ show: true }), {
      path: '/',
      expires: 365
    })
  },
  init: function() {},
  getNavSidebar: function() {
    return $('.nav-sidebar')
  },
  getToggleButton: function() {
    return $('.nav-sidebar-toggle')
  },
  getState: function() {
    return JSON.parse(Cookies.get('YL_navSidebar'))
  },
  setState: function(_state) {
    return Cookies.set('YL_navSidebar', JSON.stringify(_state), {
      path: '/',
      expires: 365
    })
  },
  show: function(transition) {
    if (transition == null) {
      transition = false;
    }
    YL_navSidebar.toggleTransition(transition)
    YL_navSidebar.getNavSidebar().removeClass('closed')
    YL_navSidebar.getToggleButton().removeClass('closed')
    return YL_navSidebar.setState({
      show: true
    })
  },
  hide: function(transition) {
    if (transition == null) {
      transition = false;
    }
    YL_navSidebar.toggleTransition(transition);
    YL_navSidebar.getNavSidebar().addClass('closed')
    YL_navSidebar.getToggleButton().addClass('closed')
    return YL_navSidebar.setState({
      show: false
    })
  },
  toggle: function(transition) {
    if (transition == null) {
      transition = true;
    }
    if (YL_navSidebar.getNavSidebar().hasClass('closed')) {
      return YL_navSidebar.show(transition);
    } else {
      return YL_navSidebar.hide(transition);
    }
  },
  toggleTransition: function(transition) {
    if (transition) {
      return YL_navSidebar.getNavSidebar().addClass('transition')
    } else {
      return YL_navSidebar.getNavSidebar().removeClass('transition')
    }
  }
}