import ApplicationController from '../application_controller'
import VALIDATION_STATES from './validation_states'

export default class extends ApplicationController {
  static targets = ["input"]

  initialize() {
    super.initialize()
    this.isRequired = $(this.element).data('required')
    this.validationState = 'invalid'
    this.validationRegex = $(this.element).data('validation-regex')
    if(this.hasInputTarget) {
      if(this.isRequired) {
        this.inputTarget.addEventListener('input', this.watchField.bind(this))
        this.checkValidation($(this.inputTarget))
      } else {
        // well optional so post it as valid
        this.validationState = 'valid'
        this.dispatchValidationState()
      }
    }
  }

  watchField(event) {
    this.checkValidation($(event.currentTarget))
  }

  checkValidation(target) {
    const value = target.val()
    let newValidationState = ''
    if(this.validationRegex != null) {
      const regularExpression = new RegExp(this.validationRegex, 'g')
      console.log(regularExpression)
      newValidationState = regularExpression.test(value) ? VALIDATION_STATES.valid : VALIDATION_STATES.invalid
    } else {
      // just check if it is there
      newValidationState = value !== '' && value !== null ? VALIDATION_STATES.valid : VALIDATION_STATES.invalid
    }
    if(this.validationState !== newValidationState) {
      this.validationState = newValidationState
      this.dispatchValidationState()
    }
  }

  dispatchValidationState() {
    this.logInfo(`dispatching new state ${this.validationState}`)
    const eventName = 'validations.field-validation'
    const stateData =  {
      validationState: this.validationState
    }
    const validationStateEvent = new CustomEvent(eventName, {
      detail: stateData
    })

    this.element.dispatchEvent(validationStateEvent)
    $(this.element).trigger({type: eventName, detail: stateData})
  }
}