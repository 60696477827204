import Ajax from '../packs/ajax';

export default class PhaseLine {

  constructor({element, options }) {
    this.element = element
    this.options = options
    this.initialize()
  }

  initialize() {
    // initialize drop events
    this.defineHandlers()
  }

  /*
  * defines the drop behavior
  */
  async drop(ev, self) {
    ev.stopPropagation();

    
    let id = ev.dataTransfer.getData("id"),
    type = ev.dataTransfer.getData("type"),
    subtype = ev.dataTransfer.getData("subtype"),
    defId = ev.dataTransfer.getData("defId"),
    $target = $(ev.target);
    // console.log("target: ", $target);
    // console.log("type: ", type);
    // console.log("subtype: ", subtype);

    let parent = $target.parent(),
      phaseId = parent.data("phase-id");
    const title = parent.data("title");
    const proposedLearnUnitIndex = parent.data("plu");

    let prev = $target.prev('.phase-part:not(.opacity-30)');
    // console.log("prev: ", prev);
    let learnUnitPosition = $(parent).children('.phase-part:not(.opacity-30)').index(prev) + 1;

    // console.log("position: ", learnUnitPosition);

    $(self.element).trigger({
      type: "phasechange",
      phaseId: phaseId,
      learnUnitPosition: learnUnitPosition,
      learnUnitType: type,
      learnUnitSubtype: subtype,
      defId: defId,
      learnUnitId: id,
      element: this.element,
      title: title,
      proposedLearnUnitIndex: proposedLearnUnitIndex
    });
  }

  /*
  * sets the event handlers for drag&drop behavior
  */
  defineHandlers(){
    let self = this;

    // define the dragenter behavior
    $(this.element).on('dragenter', '[dropzone=true]', function(ev) {
      // $(ev.target).css('border', '1px solid green')
      $(ev.target).addClass('entered')
    })

    // define the dragleave behavior
    $(this.element).on('dragleave', '[dropzone=true]', function(ev) {
      // $(ev.target).css('border', '')
      $(ev.target).removeClass('entered')
    })

    // define the dragover behavior
    $(this.element).on('dragover', '[dropzone=true]', function(ev) {
      ev.preventDefault()
    })

    // define the drop behavior
    $(this.element).on("drop", "[dropzone=true]", function (ev) {
      $(ev.target).removeClass("entered");
      self.drop(ev.originalEvent, self);
    });
  }

}

/*
* create a unique id for dynamic created elements
*/
function createUUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}
