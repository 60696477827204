import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["label", "toggle"];


  connect() {
    super.connect()
    const self = this
    if(this.hasToggleTarget) {
      this.toggleTargets.forEach((target, index, targets) => {
        const showExtendedTooltips = $(target).prop("checked")
        self.showTooltip({}, showExtendedTooltips)
      })
    }
  }

  ///
  // toggles the extended definition tooltip option in the current users profile
  ///
  toggleExtendedDefinitionTooltip(event) {
    let self = this
    let $target = $(event.currentTarget)
    let showExtendedTooltips = $target.prop("checked");
    // console.log("showExtendedTooltips: ", showExtendedTooltips)

    this.stimulate("profileReflex#set_extended_definition_tooltip", !showExtendedTooltips, 'journey_builder').then(payload => {
      self.showTooltip(payload, showExtendedTooltips)
    })
  }

  /**
   * Show tooltip.
   * @param {*} payload
   * @param boolean showExtendTooltips
   */
  showTooltip(payload, showExtendedTooltips) {
    const { data, element, event } = payload
    // console.log("data: ", data)
    // console.log("element: ", element)
    // console.log("event: ", event)
    let tooltipTitle = showExtendedTooltips ? "data-long-title" : "data-short-title"
    let tooltiped = $("#group_definition_area").find(".learn-unit-item, .card.definition-item")
    // console.log("tooltipped: ", tooltiped)
    tooltiped.tooltip("dispose")
    tooltiped.tooltip(
      {
        title: function() {return $(this).attr(tooltipTitle)}
      }
    )
  }

  ///
  // adds an experience link to the current users profile
  ///
  addExperienceLink(event) {
    event.preventDefault()
    let self = this
    const $target = $(event.currentTarget);
    const form = $target.closest("form");
    this.stimulate("profileReflex#add_experience_link", form[0]);
    // .then(payload => {
    //   // self.addExperienceLinkCallback(payload)
    // })
  }

  ///
  // adds an experience link to the current users profile
  ///
  removeExperienceLink(event) {
    event.preventDefault()
    this.stimulate("profileReflex#remove_experience_link", event.currentTarget);
    // .then(payload => {
    //   // self.addExperienceLinkCallback(payload)
    // })
  }

  ///
  // edits an experience link of the current users profile
  ///
  editExperienceLink(event) {
    event.preventDefault()
    let self = this
    const $target = $(event.currentTarget);
    const experienceLinkName = $target.data("experience-link-name");
    // console.log("experienceLinkName: ", experienceLinkName)
    const experienceLinkUrl = $target.data("experience-link-url");
    // console.log("experienceLinkUrl: ", experienceLinkUrl)
    const experienceLinkDescription = $target.data("experience-link-description");
    // console.log("experienceLinkDescription: ", experienceLinkDescription)
    $("input#profile_experience_link_name").val(experienceLinkName)
    $("input#profile_experience_link").val(experienceLinkUrl)
    $("input#profile_experience_link_description").val(experienceLinkDescription)
  }
}
