import ApplicationController from './application_controller'
import Param from '../packs/param';

export default class extends ApplicationController {

  connect() {
    super.connect()
    this.param = new Param(this.getBaseUrl());
  }

  ///
  // calls the AppointmentReflex to add a new appointment to the form in the default journey builder form
  ///
  add(event) {
    let $target = $(event.currentTarget);
    let eventId = $target.data('event-id');
    this.startLoading()
    this.stimulate('Appointment#add', eventId).then(payload => {
      reinitializeForms($("#learn_units_form"))
      this.reset_csrf_token();
      this.stopLoading()
    })
  }

  ///
  // calls the AppointmentReflex to add a new appointment to the form in the learnable modal
  ///
  addAppointmentToLearnable(event) {
    this.startLoading()
    this.stimulate('Appointment#add_appointment_to_learnable', event.currentTarget).then(() => {
      reinitializeForms($("#learn_units_form_pseudo_modal"))
      this.stopLoading()
    });

  }

  ///
  // toggles show_all/show_from_interest on participation screen
  ///
  switchScope(event) {
    let $target = $(event.currentTarget)
    let isFiltered = $target.prop("checked");
    this.param.updateParams("filtered", isFiltered, false);
    this.stimulate("searchReflex#filter");
  }

  ///
  // deletes an appointment
  delete(event) {
    let $target = $(event.currentTarget);
    let appId = $target.data('app-id');

    this.stimulate('Appointment#delete', appId)
  }

  ///
  // deletes the appointment element from the DOM, when the deletion was successful 
  deleteSuccess(element, reflex, error, reflexId) {
    $(element).remove()
  }

  ///
  // shows an error message when the deletion was not successful
  ///
  deleteError(element, reflex, error, reflexId) {
    // console.log("we have an error here: ", error)
    let $el = $(element).find(".pseudo-btn.delete")
    $el.attr("title", I18n.t(error));
    $el.tooltip("show")
  }



}