import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {

  connect() {
    super.connect();
    this.original = $(".original", this.element)
    this.inlineInput = $(".input-form input", this.element)
    this.editButton = $(".btn-icon.edit", this.element)
    this.cancelButton = $(".btn-icon.cancel", this.element)
    this.inlineInput.hide()
    this.inlineInput.removeClass('hidden')
    this.cancelButton.hide()
    this.cancelButton.removeClass('hidden')
  }

  edit(event) {
    this.original.hide()
    this.inlineInput.show()
    this.editButton.hide()
    this.cancelButton.show()
  }

  cancel(event) {
    this.inlineInput.hide()
    this.original.show()
    this.editButton.show()
    this.cancelButton.hide()
  }

  update(event) {
    event.preventDefault();
    const self = this
    this.startLoading()
    this.cancelButton.hide()
    const newValue = this.inlineInput.val()
    this.stimulate("FormReflex#update", newValue).then(payload => {
      self.inlineInput.hide()
      self.original.text(newValue)
      self.original.show()
      self.editButton.show()
      self.stopLoading();
    });
  }

}
