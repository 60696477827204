import ApplicationController from './application_controller'

export default class extends ApplicationController {

  dragstart(event) {
    // console.log('drag started')
    let target = $(event.target);
    copyDataToDataTransfer(event.dataTransfer, target.data());
  }

  dragover(event) {
    // console.log('drag over')
    event.preventDefault();
  }

  dragend(event) {
    let journeyId, userId;
    let item = $(event.currentTarget).data("item");
    let filter = ""
    switch (item) {
      case "users":
        userId = $(event.currentTarget).data('userid');
        // console.log("user ID: ", userId);
        filter = $("#participants_filter")[0].value
        // console.log("filter: ", filter);
        this.stimulate("GroupsReflex#remove_user", userId, filter).then(() => {
          this._filterParticipants(filter);
        });
        break;
      case "journeys":
        journeyId = $(event.currentTarget).data('journeyid');
        filter = $("#journeys_filter")[0].value
        // console.log("filter: ", filter);
        this.stimulate("GroupsReflex#remove_journey", journeyId, filter).then(() => {
          this._filterJourneys(filter);
        });
        break;
      case "user_journeys":
        journeyId = $(event.currentTarget).data('journeyid');
        filter = $("#journeys_filter")[0].value
        // console.log("filter: ", filter);
        this.stimulate("UsersReflex#remove_journey", journeyId, filter).then(() => {
          this._filterJourneys(filter);
        });
        break;
      default:
        break;
    }
  }

  drop(event) {
    // console.log("dropped an element")
    let journeyId, userId, groupId;
    let maxDuration = event.dataTransfer.getData('duration');
    let item = $(event.currentTarget).data('item');
    let modal = $("#journey_assignment_modal");
    let modalSubmitButton = modal.find("#assignment_modal_submit_button");
    // console.log("element: ", $(event.currentTarget));
    switch(item) {
      case 'users':
        userId = event.dataTransfer.getData('userid');
        const filter = $("#participants_filter")[0].value
        // console.log("filter: ", filter);
        this.stimulate('GroupsReflex#add_user', userId, filter).then(() => {
          this._filterParticipants(filter);
        });
        break;
      case 'journeys':
        groupId = event.dataTransfer.getData('groupid');
        journeyId = event.dataTransfer.getData('journeyid');
        console.log(`adding journey '${journeyId}' to group '${groupId}'`);
        // console.log("journeyId: ", journeyId);
        modal.find("input#max_duration").val(maxDuration);
        modalSubmitButton.attr("data-journeyid", journeyId);
        modalSubmitButton.attr("data-groupid", groupId);
        modal.modal("show");
        break;
      case 'user_journeys':
        userId = event.dataTransfer.getData('userid');
        journeyId = event.dataTransfer.getData('journeyid');
        console.log(`adding journey '${journeyId}' to user '${userId}'`);
        modal.find("input#max_duration").val(maxDuration);
        modalSubmitButton.attr("data-journeyid", journeyId);
        modalSubmitButton.attr("data-userid", userId);
        modal.modal("show");
        break;
      default:
        console.log(`no fitting item given: ${item}`)
        break;
    }
  }

  // assigns a journey to an user
  assign(event) {
    let journeyId = $(event.currentTarget).data('journeyid');
    let userId = $(event.currentTarget).data('userid');
    // console.log("journey: ", journeyId);
    // console.log("user: ", userId);
    let modal = $("#journey_assignment_modal");
    let maxDuration = modal.find("input#max_duration").val();
    const filter = $("#journeys_filter")[0].value

    modal.modal('hide');
    this.stimulate('UsersReflex#assign_journey', userId, journeyId, maxDuration, $('#choose_user_mentor').val(), filter).then(() => {
      this._filterJourneys(filter);
      this.stopLoading();
    });
  }

  // assigns a journey to a group
  assign_to_group(event) {
    let journeyId = $(event.currentTarget).data('journeyid');
    let groupId = $(event.currentTarget).data('groupid');
    // console.log("journey: ", journeyId);
    // console.log("group: ", groupId);
    let modal = $("#journey_assignment_modal");
    let maxDuration = modal.find("input#max_duration").val();
    const filter = $("#journeys_filter")[0].value
    // console.log("filter: ", filter);
    modal.modal('hide');
    this.startLoading();
    this.stimulate('GroupsReflex#assign_journey', groupId, journeyId, maxDuration, $('#choose_mentor').val(), filter).then(() => {
      this._filterJourneys(filter);
      this.stopLoading();
    });
  }

  ///
  // filters the participants list by given term as prefix
  ///
  filterParticipants(event) {
    let target = event.currentTarget;
    // console.log("target: ", target)
    this._filterParticipants(target.value)
  }

  ///
  // filters the participants list by given term as prefix
  ///
  _filterParticipants(term) {
    console.log(`term: ${term}`)
    const allDivs = $('#unassigned_users .unassigned-user');
    allDivs.removeClass('hidden')

    const nonMatchingDivs = allDivs.filter(function() {
      const text = $(this).find('div.profile-label').text();
      
      return !text.toLowerCase().includes(term.toLowerCase());
    });
    // console.log("non matchings: ", nonMatchingDivs)
    nonMatchingDivs.each(function() {
      $(this).addClass('hidden');
    });
  }

  ///
  // filters the journeys list by set term as prefix
  ///
  filterJourneys(event) {
    let target = event.currentTarget;
    // console.log("target: ", target)
    let term = target.value
    this._filterJourneys(term);
  }  
  
  ///
  // filters the journeys list by given term as prefix
  ///
  _filterJourneys(term) {
    const allDivs = $('#unassigned_journeys .unassigned-journey');
    allDivs.removeClass('hidden')

    const nonMatchingDivs = allDivs.filter(function() {
      const text = $(this).find('.journey-title').text();
      
    return !text.toLowerCase().includes(term.toLowerCase());
    });
    // console.log("non matchings: ", nonMatchingDivs)
    nonMatchingDivs.each(function() {
      $(this).addClass('hidden');
    });
  }

  selectUser(event) {
    $('#all_users .user-card').removeClass('selected')
    $(event.currentTarget).addClass('selected');
    this.stimulate('SupervisingReflex#select_user', event.currentTarget);
    
  }

  ///
  // filters the user list by given term as prefix
  ///
  filterUsers(event) {
    let target = event.currentTarget;
    // console.log("target: ", target)
    this._filterUsers(target.value)
  }

  ///
  // filters the user list by given term as prefix
  ///
  _filterUsers(term) {
    console.log(`term: ${term}`)
    const allDivs = $('#all_users .user-card');
    allDivs.removeClass('hidden')
    allDivs.attr('data-term', term);

    const nonMatchingDivs = allDivs.filter(function() {
      const text = $(this).find('div.profile-label').text();
      
      return !text.toLowerCase().includes(term.toLowerCase());
    });
    // console.log("non matchings: ", nonMatchingDivs)
    nonMatchingDivs.each(function() {
      $(this).addClass('hidden');
    });
  }

}

function copyDataToDataTransfer(dataTransfer, data) {
  for (let [key, value] of Object.entries(data)) {
    dataTransfer.setData(key, value);
  }
  return dataTransfer
}