import ApplicationController from './application_controller'

export default class extends ApplicationController {

  initialize() {
    super.initialize();
    this.useReflex = false;
  }

  /**
   * Diese Funktion wird aufgerufen, wenn ein bestimmtes Ereignis ausgelöst wird.
   * Sie versteckt das aktuell sichtbare Formularelement und zeigt ein neues Formularelement an.
   * 
   * @param {Event} event - Das ausgelöste Ereignis.
   */
  showForm(event) {
    let currentFormElementVisible = $(".form-block:not(.hidden)");
    let newFormElementVisible = $(
      `#form_element_${$(event.currentTarget).data("form-id")}`
    );

    currentFormElementVisible.addClass("hidden");
    if (
      currentFormElementVisible.attr("id") != newFormElementVisible.attr("id")
    ) {
      newFormElementVisible.removeClass("hidden");
    }
  }

  // shows the form element for the selected definitions card
  select(event) {
    let $target = $(event.currentTarget),
    id = $target.data("id"),
    prefix_id = $target.data("prefix-id"),
    isSelected = $target.hasClass('selected');

    $('.form-element').addClass('hidden');

    let form = $(`#${prefix_id}${id}`);
    if(isSelected) {
      form.removeClass('hidden');
    } else {
      form.addClass('hidden');
    }
  }

}