import ApplicationController from "./application_controller";
import SwaggerUI from "swagger-ui";

export default class extends ApplicationController {
  static values = {
    json: String
  };

  initialize() {
    super.initialize();
    this.useReflex = false;
    this.initializeSwagger();
  }

  initializeSwagger() {
    this.url = $(this.element).data("swagger-url");
    if (this.url) {
      const disableInfoPlugin = function () {
        return {
          wrapComponents: {
            InfoUrl: () => () => null,
          }
        };
      };
      this.swaggerUI = SwaggerUI({
        url: this.url,
        domNode: this.element,
        presets: [
          SwaggerUI.presets.apis,
          disableInfoPlugin
        ],
      });
    }
  }
}
