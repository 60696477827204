import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["learnCard", "boxTotal", "boxCorrect", "boxWrong", "interactionBtn"]

  // How many cards are in this stack.
  numberOfCards = 0
  // Which card we are currently.
  currentCardIndex = 0

  // Count of how many answers the user did know in this session
  correct = 0
  // Count of how many answers the user did not know in this session
  wrong = 0
  // How many cards are in the box.
  totalCards = 0

  connect() {
    super.connect()

    this.#initGame()
  }

  #initGame() {
    if(this.hasLearnCardTarget) {
      this.numberOfCards = this.learnCardTargets.filter((learn_card, index, learn_cards) => {
        return !$(learn_card).data('exclude')
      }).length
      this.totalCards = this.numberOfCards
    }
    this.currentCardIndex = 0
    this.correct = 0
    this.wrong = 0
    this.#updateBoxTotalBy(0)
    this.#updateBoxCorrect()
    this.#updateBoxWrong()
    this.#disableInteractionButtons()
  }

  /**
   * This will turn around the the current visible card
   * @param {*} event fired by the button
   */
  turnAround(event) {
    event.preventDefault()
    if(this.hasLearnCardTarget) {
      const currentCard = this.learnCardTargets[this.currentCardIndex]
      $(currentCard).find('.learn-card-frontside').toggleClass('invisible')
      $(currentCard).find('.learn-card-backside').toggleClass('invisible')
      this.#enableInteractionButtons()
    }
  }

  /**
   * This will go to the next card and increase the did know count.
   * @param {*} event fired by the button
   */
  doKnow(event) {
    event.preventDefault()
    const $target = $(event.currentTarget)
    this.stimulate('LearnCardCollectionReflex#card_known', $target.data('learn-card-id'))
    this.#nextCard(event)
    this.correct++
    this.#updateBoxTotalBy(-1)
    this.#updateBoxCorrect()
    this.#disableInteractionButtons()
  }

    /**
   * This will go to the next card and increase the did not know count.
   * @param {*} event fired by the button
   */
  doNotKnow(event) {
    event.preventDefault()
    const $target = $(event.currentTarget)
    this.stimulate('LearnCardCollectionReflex#card_not_known', $target.data('learn-card-id'))
    this.#nextCard(event)
    this.wrong++
    this.#updateBoxTotalBy(-1)
    this.#updateBoxWrong()
    this.#disableInteractionButtons()
  }

  /**
   * This will go to the next card.
   * @param {*} event fired by the button
   */
  #nextCard(event) {
    if(this.hasLearnCardTarget) {
      const previousCard = this.learnCardTargets[this.currentCardIndex]
      $(previousCard).addClass('hidden')
      if(this.currentCardIndex + 1 <= this.numberOfCards) {
        this.currentCardIndex++
        const currentCard = this.learnCardTargets[this.currentCardIndex]
        $(currentCard).removeClass('hidden')
      }
    }
  }

  repeatNotKnown(event) {
    const self = this
    this.stimulate('LearnCardCollectionReflex#repeat_not_known').then(() => {
      self.#initGame()
    })
  }

  /**
   * this will restart the complete learn card collection
   * @param {*} event
   */
  restart(event) {
    const self = this
    this.stimulate('LearnCardCollectionReflex#restart').then(() => {
      self.#initGame()
    })
  }

  /**
   * This will update the number of total cards by a given value.
   * @param {Integer} value how much it should be updated by
   */
  #updateBoxTotalBy(value) {
    if(this.hasBoxTotalTarget) {
      this.totalCards += value
      $(this.boxTotalTarget).children('.box-item-count').html(this.totalCards)
    }
  }

  /**
   * Update the visual box to the given number of correct cards
   */
  #updateBoxCorrect() {
    if(this.hasBoxCorrectTarget) {
      $(this.boxCorrectTarget).children('.box-item-count').html(this.correct)
    }
  }

  /**
   * Update the visual box to the given number of wrong cards
   */
  #updateBoxWrong() {
    if(this.hasBoxWrongTarget) {
      $(this.boxWrongTarget).children('.box-item-count').html(this.wrong)
    }
  }

  /**
   * This will disable all interaction buttons. ('do know' and 'do not know')
   */
  #disableInteractionButtons() {
    if(this.hasInteractionBtnTarget) {
      $(this.interactionBtnTargets).addClass('disabled')
    }
  }

  /**
   * This will enable all interaction buttons. ('do know' and 'do not know')
   */
  #enableInteractionButtons() {
    if(this.hasInteractionBtnTarget) {
      $(this.interactionBtnTargets).removeClass('disabled')
    }
  }
}