import ApplicationController from './application_controller'

export default class extends ApplicationController {

  initialize() {
    super.initialize()
    // this.initializers = {
    //   simple: this.initClickListeners,
    //   in_page: this.initInPage,
    // }

    this.$area = $(this.element)
  }

  connect() {
    super.connect()
    // console.log(`${this.identifier}-controller connected`)
    this.initializeRatings()
  }

  initializeRatings() {
    this.$area.find(".rating").each(function(i, el) {
      // var $el, $ratingAverage, $ratingLabel, $ratingTooltip, $ratingUser;
      const $el = $(el);
      if (el.dataset.initializedRating) {
        return;
      }
      el.dataset.initializedRating = true;
      const $ratingAverage = $el.find(".rating-average");
      const $ratingUser = $el.find(".rating-user");
      const $ratingLabel = $el.find(".rating-average-label");
      $ratingAverage.barrating({
        theme: "custom-" + ($el.attr("data-styling")),
        initialRating: Math.round(parseFloat($ratingAverage.attr("data-value"))),
        readonly: true
      });
      if (!$ratingUser.length) {
        return;
      }
      $ratingUser.barrating({
        theme: "custom-" + ($el.attr("data-styling")),
        showSelectedRating: false,
        allowEmpty: false,
        deselectable: false
      });
      const $ratingTooltip = $ratingAverage.parents(".br-wrapper");
      $ratingTooltip.tooltipster({
        animation: 'fade',
        trigger: 'click',
        delay: 200,
        side: 'bottom',
        interactive: true,
        theme: 'tooltipster-rating',
        content: $el.find(".rating-tooltip-content"),
        functionReady: function(instance, helper) {
          return $ratingUser.barrating("set", $ratingUser.attr("data-value"));
        }
      });
      return $el.find(".rating-submit").click(function() {
        const value = $ratingUser.data("barrating").ratingValue;
        if (!value) {
          return false;
        }
        $.post($el.attr("data-path"), {
          value: value
        }, function(data) {
          var rating;
          rating = parseFloat(data["average_rating"]);
          $ratingAverage.barrating("set", Math.round(rating));
          $ratingUser.attr("data-value", value);
          $ratingLabel.html(rating.toFixed(1));
          return $ratingTooltip.tooltipster("close");
        });
        return false;
      });
    });
  }

}
