import ApplicationController from './application_controller'

////
// responsible for image source switching in definitions
////
export default class extends ApplicationController {


  connect() {
    super.connect();
    let self = this;
  }

  ///
  // toggles the visibility of upload or default image field
  ///
  toggleDefaultIcon(event) {
    // console.log("switching default icon");
    event.preventDefault();
    // this.startLoading();
    let $target = $(event.currentTarget);
    let $iconDefinition = $target.closest(".row").find(".icon-definition");
    // console.log("icon definition: ", $iconDefinition);
    $iconDefinition.find(".default").toggleClass("hidden");
    $iconDefinition.find(".uploaded").toggleClass("hidden");
  }
}