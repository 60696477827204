import ApplicationController from './application_controller';

export default class extends ApplicationController {

  selectRoom(event) {
    let $target = $(event.currentTarget);
    console.log($target);
    $('.room-item.bg-light').removeClass('bg-light');
    this.stimulate('Rooms#select_room', $target.data('room-id'));
  }
}