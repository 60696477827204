import ApplicationController from './application_controller'

////
// all around the multiplechoice learn unit type
////
export default class extends ApplicationController {

  static values = {
    showmodals: String,
    hidemodals: String
  }

  static targets = [ "questions", "button" ]
  
  ////
  // checks if in each question an answer is checked and shows submit button, when true
  ////
  markedAnswer(event) {
    // console.log("marked answer")
    let $target = $(event.currentTarget);
    let $questions = $(this.questionsTarget);
    let $button = $(this.buttonTarget);
    // console.log("button: ", $button)
    // console.log("questions: ", $questions)
    let allChecked = true;
    $questions.find(".answers").each(function() {
      // console.log("found answers: ", this);
      if ($(this ).find("input[type=checkbox]:checked").length == 0) {
        allChecked = false;
      }
    });
    if (allChecked) {
      // console.log("one answer checked for each question");
      $button.removeClass('disabled');
    } else {
      $button.addClass('disabled');
    }
  }

}