import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  change(event) {
    let self = this;
    let input = event.currentTarget;
    if (input.files && input.files[0]) {
      self.loadPreview(input);
    }
  }

  loadPreview(input) {
    var reader = new FileReader();
    var file = input.files[0];

    reader.onload = function (e) {
      let next = $(input).next();
      if (file.type.startsWith("video")) {
        // create video tag
        if ($(next).is("video")) {
          $(next).attr("src", e.target.result);
        } else {
          $(next).remove();
          let imageElement = document.createElement("video");
          $(imageElement).attr({
            src: e.target.result,
            autoplay: "autoplay",
            muted: "muted",
            loop: "loop",
          });
          $(imageElement).insertAfter(input);
        }
      } else {
        // create image tag
        if ($(next).is("img")) {
          $(next).attr("src", e.target.result);
        } else {
          $(next).remove();
          let imageElement = document.createElement("img");
          $(imageElement).attr("src", e.target.result);
          $(imageElement).insertAfter(input);
        }
      }
    };

    reader.readAsDataURL(file);
  }
}
