window.formInitializationRoutines = [];

window.addFormInitializationRoutine = function(routine) {
  formInitializationRoutines.push(routine);
  return routine(document.body);
};

window.reinitializeForms = function(context) {
  var i, len, results, routine;
  console.log("form is reinitialized");
  i = void 0;
  len = void 0;
  routine = void 0;
  i = 0;
  len = formInitializationRoutines.length;
  results = [];
  while (i < len) {
    routine = formInitializationRoutines[i];
    routine(context);
    results.push(i++);
  }
  return results;
};


/*
 * Google Maps
 */

window.mapsHelper = {
  initialized: false,
  initializationRoutines: [],
  defaultConfiguration: {
    center: {
      lat: 51.165693,
      lng: 10.451528
    },
    zoom: 5
  },
  addInitializationRoutine: function(routine) {
    this.initializationRoutines.push(routine);
    if (this.initialized) {
      return $(function() {
        return routine(document.body);
      });
    }
  },
  updateMapWithPlace: function(mapApi, place, marker) {
    if (place.geometry.viewport) {
      mapApi.fitBounds(place.geometry.viewport);
    } else {
      mapApi.setZoom(11);
    }
    mapApi.setCenter(place.geometry.location);
    if (marker) {
      marker.setPlace({
        placeId: place.place_id,
        location: place.geometry.location
      });
      return marker.setVisible(true);
    }
  }
};

window.initializeGoogleMaps = function() {
  mapsHelper.initialized = true;
  return $(function() {
    var j, len1, ref, results, routine;
    ref = mapsHelper.initializationRoutines;
    results = [];
    for (j = 0, len1 = ref.length; j < len1; j++) {
      routine = ref[j];
      results.push(routine(document.body));
    }
    return results;
  });
};

addFormInitializationRoutine(function(context) {
  if (mapsHelper.initialized) {
    return $(function() {
      var j, len1, ref, results, routine;
      ref = mapsHelper.initializationRoutines;
      results = [];
      for (j = 0, len1 = ref.length; j < len1; j++) {
        routine = ref[j];
        results.push(routine(context));
      }
      return results;
    });
  }
});