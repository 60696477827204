import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {

  static targets = [ "slot" ]

  initialize() {
    super.initialize()
    this.defineHandlers()
  }

  connect() {
    super.connect()
    // console.log(`slots are: ${this.slotTargets}`)
  }

  /*
  * defines the drop behavior
  */
  drop(ev, self, slotType) {
    ev.stopPropagation();

    const type = ev.dataTransfer.getData("type"),
      subtype = ev.dataTransfer.getData("subtype"),
      defId = ev.dataTransfer.getData("defId"),
      $target = $(ev.target),
      $slot = $target.closest(".ltd-slot");

    // console.log("target: ", $target);
    // console.log("slot type: ", slotType);
    // console.log("type: ", type);
    // console.log("subtype: ", subtype);
    if (['certificate', 'learn_type_dependent'].includes(type) || type == undefined) {
      $slot.addClass('not-accepted')
      return
    } else {
      $slot.removeClass('not-accepted')
    }
    
    const disabled = $slot.data('disabled')

    console.log(disabled)

    if(!disabled) {
      self.startLoading()
      self.stimulate("LearnunitReflex#add_tld_slot",{ serializeForm: false }, type, subtype, defId, slotType).then(() => {
        // console.log("slot done")
        self.stopLoading();
      });
    }
  }

  /*
  * sets the event handlers for drag&drop behavior
  */
  defineHandlers(){
    let self = this;

    // // define the dragenter behavior
    // this.slotTargets.forEach(function (el, index) {
    //   $(el).on('dragenter', function (ev) {
    //     const $slot = $(ev.target).closest(".ltd-slot")
    //     // $slot.css('border', '2px solid green')
    //     // $slot.addClass('entered')
    //   })
    // })

    // define the dragleave behavior
    this.slotTargets.forEach(function (el, index) {
      $(el).on('dragleave', function(ev) {
        const $slot = $(ev.target).closest(".ltd-slot")
        // $slot.css('border', '1px solid black')
        if($slot.data('disabled')) {
          $slot.tooltip('dispose')
        }
        $slot.removeClass('entered')
      })
    })

    // define the dragover behavior
    $(this.element).on('dragover', function(ev) {
      ev.preventDefault()
      const $slot = $(ev.target).closest(".ltd-slot")
      // $slot.css('border', '2px solid green')
      if($slot.data('disabled')) {
        $slot.tooltip('show')
      }
      $slot.addClass('entered')
  })

    // define the drop behavior
    this.slotTargets.forEach(function (el, index) {
      $(el).on("drop", function (ev) {
        const $slot = $(ev.target).closest(".ltd-slot")
        const slotType = $slot.data('slot-type');
        // $slot.css('border', '2px solid red')
        $slot.removeClass("entered");
        self.drop(ev.originalEvent, self, slotType);
      });
    })
  }

  ///
  // opens the pseudo modal for editing learnables
  ///
  openLearnableEditor(event) {
    // we should send the slot element
    const slot = event.currentTarget
    this.stimulate("LearnunitReflex#open_learnable_editor", slot,{ serializeForm: false }).then(() => {
      reinitializeForms($("#learn_units_form_pseudo_modal"))
      $("#learn_units_form_pseudo_modal").removeClass("d-none");
      $('#learn_units_form').hide();
    });

  }

  saveChanges(event) {
    const $target = $(event.currentTarget)
    // console.log("target: ", $target)
    const learnableType = $target.data('learnable-type')
    // console.log("learnable_type: ", learnableType)
    const learnableId = $target.data('learnable-id')
    // console.log("learnable_id: ", learnableId)
    const form = $("#learn_units_form_pseudo_modal").find('form')[0]
    // console.log("form: ", form)
    this.stimulate("LearnunitReflex#save_changes", form,{ serializeForm: true }, learnableType, learnableId).then(() => {
      $("#learn_units_form_pseudo_modal").addClass("d-none");
      $('#learn_units_form').show();
    });

  }  
  
  ///
  // closes the pseudo modal
  ///
  closePseudoModal(event) {
      $("#learn_units_form_pseudo_modal").addClass("d-none");
      $('#learn_units_form').show();
  }

  deleteSlot(event) {
    // we should send the slot element
    const slot = $(event.currentTarget).closest(".ltd-slot")
    // console.log("slot: ", slot)
    this.stimulate("LearnunitReflex#delete_ltd_slot", slot[0], { serializeForm: false }).then(() => {
      console.log('slot removed')
    });

  }

}
