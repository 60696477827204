import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["player", "errorMessage"]

  connect() {
    super.connect()
    this.useReflex = false
    if(this.hasPlayerTarget) {
      this.id = $(this.playerTarget).data("id")
      this.videoPlayerType = $(this.playerTarget).data("video-player-type")
      this.videoId = $(this.playerTarget).data("video-id")
      this.videoUrl = $(this.playerTarget).data("video-url")
      this.initializeOnStart = $(this.playerTarget).data("initialize-on-start")
      this.playerControls = $(this.playerTarget).data("player-controls")
      if(this.playerControls === undefined) {
        this.playerControls = "2"
      }
      console.log(this.playerControls)
      this.dispatchedVideoEnded = false
      // initialize the missing frameworks if there are one.
      this.#initializeFrameworks()

      if(this.initializeOnStart) {
        window.YT.ready(() => {
          if(this.player == null) {
            this.createPlayer()
          }
        })
      }
    }
  }

  /**
   * This plays the video back. If the player is not created yet it will be created.
   * In case we have already played it back it will be replayed.
   */
  playVideo() {
    if(this.player == null) {
      this.createPlayer()
    }
    switch(this.videoPlayerType) {
      case "youtube":
        if(this.player.getPlayerState) {
          if(this.player.getPlayerState() == YT.PlayerState.ENDED) {
            this.stopVideo()
          }
        }
        if(this.player.playVideo) {
          this.player.playVideo()
        } else {
          this.startVideoOnReady = true
        }
        break
      case "vimeo":
        this.player.play()
        break
    }
  }

  /**
   * This will pause the video.
   */
  pauseVideo() {
    this.startVideoOnReady = false
    if(this.player == null) {
      this.createPlayer()
    }

    switch(this.videoPlayerType) {
      case "youtube":
        if(this.player.pauseVideo) {
          this.player.pauseVideo()
        }
        break
      case "vimeo":
        this.player.pause()
        break
    }
  }

  /**
   * This will stop the video
   */
  stopVideo() {
    this.dispatchedVideoEnded = false
    if(this.player == null) {
      this.createPlayer()
    }

    switch(this.videoPlayerType) {
      case "youtube":
        if(this.player.stopVideo) {
          this.player.stopVideo()
        }
        break
      case "vimeo":
        this.player.stop()
        break
    }
  }

  /**
   * create a player instance either for vimeo or for youtube.
   */
  createPlayer() {
    this.logInfo(`create player for ${this.videoPlayerType} with these parameters: ${this.videoId}, ${this.videoUrl}, ${this.id}`)
    switch(this.videoPlayerType) {
      case "vimeo":
        if(this.videoUrl) {
          this.player = new Vimeo.Player(
            `video_player_${this.id}`,
            { url: this.videoUrl, responsive: true }
          )
          this.player.on('ended', (event) => this.#dispatchVideoEnded(event))
        } else {
          // no video url for a vimeo video. Inform user about missing url.
          this.logWarning("no vimeo video url is set")
          this.#displayErrorMessage()
        }
        break
      case "youtube":
        if(!this.videoId && !this.videoUrl) {
          // neither id nor video url. Inform user about this
          this.logWarning("neither a url or id is set for youtube")
          this.#displayErrorMessage()
          return
        }
        let self = this
        self.logInfo("youtube framework is ready to initialize")
        let youtubeId = self.videoId
        if(self.videoUrl) {
          youtubeId = self.#cutYoutubeId(self.url)
        }
        self.player = new window.YT.Player(
          `video_player_${self.id}`,
          { 
            height: "100%",
            width: "100%",
            videoId: youtubeId,
            playerVars: { autoplay: 0, controls: this.playerControls, iv_load_policy: 3, modestbranding: 1, rel: 0, showinfo: 0 },
            events: { 'onReady': (event) => { self.#onVideoReady(event, self) }, 'onStateChange': (event) => { self.#playerStateChange(event, self) } } 
          }
        )
        break
      default:
        // we don't know the video type. Display the User this information.
        this.logWarning("we don't know what kind of video player you wanted to display.")
        break
    }
  }

  /**
   * This will import the needed frameworks for the videoPlayerType.
   * When it is already imported it will not import it again.
   */
  #initializeFrameworks() {
    const firstScriptTag = document.getElementsByTagName("script")[0]
    if($("script#yt_framework").length == 0 && this.videoPlayerType == "youtube") {
      this.logInfo("initializing youtube framework")
      const ytTag = document.createElement("script")
      ytTag.id = "yt_framework"
      ytTag.src = 'https://www.youtube.com/iframe_api'
      firstScriptTag.parentNode.insertBefore(ytTag, firstScriptTag)
    }

    if($("script#vimeo_framework").length == 0 && this.videoPlayerType == "vimeo") {
      this.logInfo("initializing vimeo framework")
      const vimeoTag = document.createElement('script');
      vimeoTag.id = "vimeo_framework"
      vimeoTag.src = 'https://player.vimeo.com/api/player.js';
      firstScriptTag.parentNode.insertBefore(vimeoTag, firstScriptTag);
    }
  }

  /**
   * this dispatch the VideoPlayer.ended event through the player target dom.
   * @param {*} event 
   */
  #dispatchVideoEnded(event) {
    if(!this.dispatchedVideoEnded) {
      this.dispatchedVideoEnded = true
      let player = event.target
      const videoEnded = new CustomEvent("videoplayer.ended", {
        detail: { video_id: this.id, player: player },
        bubbles: true,
        cancelable: true,
        composed: false,
      })
      if(this.hasPlayerTarget) {
        this.logInfo(`dispatching event ${videoEnded.type}`)
        this.playerTarget.dispatchEvent(videoEnded)
      }
    }
  }

  /**
   * Callback for whenever the youtube player is ready for playback.
   * It will dispatch the VideoPlayer.ready to the player Target dom object.
   * @param {*} event 
   * @param {VideoPlayerController} self 
   */
  #onVideoReady(event, self) {
    let player = event.target

    if(self.startVideoOnReady) {
      self.playVideo()
    }

    const playerReadyEvent = new CustomEvent("videoplayer.ready", {
      detail: { video_id: this.id, player: player },
      bubbles: true,
      cancelable: true,
      composed: false,
    })
    if(this.hasPlayerTarget) {
      this.logInfo(`dispatching event ${playerReadyEvent.type}`)
      this.playerTarget.dispatchEvent(playerReadyEvent)
    }
  }

  /**
   * Callback for Youtube Player when the state is changed.
   * We are only in the YT.PlayerState.ENDED interested to dispatch an Event for this.
   * @param {*} event 
   * @param {VideoPlayerController} self 
   */
  #playerStateChange(event, self) {
    let state = event.data

    this.logInfo(`current player state -> ${state}`)
    this.logInfo(`state ended: ${YT.PlayerState.ENDED}`)

    switch(state) {
      case YT.PlayerState.ENDED:
        this.startVideoOnReady = false
        self.#dispatchVideoEnded(event)
        break
    }
  }

  /**
   * this shows the Error Message when an error happens, like missing url and video id.
   */
  #displayErrorMessage() {
    if(this.hasErrorMessageTarget) {
      $(this.errorMessageTarget).removeClass('d-none')
    }
  }

  /**
   * This cut out the video id from an youtube url.
   * @param {String} url the youtube url, e.g. https://www.youtube.com/watch?v=foQTKCQqVWk
   * @returns {String} the youtube video id, e.g. foQTKCQqVWk
   */
  #cutYoutubeId(url) {
    let regex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
    let match = url.match(regex)
    return match[1]
  }
}