import ApplicationController from './application_controller'
import { getMetaValue } from "../packs/global_functions";

export default class extends ApplicationController {

  connect() {

    super.connect();
    var $renderModal = $("#render_modal")
    if($renderModal.length){
      var modalId = $renderModal.data('modalid')
      // console.log("modal ID: ", modalId)
      $(`#${modalId}`).modal('show')
    }
  }

  getDefinitionDialog(event) {
    event.stopPropagation();

    //hide selected definitions/group-definitions
    $(".card.selected").css({
      "border-color": "",
      "background-color": ""
    });
    $(".card.selected").removeClass("selected");
    $('.form-element').addClass('hidden');

    let $target = $(event.currentTarget),
    groupId = $target.data('group-id'),
    $openingTarget = $($target.data('target'));

    let items = $openingTarget.find("[data-group-id]");
    items.attr('data-group-id', groupId);

    $openingTarget.modal('show');
  }

  getLocation(event) {
    let locationId = $(event.currentTarget).data('location-id')
    this.stimulate('LocationReflex#getLocation', "#location_info", locationId)
  }

  getLocationSuccess(event) {
    $('#location_modal').modal('show')
    window.initializeGoogleMaps()
  }

  getFaq(event) {
    event.preventDefault();
    $('#modal_faq').modal('show')
  }

  getDataPolicy(event) {
    event.preventDefault();
    $('#modal_privacy_policy').modal('show')
  }

  getImprint(event) {
    event.preventDefault();
    $('#modal_imprint').modal('show')
  }

  openTenantEdit(event) {
    let tenantId = $(event.currentTarget).data('tenant-id')
    this.stimulate('TenantModalReflex#open_edit', tenantId).then(() => {
      $("#tenant_edit_modal [name=authenticity_token]").val(getMetaValue("csrf-token"));
      $('#tenant_edit_modal').modal('show');
    })
  }

  openTenantCreate(event) {
    this.stimulate('TenantModalReflex#open_create').then((event)=> {
      $("#tenant_create_modal [name=authenticity_token]").val(getMetaValue("csrf-token"));
      $('#tenant_create_modal').modal('show');
    })
  }

  getGroupCloneDialog(event) {
    event.preventDefault();
    event.stopPropagation();
    let $target = $(event.currentTarget);
    let groupId = $target.data('group-id');
    this.stimulate('GroupDefinitionReflex#group_clone_open', groupId);
  }

  groupCloneOpenSuccess(event) {
    $("#modal_clone [name=authenticity_token]").val(getMetaValue("csrf-token"));
    let $openingTarget = $('#modal_clone');
    $openingTarget.modal('show');
  }

  close(event) {
    let target = $(event.currentTarget).closest(".modal");
    $(target).modal('hide');
  }

  getGroupDefinitionEditDialog(event) {
    event.preventDefault();
    event.stopPropagation();
    // console.log("clicked")
    let $target = $(event.currentTarget);
    let groupId = $target.data('group-id');
    let formPartIdentifier = `#group_definition_form_${groupId}`
    // console.log("form part id: ", formPartIdentifier)
    let $formPart = $(formPartIdentifier);
    // console.log("form part: ", $formPart)
    $formPart.removeClass('hidden')
  }

  show_loader(event) {
    let $target = $(event.currentTarget);
    $target.closest(".modal").first().modal("hide");
    this.startLoading();
  }
}