import { I18n } from "i18n-js"

import de from "../js_locales/de.json"
import en from "../js_locales/en.json"

const i18n = new I18n()
window.I18n = i18n;
window.I18nLocales = {
  de: de,
  en: en
}