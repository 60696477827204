import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {

  static targets = ['input']

  connect() {
    super.connect();
  }

  ///
  // toggles the input value as well as the 'hidden' class for both states of the checkbox
  ///
  toggle(event) {
    event.preventDefault();    
    const $target = $(event.currentTarget);
    const $input = $(this.inputTarget)
    const input_val = $input.val()
    if (input_val == 'true') {
      $input.val('false')

    } else {
      $input.val('true')
    }
    $target.parent().find(".on-state, .off-state").toggleClass('hidden')
  }

}
