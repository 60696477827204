import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  peak(event) {
    // console.log("target: ", $(event.target));
    // console.log("a target ? ", $(event.target).closest("a").length > 0);
    // console.log("target != currentTarget ? ", event.target != event.currentTarget);
    if($(event.target).closest("a").length > 0 && event.target != event.currentTarget) {
      // do nothing should not interest you!
    } else {
      event.preventDefault();
      event.stopPropagation();
      let $target = $(event.currentTarget);
      $target.toggleClass('opened');
      $($target.data("target")).toggleClass('open');
    }
  }
}