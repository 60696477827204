import ApplicationController from "../../../javascript/controllers/application_controller"

export default class extends ApplicationController {

  static targets = ["answer"]

  static values = {
    correctAnswersIndex: Array,
    answers: Array
  }


  selectAnswer(event) {
    const currentTarget = event.currentTarget
    const answerIndex = $(currentTarget).data('answer-index')
    const points = this.#calculatePoints(answerIndex)
    this.#sendPointUpdate(points)
    this.oldPoints = points
    this.#dispatchQuestionCompleteState()
  }


  #calculatePoints(answerIndex) {
    console.log(answerIndex)
    console.log(this.correctAnswersIndexValue)

    const isIn = this.correctAnswersIndexValue.includes(answerIndex)

    const answerObject = this.answersValue[answerIndex]

    if (isIn) {
      return answerObject['points']
    } else {
      const calculatedPoints = answerObject['points']
      for(key in calculatedPoints) {
        calculatedPoints[key] *= -1
      }

      return calculatedPoints
    }

    return null
  }

  #dispatchQuestionCompleteState() {
    const questionComplete = new CustomEvent('wizard.quiz.question_state', {
      detail: {
        question_complete: true
      }
    })
    this.element.dispatchEvent(questionComplete)
  }

  #sendPointUpdate(points) {
    const pointUpdateEvent = new CustomEvent('wizard.quiz.point_update', {
      detail: {
        oldPoints: this.oldPoints,
        newPoints: points
      }
    })
    this.element.dispatchEvent(pointUpdateEvent)
  }
}