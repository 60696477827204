import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["input"]

  toggleVisibility(event) {
    let target = event.currentTarget,
    type = $(this.inputTarget).attr('type');

    switch(type) {
      case "password":
        $(target).addClass('pw-visible');
        $(this.inputTarget).attr('type', 'text');
        break;
      case "text":
        $(target).removeClass('pw-visible');
        $(this.inputTarget).attr('type', 'password');
        break;
    }
  }

}