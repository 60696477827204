import ApplicationController from './application_controller'
import Ajax from '../packs/ajax.js';
import { getMetaValue } from "../packs/global_functions";

export default class extends ApplicationController {

  ////
  // adds a new group defintion and renders it
  ////
  new(event) {
    event.preventDefault();
    this.startLoading();
    let $target = $(event.currentTarget);
    //hide selected definitions/group-definitions
    $(".card.selected").css({
      "border-color": "",
      "background-color": ""
    });
    $(".card.selected").removeClass("selected");
    $('.form-element').addClass('hidden');
    this.stimulate('GroupDefinitionReflex#add_group', { serializeForm: false }).then(() => {
      this.replaceAuthenticityTokenInForm();
      this.stopLoading();
    });
  }

  removeGroup(event) {
    event.preventDefault();
    this.startLoading();
    let $target = $(event.currentTarget);
    let groupId = $target.data('group-id');
    let groupCard = $target.closest(".card.group-definition-item").first().parent();
    // console.log("groupId: ", groupId);
    // console.log("groupCard: ", groupCard);
    let confirmed = confirm(I18n.t('shared.delete_group_definitions_confirm')); // 
    if(confirmed) {
      this.stimulate('GroupDefinitionReflex#remove_group', groupId).then(() => {
        groupCard.remove();
      });
    }
    this.stopLoading();
  }

  show_loader(event) {
    let $target = $(event.currentTarget);
    this.startLoading();
  }


  async newSuccess(event) {
    let $newTarget = $('.group-definition-item.selected'),
    prefixId = $newTarget.data('prefix-id'),
    id = $newTarget.data('id');

    let formElement = document.createElement('div');
    formElement.id = `${prefixId}${id}`;
    $(formElement).addClass('form-element');

    let groupForm = $('#group_definitions_form');
    let objectName = groupForm.find('#group_object_name').data('object-name');

    $(formElement).appendTo(groupForm);
    let form = await new Ajax('/group_definition_form').doAjax("GET", { "id": id, "object_name": objectName });
    $(form).appendTo(formElement);
  }

  ///
  // exchange the csrf token in the newly rendered form
  ///
  afterRenderCorrespondingForm(element, reflex, noop, reflexId) {
    this.replaceAuthenticityTokenInForm()
  }

  ///
  // exchange the csrf token when definition group is added
  ///
  afterRenderAddGroup(element, reflex, noop, reflexId) {
    this.replaceAuthenticityTokenInForm()
  }

  ///
  // replaces the csrf token in form with the right one in header
  ///
  replaceAuthenticityTokenInForm() {
    let $form = $("form#group_definitions_form");
    let $authTokenField = $("input[name=authenticity_token]", $form).first();
    if($authTokenField.length == 0) {
      $authTokenField = $("<input>").attr("type", "hidden").attr("name", "authenticity_token");
      $form.append($authTokenField);
      // console.log("authenticity_token field added!!!")
    }
    // console.log("authTokenField: ", $authTokenField[0])
    $authTokenField.val(getMetaValue("csrf-token"));
    // console.log("!!! authenticity token replaced")
  }

  ///
  // imports the default definitions (default_definitions.yml)
  ///
  importDefaults(event) {
    event.preventDefault();
    this.startLoading();
    let $target = $(event.currentTarget);
    this.stimulate('GroupDefinitionReflex#load_default_definitions').then(() => {
      this.stopLoading();
    });
  }

  ///
  // renders the form for the clicked definition card
  ///
  renderDefinitionForm(event) {
    event.preventDefault();
    this.stimulate("DefinitionReflex#render_corresponding_form", event.currentTarget)
  //   this.stimulate("DefinitionReflex#render_corresponding_form", event.currentTarget).then(() => {
  //     this.replaceAuthenticityTokenInForm();
  //   });

  }

  ///
  // renders the form for the clicked group definition card
  ///
  renderGroupDefinitionForm(event) {
    event.preventDefault();
    this.stimulate("GroupDefinitionReflex#render_corresponding_form", event.currentTarget)
  //   this.stimulate("DefinitionReflex#render_corresponding_form", event.currentTarget).then(() => {
  //     this.replaceAuthenticityTokenInForm();
  //   });
  
  }


}