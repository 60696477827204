import ApplicationController from './application_controller'
import Sortable from "sortablejs"


export default class extends ApplicationController {

  static values = {
    init: String
  }

  static targets = ["questionPosition", "answerPosition"]
  
  initialize() {
    super.initialize();

    this.initializers = {
      answers: this.initializeAnswers,
      questions: this.initializeQuestions
    }
  }

  connect() {
    super.connect()
    const self = this
    /// call the configured initializers
    let initMethods = this.initValue.split(" ");
    initMethods.forEach(function (initializer, index) {
      self.initializers[initializer].bind(self)()
    })
  

  }

  initializeAnswers() {
    const self = this;
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      handle: '.drag-handler',
      onEnd: self.answersEnd.bind(self)
    })
  }

  initializeQuestions() {
    console.log("initialize Questions...")
    const self = this;
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      handle: '.drag-handler',
      onEnd: self.questionsEnd.bind(self)
    })

  }

  ///
  // called when sort of answers has been ended
  ///
  answersEnd(event) {
    console.log("ended answer sort")
    console.log("this: ", event.newIndex)
    if(this.hasAnswerPositionTarget) {
      $(this.answerPositionTargets).each((index, positionField) => {
          $(positionField).val(index)
      })
    }
  }

  ///
  // called when sort of answers has been ended
  ///
  questionsEnd(event) {
    console.log("ended questions sort")
    console.log("item: ", event.item)
    console.log("new Index: ", event.newIndex)
    // $(event.item).find('.question-position').val(event.newIndex)
    if(this.hasQuestionPositionTarget) {
      $(this.questionPositionTargets).each((index, positionField) => {
        $(positionField).val(index)
      })
    }
  }

}
