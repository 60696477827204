import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // check all
  // single check
  // field to be updated

  static targets = ["output", "button"]

  checkAll(event) {
    let checkbox = $(event.currentTarget);
    //check all single checkboxes
    $('.multiselect-checkbox').prop('checked', checkbox.prop('checked'));
    this.updateOutputField();
  }

  singleCheck(event) {
    // update the output field
    this.updateOutputField();
  }

  updateOutputField() {
    let values = new Array();
    for(let item of $('.multiselect-checkbox')) {
      if($(item).prop('checked')) {
        values.push(parseInt($(item).data('id')));
      }
    }

    if(this.hasOutputTarget) {
      $(this.outputTarget).val(values.join(',')).trigger('change');
      if(this.hasButtonTarget) {
        if($(this.outputTarget).val() === "") {
          $(this.buttonTarget).addClass('invisible')
        } else {
          $(this.buttonTarget).removeClass('invisible')
        }
      }
    }
  }

}