import ApplicationController from './application_controller'

////
// all video and audio js initialization
////
export default class extends ApplicationController {

  initialize() {
    super.initialize()
    const self = this

    this.playersReady = false
    window.academyVideoPlayers = {}
    this.audioPlayers = {}
    // when the API is ready we can create youtube players
    window.onYouTubeIframeAPIReady = function() {
      self.playersReady = true
    }
  }

  connect() {
    super.connect();

    let self = this
    // let initializeIt = $("#learn_path").data("initialize-media")
    // console.log(`initialize audio/video players: ${initializeIt}`)
    let noInitialization = $("#learn_path_container").data("media-initialized")
    console.log(`initialize audio/video players: ${!noInitialization}`)

    // we do not initialize a second time, when we only render parts of the page (like for reflex morphs)
    if(!noInitialization) {
      // 1) initialize video players
      this.initializeVideoPlayers()

      // 2) initialize  audio players
      this.initializeAudioPlayers()
      $('#learn_path_container').attr("data-media-initialized", true)
    }

  }
  
  ///
  // VIDEO
  ///



  ///
  // create the corresponding youtube/vimeo player whenever the user clicks to watch the video
  ///
  initializeVideoPlayers() {
    console.log('init video players')
    const self = this

    /// 1) load the needed scripts (YT will call onYouTubeIframeAPIReady, when loaded)

    const firstScriptTag = document.getElementsByTagName('script')[0]

    const ytTag = document.createElement('script')
    ytTag.src = 'https://www.youtube.com/iframe_api'
    firstScriptTag.parentNode.insertBefore(ytTag, firstScriptTag)

    // const vimeoTag = document.createElement('script')
    // vimeoTag.src = 'https://player.vimeo.com/api/player.js'
    // firstScriptTag.parentNode.insertBefore(vimeoTag, firstScriptTag)


    /// 2) establish the YT.Player ready event listener
    document.addEventListener("YTVideo.ready", function(event) { 
      // console.log("details in ready event are:", event.detail)
      const videoId = event.detail.video_id
      // console.log(`video id: ${videoId}`)
      const player = event.detail.player
      // console.log(`player: ${player}`)
      self.onAcademyPlayerReady(videoId, player)
    })

    /// 3) detach the video modals, so they will also exist after partial rerendering
    const videos = $('.modal.video').detach()
    videos.appendTo('body')

    /// 2) set event listener for video start clicks
    $(document).on('click', '[data-action=open-video]', function(event) {
      console.log('open the video')
      
      var $target, player, video_name, video_ref, video_tag_id, video_type, shouldConsume
      $target = $(event.currentTarget)
      video_name = $target.data('name')
      shouldConsume = $target.data('consume')
      console.log(`video name: ${video_name}`)
      if ((window.academyVideoPlayers[video_name] == null)) {
        video_type = $target.data('type')
        video_ref = $target.data('ref')
        video_tag_id = 'video_player_' + video_name
        player = new VideoPlayer({
          id: video_tag_id,
          url: video_ref,
          type: 'youtube'
        })
        player.createPlayer()
        if(shouldConsume) {
          player.on('ended', function() {
            return self.markVideoAsConsumed(video_name)
          })
        }
        if (player != null) {
          window.academyVideoPlayers[video_name] = {
            player: player,
            state: 'new',
            id: video_name,
            type: video_type
          }
        }
      } else {
        /// player has been created before, so just use it
        console.log("we restart an existing player")
        player = window.academyVideoPlayers[video_name].player
        self.manipulateVideoModal(video_name, "show")
        player.startVideo()
      }
    })
    
  }

    ///
  // event, fired when the player is ready
  ///
  onAcademyPlayerReady(videoId, player) {
    console.log(`player for ${videoId} is ready`)
    const $video_modal = this.getVideoModal(videoId)

    // show the running video
    $video_modal.modal("show")
    player.playVideo()

    // set listener for the modal hide event, so we can pause the video
    $video_modal.on('hidden.bs.modal', function() {
      console.log("pause video, as modal got hidden")
      player.pauseVideo()
    })  

    // and set state for video
    window.academyVideoPlayers[videoId]['state'] = 'ready'
  }  

  ///
  // marks a video learn_unit as consumed via reflex 
  ///
  markVideoAsConsumed(videoId) {
    console.log(`MARK VIDEO '${videoId}' AS COMSUMED`)
    const learnUnitId = this.manipulateVideoModal(videoId, "hide")
    if (learnUnitId) {
      this.stimulate('ConsumableReflex#claim_video', learnUnitId)
    }
  }

  ///
  // @return [jQuery::Element] the correspondingvideo modal
  ///
  getVideoModal(videoId) { 
    return $(`#modal_video_${videoId}`)
  }

  ///
  // manipulates the modal for given videoId
  // @param videoId [String] the internal ID for the video (name)
  // @param action [String] the action for the modal ("show", "hide")
  // @return [int] the id of the corresponding learn_unit
  ///
  manipulateVideoModal(videoId, action="show") {
    let learnUnitId = null
    const $video_modal = this.getVideoModal(videoId)
    if ($video_modal != null) {
      learnUnitId = $video_modal.data('learn-unit-id')
      $video_modal.modal(action)
    }
    return learnUnitId
  }


  // AUDIO


  ///
  // create the corresponding youtube/vimeo player whenever the user clicks to watch the video
  ///
  initializeAudioPlayers() {
    console.log('init audio players')
    let self = this

    $(document).on('click', 'a.audio-trigger', function(event) {
      console.log('open the audio')
      const $target = $(event.currentTarget)

      // 1) find the corresponding player
      const consumableId = $target.data('consumable-id')
      const playerId = `audio_player_${consumableId}`
      console.log(`started audio player '${playerId}'`)
      const player = document.getElementById(playerId)

      // 2) find modal for player and set event listeners (once)
      const $audio_modal = $("#modal_audio_" + consumableId)

      if($audio_modal.data('url') === undefined) {
        return
      }

      if(self.audioPlayers[playerId] == undefined) {
        self.audioPlayers[playerId] = player

        $audio_modal.on('hidden.bs.modal', function() {
          console.log("pause audio, as modal got hidden")
          player.pause()
        })
        player.addEventListener("ended", function() {
          $audio_modal.modal("hide")
          self.stimulate('ConsumableReflex#claim_audio', consumableId)
        })
      }

      // 3) now start the player and add listener for ended, so the consumable can be claimed
      player.play()
    })

  }

}