import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    super.connect();
    this.updateGroups();
    this.checkRole()
  }

  checkRole() {
    const self = this
    const role = $("select#user_role").val()
    console.log(`role is: ${role}`)
    
    this.showPermissionArea(role)

    // and also setup listener for a role:changed event
    $(document).on("role:changed", function (event, irole) {
      // console.log("git role:changed with ", irole)
      self.showPermissionArea(irole)
    });
  }

  showPermissionArea(role) {
    const $permissionArea = $("#role_permissions")
    if (role == "user") {
      // console.log("we show it")
      $permissionArea.show()
    } else {
      // console.log("we hide it")
      $permissionArea.hide()
    }
  }
  ///
  // stimulates the permission change of a user (only on view)
  ///
  changePermissionSet(event) {
    const self = this
    const $target = $(event.currentTarget)
    const userId = $target.data("user-id");
    // console.log(`user id: ${userId}`)
    this.stimulate('PermissionReflex#change_permission_set', $target.val(), userId).then(payload => {
      self.reset_csrf_token();
    })
  }

  ///
  // when one of th eheader checkboxes is clicked
  ///
  headerChange(event) {
    let group = $(event.currentTarget).attr('id'),
    groupCheckboxes = $(`[data-group='${group}'] input[type=checkbox]`);
    $(groupCheckboxes).each(function() {
      $(this).prop('checked', $(event.currentTarget).prop('checked'))
    });

    this.invalidatePermissionSet();
    this.updatePermissions();
  }

  ///
  // when one of the permission checkboxes is clicked
  ///
  itemChange(event) {
    const $target = $(event.currentTarget)
    const setName = $target.parent().data("set");
    const groupName = $target.parent().data("group-name")
    this.updateGroups(setName, groupName);
    this.invalidatePermissionSet();
    this.updatePermissions();
  }

  ///
  // updates the hidden permissions fild
  ///
  updatePermissions() {
    let selectedPermissions = $("[data-group] input[type=checkbox]:checked")
      .map(function () {
        return parseInt($(this).data("value"));
      })
      .get();

    $("#profile_permissions").val(selectedPermissions)
  }

  ///
  // invalidates the permissions set hidden field, when a permission is changed
  ///
  invalidatePermissionSet() {
    $("#permission_set_selection select").val('').change()
    $("#profile_permission_set_id").val('')
  }

  afterChangeSelectedRole() {
    this.updateGroups();
  }

  ///
  // sets the state of the headr checkboxes appropriately
  ///
  updateGroups(setName, groupName) {
    let permissionHeaderCheckboxesSelector = ".permission-header input[type=checkbox]";      
    if (setName ) {
      permissionHeaderCheckboxesSelector = `.permission-header[data-set=${setName}][data-group=${groupName}] input[type=checkbox]`;
    }
    for(let header of $(permissionHeaderCheckboxesSelector)) {
      const group = $(header).attr("id")
      const groupCheckboxes = $(`[data-group='${group}'] input[type=checkbox]`);

      let total = $(groupCheckboxes).length;
      const checked = groupCheckboxes.filter(':checked').length;

      if (checked == 0) {
        $(header).prop("indeterminate", false);
        $(header).prop("checked", false);
      } else if (checked > 0 && checked < total) {
        $(header).prop("indeterminate", true);
      } else if (checked == total) {
        $(header).prop("indeterminate", false);
        $(header).prop("checked", true);
      }
    }
  }
}