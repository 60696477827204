export default class Ajax {
  constructor(url) {
    this.url = url;
  }

  async doAjax(method, args) {

    let result;

    try {
      result = await $.ajax({
        url: this.url,
        method: method,
        data: $.extend(args, {authenticity_token: $('[name="csrf-token"]')[0].content})
      })

      return result;
    } catch (error) {
      console.error(error);
    }
  }
}