$(function(){
  $(".share #share-button").on("click", function(event) {
    //get variable for product id
    let journey_id = $(this).data('journey'),
    share_modal = $("#share");
    let shareForm = share_modal.find("#share-form");
    $(shareForm).attr("data-url", $(this).data("url"));
    share_modal.find("#product-title").html($(this).data('jtitle'));
    share_modal.find("#shareaddress").val('');
    share_modal.find("#sharemail").val('');
    share_modal.find("#journey").val(journey_id);
    share_modal.modal('show');
  });
});