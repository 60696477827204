import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    let self = this;
    $(document).on(
      "keyup",
      "input[data-attribute]:not([type=hidden]), textarea[data-attribute]:not([type=hidden])",
      function (event) {
        self.resyncData(this);
      }
    );

    $(document).on(
      "change",
      "select[data-attribute]:not([type=hidden]), input[data-attribute][data-inputfield]",
      function (event) {
        self.resyncData(this);
      }
    );

    for (let input of $(
      "input[data-attribute]:not([type=hidden]), textarea[data-attribute]:not([type=hidden]), select[data-attribute]:not([type=hidden]), input[data-attribute][data-inputfield]"
    )) {
      self.resyncData(input);
    }
  }

  resyncData(self) {
    let element = $(self),
      attribute = element.data("attribute"),
      formId = element.data("form-id");

    let value = element.val();

    if (element.attr("type") == "checkbox") {
      value = element.prop("checked");
    }

    let elementToSyncTo = $(
      `[data-attribute=${attribute}][data-form-id=${formId}]:not(:input), [data-attribute=${attribute}][data-form-id=${formId}][type=hidden]`
    );
    if (elementToSyncTo.is(":input")) {
      elementToSyncTo.val(value);
    } else {
      elementToSyncTo.html(value);
    }
  }
}