import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    init: String,
    url: String
  }


  initialize() {
    super.initialize()
    this.initializers = {
      simple: this.initClickListeners,
      in_page: this.initInPage,
    }

    this.$area = $(this.element)
  }

  connect() {
    super.connect()
    let self = this
    let initMethods = this.initValue.split(" ")
    if(initMethods[0] == "") {
      initMethods = ['simple'] 
    }
    initMethods.forEach(function (initializer, index) {
      self.initializers[initializer].bind(self)()
    })
  }

  initClickListeners() {
    const self = this;
    let clickables = self.$area.find("td:not(.not-clickable)");
    // clickables.each(function( index ) {
    //   console.log("clickable: ", this);
    // })
    clickables.on("click", function() {
      const path = self.urlValue
      // console.log(`redirecting to ${path}`);
      document.location.href = path;
    });
  }

  initInPage() {

  }

  ///
  // loads the given partial via reflex
  // locals for the partial is the instance defined by class and id (all parameters are given as data attributes)
  // selected elements can get special css class (selection-class)
  // one also can define a call (method name) that is send to the instance with the current user as a parameter
  ///
  loadInPage(event) {
    // console.log("loading in page")
    const $target = $(event.currentTarget)
    const id = $target.data('id')
    const clazz = $target.data('clazz')
    const partial = $target.data('partial')
    const selector = $target.data('selector')
    const selectionClass = $target.data('selection-class')
    const selectionSelector = $target.data('selection-selector')
    const instanceCall = $target.data('instance-call-for-user')
    // console.log(`class: ${clazz}`)
    // console.log(`id: ${id}`)
    // console.log(`partial: ${partial}`)
    // console.log(`selection class: ${selectionClass}`)
    // console.log(`instance call: ${instanceCall}`)
    
    // set selection class to all childs of parent (after unset selectionClass on all others)
    let selectedClass = $target.attr("class").split(/\s+/).join(".");
    selectedClass = `.${selectedClass}`
    let allSelectables = $target.parent().find(selectedClass).find(selectionSelector)
    allSelectables.removeClass(selectionClass)
    // console.log("allSelectables: ", allSelectables)
    $target.find(selectionSelector).addClass(selectionClass)
    // console.log(`selected class: ${selectedClass}`)
    this.stimulate("selectionReflex#loadInPage", clazz, id, partial, selector, instanceCall)
  }

}

