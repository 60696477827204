export default class Param {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  updateParams(key, param, isArray) {
    let params = this.getParams();

    params = this.addParam(params, key, param, isArray);

    let searchParams = this.createParamList(params);

    if (history.pushState) {
      let newUrl = this.baseUrl + searchParams;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }

  clearParam(key) {
    let params = this.getParams();

    delete params[key];

    let searchParams = this.createParamList(params);

    if (history.pushState) {
      let newUrl = this.baseUrl + searchParams;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }

  getParams() {
    let currentUrl = window.location.href,
      baseUrl = this.baseUrl;

    let currentParams = currentUrl.split(/[\?&]/).filter(function (v) {
      return v !== "" && v !== baseUrl;
    });

    return currentParams.reduce(function (hash, param) {
      let key = param.split(/=/)[0],
        value = param.split(/=/)[1];

      if (key.endsWith("[]")) {
        key = key.substring(0, key.length - 2);
        if (!(key in hash)) {
          hash[key] = new Array();
        }
        hash[key].push(decodeURI(value));
      } else {
        hash[key] = decodeURI(value);
      }
      return hash;
    }, {});
  }

  createParamList(params) {
    let list = [];

    for (let key of Object.keys(params)) {
      let param = params[key];
      if (Array.isArray(param)) {
        for (let item of param) {
          list.push(`${key}[]=${encodeURI(item)}`);
        }
      } else {
        list.push(`${key}=${encodeURI(param)}`);
      }
    }
    if (list.length !== 0) {
      return "?" + list.join("&");
    } else {
      return "";
    }
  }

  addParam(params, key, param, isArray) {
    let hash = params[key];

    if (Array.isArray(hash)) {
      if (!hash.includes(param)) {
        params[key].push(param);
      }
    } else {
      if (isArray) {
        params[key] = new Array();
        params[key].push(param);
      } else {
        params[key] = param;
      }
    }

    return params;
  }
}
