import ApplicationController from "../application_controller"
import VALIDATION_STATES from "./validation_states"

export default class extends ApplicationController {

  static targets = ['group']

  initialize() {
    super.initialize()
    if(this.hasGroupTarget) {
      document.addEventListener
      $(document).on('validations.group-validation', '[data-validations--journey-validation-target="group"]', this.watchValidations.bind(this))
    }
  }

  watchValidations(event) {
    this.logInfo('received new validation state.')
    const targetId = event.detail.id
    const state = event.detail.validationState
    const stateTarget = $(this.element).find(`#${targetId} .state`)

    switch(state) {
      case VALIDATION_STATES.valid:
        stateTarget.addClass('filled')
        break
      case VALIDATION_STATES.invalid:
        stateTarget.removeClass('filled')
        break
    }
  }
}