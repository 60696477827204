import ApplicationController from '../../../javascript/controllers/application_controller'

export default class extends ApplicationController {

  static targets = [ "sortableTree"]
  static values = {
    init: String
  }

  initialize() {
    // console.log(`${this.identifier}-controller initialized`)
    super.initialize()
    this.initializers = {
      sortable_tree: this.initializeSortableTree,
    }
  }
  connect() {
    super.connect();
    // if (this.hassortableTreeTarget) {
    //   console.log(`sortable tree target: ${this.sortableTreeTarget}`)
    // }
    this.callInitializers()
  }

  initializeSortableTree() {
    // console.log("setup the sortable trees")
    this.sortableTreeTargets.forEach((element, index) => {
      // console.log("element: ", element)
      $(element).nestedSortable({
          forceHelperSizeType: true,
          errorClass: 'cantdoit',
          disableNesting: 'cantdoit',
          handle: '.item',
          helper:	'clone',
          listType: 'ol',
          items: 'li',
          opacity: 0.6,
          placeholder: 'placeholder',
          revert: 250,
          maxLevels: $(element).data('max-levels'),
          //maxLevels: #{options[:max_levels] || 5},
          //tabSize: 20,
          // protectRoot: $(this).data('protect-root'),

          // prevent drag flickers
          tolerance: 'pointer',
          toleranceElement: '> .item',
          isTree: true,
          startCollapsed: false,
          expandOnHover: 600,
          // collapsedClass: "collapsed",
          //startCollapsed: $(this).data("start-collapsed"),

          relocate: function(){
              //$(this).nestedSortable("disable");
              var data = $(element).nestedSortable("serialize");
              var url = $(element).data("sortable-url");
              // console.log("data: ", data)
              // update on server
              $.ajax({
                  url: url,
                  type: "post",
                  data: data
              }).always(function(){
                  //$(this).nestedSortable("enable");

                  // $(this).find('.item').each(function(index){
                  //     if (index % 2){
                  //         $(this).removeClass('odd').addClass('even');
                  //     }else{
                  //         $(this).removeClass('even').addClass('odd');
                  //     }
                  // });

              }).done(function(data){

              }).fail(function(jqXHR, textStatus){

              });


              //$(this).nestedSortable("enable");
          }
      }); // nested tree
  });

  }

  ///
  // renders the hard skill import modal
  ///
  renderHardSkillImport(event) {
    event.preventDefault();
    this.stimulate("SkillReflex#render_hard_skill_import").then(payload => {
      // console.log("skillReflex stimulated to render hard skill import modal")
      const $hardSkillsModal = $("#hard_skill_modal")
      $hardSkillsModal.modal("show");
      $hardSkillsModal.on('hidden.bs.modal', function (event) {
        // console.log("I'm hidden!")
        $hardSkillsModal.html("")
        $hardSkillsModal.off('hidden.bs.modal')
      });

    });
  }

  ///
  // renders the soft skill import modal
  ///
  renderSoftSkillImport(event) {
    event.preventDefault();
    // const $target = $(event.currentTarget);
    this.stimulate("SkillReflex#render_soft_skill_import").then(payload => {
      // console.log("skillReflex stimulated to render soft skill import modal")
      $("#soft_skill_modal").modal("show");
    });
  }

  ///
  // stimulates skill reflex to import the skills from given hard skill category
  ///
  importHardSkillCategory(event) {
    const self = this;
    event.preventDefault();
    const $target = $(event.currentTarget);
    const categoryName = $("#hard_category_selection").val();
    const subcategoryName = $("#hard_subcategory_selection").val();
    $("#hard_skill_modal").modal("hide");
    this.startLoading();
    this.stimulate("SkillReflex#import_hard_skill_category", categoryName, subcategoryName).then(payload => {
      // console.log(`skillReflex stimulated to import hard skill category: ${categoryName}-${subcategoryName}`)
      self.stopLoading();
    });

  }

  ///
  // stimulates skill reflex to import the skills from given soft skill category
  ///
  importSoftSkillCategory(event) {
    const self = this;
    event.preventDefault();
    const $target = $(event.currentTarget);
    const subcategoryName = $("#soft_category_selection").val();
    $("#soft_skill_modal").modal("hide");
    this.startLoading();
    this.stimulate("SkillReflex#import_soft_skill_category", subcategoryName).then(payload => {
      // console.log(`skillReflex stimulated to import soft skill category: ${subcategoryName}`)
      self.stopLoading();
    });

  }

  ///
  // enables the import button on soft skills import modal
  ///
  enableSoftSkillImport(event) {
    const subcategoryName = $("#soft_category_selection").val();
    if (subcategoryName != '') {
      $("#soft_skill_import_button").prop("disabled", false)
    } else {
      $("#soft_skill_import_button").prop("disabled", true)
    }
  }

}
