import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {

  static values = {
    init: String
  }

  initialize() {
    super.initialize();

    this.initializers = {
      collapsible: this.initializeCollapsibles
    }
    this.listenersSet = []
  }

  connect() {
    super.connect();
    const self = this
    const initMethods = this.initValue.split(" ");

    initMethods.forEach(function (initializer, index) {
      console.log("processing initializer: ", initializer)
      if (initializer == "") {return};
      self.initializers[initializer].bind(self)()
    })
  }

  initializeCollapsibles() {
    this.logInfo('initialize Collapsibles')
    // 1) find all collapse buttons
    const collapseButtons = $(this.element).find('[data-bs-toggle="collapse"]')
    // console.log("collapse buttons: ", collapseButtons)
    collapseButtons.each(function (index) {
      const $collapseButton = $(this)
      const $toggleArea = $($collapseButton.data('bs-target'))
      // console.log("collapse area: ", $toggleArea)
      $toggleArea.on("shown.bs.collapse", function () {
        // console.log('collapsed')
        $collapseButton.addClass('collapsed')
      })
      $toggleArea.on("hidden.bs.collapse", function () {
        // console.log('hidden: ', $collapseButton)
        $collapseButton.removeClass('collapsed')
      })
    })
  }

}
