import ApplicationController from './application_controller';

export default class PhaseChangeController extends ApplicationController {

  connect() {
    super.connect();
    let self = this;

    ///
    // reacts on :phasechange events from phase_line.js (drag&drop of learn_units)
    ///
    $(document).on("phasechange", function (event) {
      // console.log("//// --- Phase Changed --- ////", event);
      self.learnUnitType = event.learnUnitType;
      self.learnUnitSubtype = event.learnUnitSubtype;
      self.learnUnitId = event.learnUnitId;
      self.phaseId = event.phaseId;
      const defId = event.defId;
      const learnUnitPosition = event.learnUnitPosition;
      const learnUnitTitle = event.title;
      const proposedLearnUnitIndex = event.proposedLearnUnitIndex;
      self.startLoading();
      self.stimulate(
        "PhaseChangeReflex#update_learn_unit",
        self.learnUnitId,
        self.learnUnitType,
        self.learnUnitSubtype,
        defId,
        self.phaseId,
        learnUnitPosition,
        learnUnitTitle,
        proposedLearnUnitIndex
        ).then(() => {
          // trigger click on newly created element to force loading 
          // reinitializeForms($("#journey_builder"))
          reinitializeForms($("#learn_units_form"))
          let $addedPart = $("#learn_path .phase-part.added")
          $addedPart.removeClass('added')
          $addedPart.trigger("click")
          self.create_authenticy_token_for('#journey_form')
          //update meta auhtorization content
          self.reset_csrf_token()
          self.stopLoading();
        });
      });
    
    $(document).on("trashdrop", function (event) {
      let learnUnitId = event.learnUnitId;
      // console.log("//// --- Learn Unit Deleted --- ////");
      // console.log("id: ", learnUnitId);
      self.stimulate("PhaseChangeReflex#delete_learn_unit", learnUnitId);
      // console.log("//// --- Learn Unit Deleted --- ////");
    });
  }

  getExistingPhasePosition(phaseId) {
    let elements = [];
    let matches;

    if (phaseId) {
      elements = $(`[value=${phaseId}]`);
    }
    // console.log("elements: ", elements);
    if (elements.length > 0) {
      let element = elements.first();
      matches = $(element).attr("id").match(/\d+/g);
    }

    return matches != null && matches.length > 0 ? parseInt(matches[0]) : -1;
  }

}