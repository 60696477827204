import { Controller } from '@hotwired/stimulus'

// FullCalendar Integration
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';

export default class extends Controller {

  static targets = ["calendar"];
  static values = {
    ctype: String,
    aspectRatio: Number,
  }

  connect() {
    let calendarElement = this.calendarTarget;
    console.log("calendarElement: ", calendarElement);
    let calendarType = this.ctypeValue;
    console.log("calendarType: ", calendarType);
    let eventsUrl = calendarType == "anonymous" ? "anonymous_events" : "events";
    let locale = I18n.locale;
    console.log("////// current locale: ", locale);
    let calendar = new Calendar(calendarElement, {
      plugins: [ dayGridPlugin, interactionPlugin ],
      locale: locale,
      firstDay: 1,
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next'
      },
      events: `/${eventsUrl}.json`,
        
      aspectRatio: this.aspectRatioValue,
      expandRows: true,
      weekNumbers: true,
      weekText: "",
      eventClassNames: function(args) {
        // console.log("event: ", args.event)
        let classNames = ["stream-bg-" + args.event.extendedProps.stream, "tooltiped"];
        if (args.isPast) {
          classNames.push("in-past");
        }
        return classNames;
      },
      // eventDidMount: function(args) {
      //   $(args.el).attr("data-tooltip-content", `#${args.event.id}`);
      // },
      // viewDidMount: function(args) {
      //   let view = args.view;
      //   $('.tooltiped', $(args.el)).each(function(index) {
      //     return $(this).tooltipster({
      //       contentCloning: true,
      //       animation: 'fade',
      //       delay: 200,
      //       theme: "tooltipster-" + $($(this).attr("data-tooltip-content")).attr("data-stream"),
      //       trigger: 'hover',
      //       interactive: true
      //     });
      //   });
      //   $(".tooltip_templates").removeClass("hidden");
      //   $('.tooltip_templates .tooltiped').each(function(index) {
      //     return $(this).find(".tile_details_lead_in").dotdotdot();
      //   });
      //   $(".tooltip_templates").addClass("hidden");
      //   $(".fc-week-number.ui-widget-content").append("<div class='kw_background'/>");
      //   return $(".fc-week-number.ui-widget-content .kw_background").each(function() {
      //     return $(this).height($(this).parent().height() + 'px');
      //   });
      // },
      displayEventTime: false
    });

    calendar.render();
  }


  selected(event) {
    if($(event.target).closest("a").length > 0 && event.target != event.currentTarget) {
      // do nothing should not interest you!
    } else {
      event.stopPropagation();
      event.preventDefault();
      let $currentTarget = $(event.currentTarget),
        hasClass = $currentTarget.hasClass("selected");
      $(".card.selected").css({
        "border-color": "",
        "background-color": ""
      });
      $(".card.selected").removeClass("selected");
      if (!hasClass) {
        $currentTarget.addClass("selected");
        let color = $currentTarget.attr("data-color");
        let backgroundColor = $currentTarget.attr("data-background-color");
        if (color) {
          $currentTarget.css({
            "border-color": color,
          });
        }
        if(backgroundColor) {
          $currentTarget.css({
            "background-color": backgroundColor,
          });
        }
      }
    }
  }
}