import { Controller } from '@hotwired/stimulus';
import Ajax from '../packs/ajax.js';

export default class extends Controller {

  async add(event) {
    var $target = $(event.target)
    var $insertBefore = $target.parents('.row#insert-before')

    var $controllerElement = $(this.element)
    var index = $controllerElement.find('.agreement').length

    var agreement = await new Ajax('/admin/render_agreement').doAjax('GET', { "index": index })

    $(agreement).insertBefore($insertBefore)
  }

  remove(event) {
    var $target = $(event.target)
    var $agreement = $target.parents('.agreement')
    $agreement.remove()
  }
}