export default class Draggable {
  constructor(modifyDropzone = true) {
    this.modifyDropzone = modifyDropzone
    let self = this
    $(document).on('dragstart', '[draggable=true]', function(e) {
      //e = JQueryEvent, need DragEvent here. This is contained in originalEvent
      self.drag(e.originalEvent, self)
    })
    $(document).on("dragend", "[draggable=true]", function (e) {
      $(".opacity-30").removeClass("opacity-30")
      if(self.modifyDropzone) {
        $("[dropzone=false]").attr({ dropzone: true })
      }
    })
  }

  drag(ev, self) {
    let target = $(ev.target),
    id = target.data('id'),
    dragImageTarget = target.data('drag-image-target'),
    eliminationTargets = target.data('elimination-targets')

    if(self.modifyDropzone) {
      let prev = target.prev('[dropzone=true]'),
      next = target.next('[dropzone=true]')
      prev.attr({ dropzone: false })
      next.attr({ dropzone: false })
    }

    ev.preventDefault
    copyDataToDataTransfer(ev.dataTransfer, target.data())
    let node = self.createDragImage(document.getElementById(dragImageTarget))
    if(node != null) {
      ev.dataTransfer.setDragImage(node, $(node).outerWidth() / 2, $(node).outerHeight() / 2)
    }

    if(eliminationTargets) {
      eliminationTargets = eliminationTargets.split(',')
      for(let elimination of eliminationTargets) {
        let item = $('#' + elimination)
        item.addClass('opacity-30')
      }
    }
  }

  createDragImage(el) {
    if(el != null) {
      let nodeElem = el.cloneNode(true);
      nodeElem.style.top = "-1000px"
      nodeElem.style.left = "-1000px"
      nodeElem.style.position = "absolute"
      nodeElem.style.pointerEvents = "none"

      //append element for screenshoting and remove directly afterwards
      document.body.appendChild(nodeElem)
      setTimeout(function() {
        document.body.removeChild(nodeElem)
      });

      return nodeElem
    }
    return null
  }
}

/*
* copies everything from JQuery.Data to DataTransfer.Data
* the values can be accessed by camelCase in the DataTransfer.Data object
*/
function copyDataToDataTransfer(dataTransfer, data) {
  for (let [key, value] of Object.entries(data)) {
    dataTransfer.setData(key, value)
  }
  return dataTransfer
}