import ApplicationController from './application_controller'


export default class extends ApplicationController {

  static targets = ["step"]

  initialize() {
    super.initialize()
    this.useReflex = false
    this.currentStep = 0
    $(this.element).on('show.bs.modal', (event) => {
      this.restoreStep(this.currentStep)
    })
    $(this.element).on('hide.bs.modal', (event) => {
      this.saveStep(this.currentStep)
    })
  }

  /**
   * This will hide all steps except the one to restore.
   * It will dipatch an custom event 'restore.step' to the current step to restore.
   * @param {Integer} step the step to restore like starting video again and show the page.
   */
  restoreStep(step) {
    if(this.hasStepTarget) {
      $(this.stepTargets).addClass('d-none')
      $(this.stepTargets[step]).removeClass('d-none')
      this.stepTargets[step].dispatchEvent(new CustomEvent('restore.step'))
    }
  }

  /**
   * It will dispatch an custom event 'save.step' to the current step to save.
   * @param {Integer} step which step to save its state (if needed)
   */
  saveStep(step) {
    if(this.hasStepTarget) {
      this.stepTargets[step].dispatchEvent(new CustomEvent('save.step'))
    }
  }

  /**
   * animate from current page to next page.
   */
  nextStep() {
    if(this.hasStepTarget) {
      if(this.stepTargets.length > this.currentStep + 1) {
        this.currentStep++
        const prevStepDom = this.stepTargets[this.currentStep - 1]
        const stepDom = this.stepTargets[this.currentStep]
        stepDom.dispatchEvent(new CustomEvent('restore.step'))
        $(prevStepDom).addClass('animate_fadeOutDown')
        $(stepDom).removeClass('d-none').addClass('animate__fadeInDown')
      } else {
        $(this.element).modal('hide')
      }
    }
  }
}