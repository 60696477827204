import ApplicationController from './application_controller'


export default class extends ApplicationController {

  createConsumable(event) {
    let target = $(event.currentTarget);
    let learn_unit_id = parseInt(target.data("learn-unit-id")),
      consumable_group_id = parseInt(target.data("consumable-group-id")),
      consumable_id = parseInt(target.data("consumable-id")),
      type = target.data("type");
    this.stimulate(
      "ConsumableGroupReflex#create_consumable",
      learn_unit_id,
      consumable_group_id,
      consumable_id,
      type
    );
  }

  createConsumableSuccess() {
    $("#create_consumable .modal").modal("show");
  }

  reload(event) {
    let target = $(event.currentTarget);
    let consumable_group_id = parseInt(target.data("consumable-group-id"));
    this.stimulate("ConsumableGroupReflex#reload", consumable_group_id);
  }
}
