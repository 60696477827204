export default class Logger {

  static LOG_LEVEL_VERBOSE = 0
  static LOG_LEVEL_WARNING = 1
  static LOG_LEVEL_ERROR = 2

  // logLevel 0 will log everything
  constructor(logLevel = Logger.LOG_LEVEL_VERBOSE) {
    this.logLevel = logLevel
  }

  info(tag, message) {
    this.#logMessage(tag, message, Logger.LOG_LEVEL_VERBOSE)
  }

  warn(tag, message) {
    this.#logMessage(tag, message, Logger.LOG_LEVEL_WARNING)
  }

  error(tag, message) {
    this.#logMessage(tag, message, Logger.LOG_LEVEL_ERROR)
  }

  #logMessage(tag, message, level) {
    if(level < this.logLevel) {
      return
    }
    switch(level) {
      case 0:
        this.#infoMessage(`[${tag}] ${message}`)
        break
      case 1:
        this.#warnMessage(`[${tag}] ${message}`)
        break
      case 2:
        this.#errorMessage(`[${tag}] ${message}`)
        break
      default:
        break
    }
  }

  #warnMessage(message) {
    this.#colorTrace(message, "#F37100")
  }

  #infoMessage(message) {
    this.#colorTrace(message, "#00CCF2")
  }

  #errorMessage(message) {
    this.#colorTrace(message, "#FF1000")
  }

  #colorTrace(message, color) {
    console.log(`%c${message}`, `color:${color};`)
  }
}