import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  change(event) {
    let self = this;
    let input = event.currentTarget;
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        let next = $(input).next();
        if($(next).is('img')) {
          $(next).attr('src', e.target.result);
        } else {
          $(next).remove();
          let imageElement = document.createElement('img');
          $(imageElement).attr('src', e.target.result);
          $(imageElement).insertAfter(input);
        }
      };

      reader.readAsDataURL(input.files[0]);
    }
  }
}