import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    super.connect();
    this.updateGroups();
    this.permissionSets = {}
    this.initializePermissions();
  }

  ///
  // when one of the group header checkboxes is changed, the unerlying checkboxes are changed accordingly
  ///
  headerChange(event) {
    const $target = $(event.currentTarget)
    const group = $target.attr('id'),
    groupCheckboxes = $(`#permissions_table [data-group='${group}'] input[type=checkbox]`);
    $(groupCheckboxes).each(function() {
      $(this).prop('checked', $(event.currentTarget).prop('checked'))
    });
    const setName = $target.parent().data("set");
    this.updatePermissionSet(setName);
    this.enableSaveButton(setName)
  }

  ///
  // stimulates appropriate action when a check box in permissions view for a permission set is changed
  ///
  itemChange(event) {
    const $target = $(event.currentTarget)
    const setName = $target.parent().data("set");
    const groupSet = $target.parent().data("group")
    const groupName = $target.parent().data("group-name")
    this.updateGroups(setName, groupName);
    this.updatePermissionSet(setName);
    this.enableSaveButton(setName)
  }

  ///
  // enables the save button for given set
  ///
  enableSaveButton(setName) {
    const $saveButton = $(`#save_button_${setName}`);
    $saveButton.removeClass('hidden').prop('disabled', false).removeClass('disabled')
  }

  ///
  // triggers the save the permissions of this permission set
  ///
  savePermissionSet(event) {
    const $target = $(event.currentTarget)
    const setName = $target.data("set");
    const $saveButton = $(`#save_button_${setName}`);
    const $saveButtonText = $(".txt", $saveButton);

    const saveButtonText = $saveButton.text()
    $saveButtonText.text("↻").css({font_size: '22px'})

    $saveButton.addClass("rotating disabled").prop("disabled", true)

    this.stimulate("PermissionReflex#update_permission_set", setName, this.permissionSets[setName]).then(payload => {
      $saveButton.addClass('hidden').removeClass("rotating")
      $saveButtonText.text(saveButtonText)
    })
  }

  ///
  // sets the internal permissionSet hash accordingly
  ///
  initializePermissions() {

    const self = this;
    let setNames = []
    $("#permissions_table input.permission[type=checkbox]:checked").each(function () {
      const setName = $(this).parent().data("set");
      if (!self.permissionSets[setName]) {
        self.permissionSets[setName] = [];
      }
      self.permissionSets[setName].push(parseInt($(this).data("value")));
      setNames.push(setName)
    });
    setNames.forEach(function (name, index) {
      self.permissionSets[name] = self.permissionSets[name].sort(
        function (a, b) {
          return +a - +b;
        }
      );
    });

    console.log("permissionSets: ", self.permissionSets)

    // this.stimulate("PermissionReflex#update_permissions", permissions);
  }

  ///
  // sets the internal permissionSet hash for given set accordingly
  ///
  updatePermissionSet(setName) {

    const self = this;
    self.permissionSets[setName] = [];
    $(`#permissions_table [data-set='${setName}'] input.permission[type=checkbox]:checked`).each(function () {
      self.permissionSets[setName].push(parseInt($(this).data("value")));
    });
    self.permissionSets[setName] = self.permissionSets[setName].sort(
      function (a, b) {
        return +a - +b;
      }
    );

    console.log(`permissionSet: ${setName}`, self.permissionSets[setName])
    // this.stimulate("PermissionReflex#update_permissions", permissions);
  }

  ///
  // updates the group headers accordingly (check_all vs. check_nothing)
  ///
  updateGroups(setName, groupName) {
    let permissionHeaderCheckboxesSelector = ".permission-header input[type=checkbox]";      
    if (setName ) {
      permissionHeaderCheckboxesSelector = `.permission-header[data-set=${setName}][data-group=${groupName}] input[type=checkbox]`;
    }

    for (let header of $(permissionHeaderCheckboxesSelector)) {
      // console.log("working on" , header)
      const group = $(header).attr("id")
      const groupCheckboxes = $(`[data-group='${group}'] input[type=checkbox]`);

      const total = $(groupCheckboxes).length;
      const checked = groupCheckboxes.filter(':checked').length;

      if (checked == 0) {
        $(header).prop("indeterminate", false);
        $(header).prop("checked", false);
      } else if (checked > 0 && checked < total) {
        $(header).prop("indeterminate", true);
      } else if (checked == total) {
        $(header).prop("indeterminate", false);
        $(header).prop("checked", true);
      }
    }
  }

  ///
  // triggers the deletion of this permission set
  ///
  removePermissionSet(event) {
    const $target = $(event.currentTarget)
    const setName = $target.data("set");

    this.stimulate("PermissionReflex#delete_permission_set", setName)
    // .then(payload => {
    //   $saveButton.addClass('hidden').removeClass("rotating")
    //   $saveButtonText.text(saveButtonText)
    // })
  }

}