import ApplicationController from "../application_controller"

export default class extends ApplicationController {

  static targets = ["points"]

  initialize() {
    super.initialize()
    this.totalPoints = {}
    this.debug = false
  }

  updatePoints(event) {
    const oldPoints = event.detail.oldPoints
    const newPoints = event.detail.newPoints

    this.#removePoints(oldPoints)
    this.#addPoints(newPoints)

    this.showDebugInformation(oldPoints, newPoints)

    this.#formUpdate()
  }

  #removePoints(points) {
    if(points) {
      for(var key in points) {
        this.totalPoints[key] -= points[key]
      }
    }
  }

  #addPoints(points) {
    if(points) {
      for(var key in points) {
        if(!this.totalPoints.hasOwnProperty(key)) {
          this.totalPoints[key] = 0
        }
        this.totalPoints[key] += points[key]
      }
    }
  }

  #formUpdate() {
    if(this.hasPointsTarget) {
      $(this.pointsTarget).val(JSON.stringify(this.totalPoints))
    }
  }

  showDebugInformation(oldPoints, newPoints) {
    if(this.debug) {
      $("#toast_debug").show()
      this.updateDebugInformation(oldPoints, newPoints)
    }
  }

  updateDebugInformation(oldPoints, newPoints) {
    const oldPointLabel = $('#toast_debug').find('.old_points')
    const newPointLabel = $('#toast_debug').find('.new_points')
    const currentPointLabel = $('#toast_debug').find('.current_points')

    console.log("old points: ", oldPoints)
    console.log("new points: ", newPoints)
    console.log("current points: ", this.totalPoints)

    currentPointLabel.html(JSON.stringify(this.totalPoints))
    if(newPoints != null) {
      newPointLabel.html(JSON.stringify(newPoints))
    } else {
      newPointLabel.html("")
    }
    if(oldPoints != null) {
      oldPointLabel.html(JSON.stringify(oldPoints))
    } else {
      oldPointLabel.html("")
    }
  }

}