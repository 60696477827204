import ApplicationController from './application_controller'


export default class extends ApplicationController {
  // static targets = [ "learnunit" ]
  static values = { id: String } // the consumables id

  connect() {
    // console.log(`connected to ${this.identifier}`)
    super.connect()
  }
  
  ///
  // stimulates the claim method of ConsumableReflex so the given consumable is claimed by current user
  ///
  claim(event) {
    this.stimulate('Consumable#claim', event.currentTarget);
  }
}