
import FieldValidationController from './field_validation_controller'
import VALIDATION_STATES from './validation_states'

export default class extends FieldValidationController {
  initialize() {
    super.initialize()
    if(this.hasInputTarget) {
      if(this.isRequired) {
        this.inputTarget.addEventListener('change', this.watchField.bind(this))
      }
    }
    
  }
  checkValidation(target) {
    console.log(target.val())
    super.checkValidation(target)
  }
}