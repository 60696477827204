import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log("date display controller connected")
    const self = this
    this.display_dates($(this.element));
    $(this.element).on("dates:changed", function () {
      // console.log("dates have changed")
      self.display_dates($(self.element));
    })
  }

  display_dates($area) {
    $area.find('.datetimestring').each(function() {
      return $(this).text(datetime.toLocal($(this).attr("data-value")));
    });
    
    $area.find('.datestring').each(function () {
      // console.log("found datestring")
      return $(this).text(datetime.toLocalDate($(this).attr("data-value")));
    });
    
    $area.find('.timestring').each(function() {
      return $(this).text(datetime.toLocalTime($(this).attr("data-value")));
    });

  }
}
