import ApplicationController from "../../../javascript/controllers/application_controller"
import Draggable from "../../../javascript/packs/draggable"

export default class extends ApplicationController {

  static targets = ["correctBucket", "incorrectBucket"]

  initialize() {
    super.initialize()
    this.currentDraggable = null
    this.allAnswers = $(this.element).find('.sort-answer').length
    this.draggable = new Draggable(false)
    this.#defineDropzonesHandlers()
  }

  #defineDropzonesHandlers() {
    const self = this
    $(this.element).on('dragenter', '[dropzone=true]', function (ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      if (!self.#contains($container, self.currentDraggable)) {
        $container.addClass('entered')
      }
    })

    // define the dragleave behavior
    $(this.element).on('dragleave', '[dropzone=true]', function (ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      $container.removeClass('entered')
    })

    $(this.element).on('dragstart', '[draggable=true]', function (ev) {
      self.currentDraggable = $(ev.currentTarget)
    })

    $(this.element).on('dragover', '[dropzone=true]', function (ev) {
      ev.preventDefault()
    })

    $(this.element).on('drop', '[dropzone=true]', function (ev) {
      const $container = $(ev.target).closest('[dropzone=true]')
      $container.removeClass('entered')
      self.#drop(ev.originalEvent, self)
    })
  }

  #drop(event, self) {
    event.stopPropagation()
    let $container = $(event.target).closest('[dropzone=true]')

    // move the item itself to the sortable container
    const originTarget = self.#getOriginTarget(event)

    const oldPoints = self.#calculatePoints()

    self.#moveElementTo(originTarget, $container, self)

    const newPoints = self.#calculatePoints()

    self.#dispatchQuestionState()
    self.#sendPointUpdate(oldPoints, newPoints)
  }

  #getOriginTarget(event) {
    return $(`#${event.dataTransfer.getData('eliminationTargets')}`)
  }

  #moveElementTo(element, to, self) {
    if (!self.#contains(to, element)) {
      element.appendTo(to)
    }
  }

  #contains(container, contained) {
    const id = `#${contained.attr('id')}`
    return container.find(id).length > 0
  }

  #calculatePoints() {
    let calculatedPoints = {}

    const answersInCorrectBucket = $(this.correctBucketTarget).find('.sort-answer')
    const correctAnswersInCorrectBucket = answersInCorrectBucket.filter((index, answer, answers) => {
      return $(answer).data('is-correct')
    })
    const incorrectAnswersInCorrectBucket = answersInCorrectBucket.filter((index, answer, answers) => {
      return !$(answer).data('is-correct')
    })
    const answersInIncorrectBucket = $(this.incorrectBucketTarget).find('.sort-answer')
    const correctAnswersInIncorrectBucket = answersInIncorrectBucket.filter((index, answer, answers) => {
      return !$(answer).data('is-correct')
    })
    const incorrectAnswersInIncorrectBucket = answersInIncorrectBucket.filter((index, answer, answers) => {
      return $(answer).data('is-correct')
    })

    const correctAnswers = $.merge(correctAnswersInCorrectBucket, correctAnswersInIncorrectBucket)
    const incorrectAnswers = $.merge(incorrectAnswersInCorrectBucket, incorrectAnswersInIncorrectBucket)

    correctAnswers.each((index, answer, answers) => {
      const points = $(answer).data('points')
      for (var key in points) {
        if (calculatedPoints.hasOwnProperty(key)) {
          calculatedPoints[key] += points[key]
        } else {
          calculatedPoints[key] = points[key]
        }
      }
    })

    incorrectAnswers.each((index, answer, answers) => {
      const points = $(answer).data('points')
      for (var key in points) {
        if (calculatedPoints.hasOwnProperty(key)) {
          calculatedPoints[key] += -points[key]
        } else {
          calculatedPoints[key] = -points[key]
        }
      }
    })

    console.log("calculated points: ", calculatedPoints)
    return calculatedPoints
  }

  #dispatchQuestionState() {
    const sortContainer = $(this.element).find('.sort-container')
    const answersSorted = sortContainer.find('.sort-answer').length

    const questionComplete = new CustomEvent('wizard.quiz.question_state', {
      detail: {
        question_complete: answersSorted >= this.allAnswers
      }
    })
    this.element.dispatchEvent(questionComplete)
  }

  #sendPointUpdate(oldPoints, newPoints) {
    const pointUpdateEvent = new CustomEvent('wizard.quiz.point_update', {
      detail: {
        oldPoints: oldPoints,
        newPoints: newPoints
      }
    })
    this.logInfo(`dispatching point update`)
    this.element.dispatchEvent(pointUpdateEvent)
  }
}