import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ["colpart", "button"]

  connect() {
    let self = this;
    this.$collapsible = $(this.colpartTarget);
    this.$button = $(this.buttonTarget);
    // this.$collapsible.collapse({toggle: false});

    this.$collapsible.on('hide.bs.collapse', function () {
      // console.log("hidden")
      self.$button.find(".material-icons").toggleClass('hidden');
    })
    this.$collapsible.on('show.bs.collapse', function () {
      // console.log("shown")
      self.$button.find(".material-icons").toggleClass('hidden');
    })
  }

  toggle(event) {
    
  }

}