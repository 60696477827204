import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    $("[data-bs-toggle=popover]").popover(
      {
        html: true,
        content: function() {
          return $(this).parent().find('.details').html();
        }
      }
    )

  }

}