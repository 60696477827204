import { Controller } from '@hotwired/stimulus'

// Select2 Integration
// import 'select2'

////
// enables select2 behavior for all selects with class 'select2'
////
export default class extends Controller {

  static values = {
    field: String
  }

  connect() {
    // $("select.select2-enabled").on('mouseenter', function(event) {
    //   console.log("select2 has focus now")
    //   let $target = $(event.currentTarget);
    //   $target.select2({tags: true});
    // });
    let self = this;
    let $parent = $(this.element).parent();

    if (this.hasFieldValue) {
      if (this.fieldValue == "email") {
        // select2 initialization for email creation
        let $emailSelect = $("select.select2-email");
        $emailSelect.select2({
          tags: true,
          placeholder: I18n.t('shared.email'),
          insertTag: function (data, tag) {
            let trimmedTag = $.trim(tag.text);
            // console.log("Tag: ", trimmedTag);
            const emailRegex = /^[\w-]+@[\w-]+\.\w{2,3}$/g;
            let isEmail = emailRegex.test(trimmedTag);
            // console.log("isEmail: ", isEmail);
            if(isEmail) {
             // Insert the tag at the end of the results
              data.push(tag);
              $parent.find(".select2-steered input").prop("disabled", false).removeClass("disabled").parent().removeClass("disabled");
            }
          }
        });        
      }

    }

    // select2 initialization for default ones
    $("select.select2").select2({});

  }
}