import ApplicationController from '../../../javascript/controllers/application_controller'
import croppie from 'croppie';

export default class extends ApplicationController {

  connect() {
    super.connect();
    this.$cropper = $(this.element)
    // console.log("element: ", this.$cropper)
    // this.cropButton = this.$cropper.find('.transform-button')
    this.usageButtons = this.$cropper.find('.use-cropping-buttons')
    this.modal = this.$cropper.closest('.modal')
    this.dropZone = this.$cropper.parent()
    this.previewContainer = this.dropZone.find('.upload-preview')
    this.imageSize = this.$cropper.data("image-size")
    // console.log("image size: ", this.imageSize)
    this.cropFormat = this.$cropper.data("crop-format")
    // console.log("crop_format: ", this.cropFormat)
    // set event listener for previewLoaded
    this.$cropper.on('previewRendered', this.initCropping.bind(this))
  }


  ///
  // makes the cropping button visible
  ///
  showCropper(event) {
    $(event.currentTarget).removeClass('hidden')
  }

  cancelCropping(event) {
    this.toggleCropButtons()
    this.cropped.croppie('destroy')
  }

  ///
  // toggles between transform and crop buttons
  ///
  toggleCropButtons() {
    const chooseButton = this.modal.find('.modal-footer .btn-primary')
    // console.log("chooseButton: ", chooseButton)
    if(this.$cropper.hasClass('hidden')) {
      this.$cropper.removeClass('hidden')
      this.previewContainer.removeClass('mh-100')
      chooseButton.addClass('disabled')
    } else {
      this.$cropper.addClass('hidden')
      this.previewContainer.addClass('mh-100')
      chooseButton.removeClass('disabled')
    }
  } 

  initCropping(event) {
    // console.log("init the cropping")
    this.toggleCropButtons()
    // const image = $(this.imageTarget)
    // console.log("cropper", this.$cropper)


    const image = this.dropZone.find('.upload-preview img')
    const realBoundary = [this.dropZone.width(), this.dropZone.height()]
    const viewPortBoundary = this.computeSize(this.imageSize, realBoundary)
    // console.log("viewPortBoundary: ", viewPortBoundary)
    const boundaryBoundary = this.computeSize(this.imageSize.map(value => value * 2), realBoundary, false)
    // console.log("boundaryBoundary: ", boundaryBoundary)
    const cropOpts = {
      viewport: { width: viewPortBoundary[0], height: viewPortBoundary[1], type: this.cropFormat },
      boundary: { width: boundaryBoundary[0], height: boundaryBoundary[1], type: this.cropFormat },
      showZoomer: false,
      enableOrientation: true,
      enableResize: false
    }
    this.cropped = image.croppie(cropOpts);
    this.modal.modal('handleUpdate')
  }

  useCropped(event) {
    const self = this
    this.cropped.croppie('result', {
      type: 'base64',
      size: {width: this.imageSize[0], height: this.imageSize[1]}
    }).then(function (resp) {
      const imageSource = $(self.dropZone.find('.upload-preview img'))
      imageSource.attr('src', resp)
      self.dropZone.attr('data-base64', 'true')
      self.cropped.croppie('destroy')
      self.$cropper.addClass('hidden')
    })
    this.toggleCropButtons()
  }

  ///
  // Function to compute a new size that does not exceed maximum width and height
  ///
  computeSize(size, maxSize, respectAspectRatio = true) {
    let width = size[0],
    height = size[1],
    maxWidth = maxSize[0],
    maxHeight = maxSize[1]

    // Compute aspect ratio if respectAspectRatio is enabled
    const aspectRatio = respectAspectRatio ? width / height : 1;

    // Check if width exceeds maxWidth
    if (width > maxWidth) {
      width = maxWidth;
      height = respectAspectRatio ? Math.round(maxWidth / aspectRatio) : height; // Adjust height proportionally if respectAspectRatio is enabled
    }

    // Check if height exceeds maxHeight
    if (height > maxHeight) {
      height = maxHeight;
      width = respectAspectRatio ? Math.round(maxHeight * aspectRatio) : width; // Adjust width proportionally if respectAspectRatio is enabled
    }

    return [width, height];
  }
}
